import React from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import * as permissions from "../../../../utils/permissions";

import { useFirebase } from "../../../Firebase";
import { Item, Button, Dropdown, Segment, Header, Icon } from "semantic-ui-react";
import DeleteModal from "../../../Common/DeleteModal";
import { useAuth } from "../../../Session";

export default function AdminCourseList({ courses }) {
  const firebase = useFirebase();
  const auth = useAuth();

  function deleteCourse(course) {
    firebase.course(course._id).delete();
  }

  const body = courses.map(course => (
    <Item key={course._id}>
      <Item.Content>
        <Button.Group floated="right" primary>
          <Button as={Link} to={`/manage/${course._id}`} loading={!permissions.canManage(auth, course._id)}>
            Manage Course
          </Button>
          <Dropdown className="button icon" floating>
            <Dropdown.Menu>
              <Dropdown.Item
                text="Invite Students"
                icon="envelope open outline"
                as={Link}
                to={`/manage/${course._id}/students`}
              />
              <Dropdown.Divider />
              <Dropdown.Item text="Settings" icon="cog" as={Link} to={`/manage/${course._id}/edit`} />
              {/* <Dropdown.Item text="Delete Course" icon="trash alternate" /> */}

              <DeleteModal
                onClick={() => deleteCourse(course)}
                icon="trash alternate"
                title="Delete Course"
                message="You are about to delete a course (this is irreversible)"
                expected={course.name}
                trigger={<Dropdown.Item text="Delete Course" icon="trash alternate" />}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Button.Group>
        <Item.Header>{course.name}</Item.Header>
        {!isEmpty(course.description) && (
          <Item.Description>
            <p>{course.description}</p>
          </Item.Description>
        )}
        <Item.Extra>
          <b>{course.enrolledCounter}</b> Students enrolled | <b>{course.invitationCounter}</b> Pending invitation
        </Item.Extra>
      </Item.Content>
    </Item>
  ));

  if (isEmpty(courses)) {
    return (
      <Segment placeholder>
        <Header icon>
          <Icon name="book" />
          No managed courses
        </Header>
      </Segment>
    );
  }

  return <Item.Group divided>{body}</Item.Group>;
}
