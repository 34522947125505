import React, { useState } from "react";
import { useFirebase } from "../../../Firebase";
import { useFirestoreQuery } from "../../../../utils/firebaseHooks";

import { ManagePageContainer } from "../../../UI/ManagePageContainer";
import { Header, Icon, Breadcrumb, Divider, Segment, Message } from "semantic-ui-react";
import { AnnouncementList } from "../../../Common/Announcement";
import * as dataModel from "../../../../services/dataModel";

import AsyncButton from "../../../UI/AsyncButton";
import Textarea from "react-textarea-autosize";
import { useAuth } from "../../../Session";

export default function AnnouncementsPage({ course }) {
  const firebase = useFirebase();
  const [announcements, isLoading] = useFirestoreQuery(firebase.announcements(course._id).orderBy("createdAt", "desc"));

  const breadcrumb = [
    { key: "Home", content: <Icon name="home" /> },
    { key: "announcements", content: "Announcements" }
  ];

  return (
    <ManagePageContainer>
      <Header
        as="h1"
        icon="announcement"
        content="Announcements"
        subheader="Manage announcement messages visible to students"
      />
      <Breadcrumb icon="right angle" sections={breadcrumb} />
      <Divider />

      <CreateAnnouncementForm course={course} />

      {!isLoading && <AnnouncementList isAdmin announcements={announcements} />}
    </ManagePageContainer>
  );
}

function CreateAnnouncementForm({ course }) {
  const auth = useAuth();
  const firebase = useFirebase();
  const [content, setContent] = useState("");

  async function handleSubmit() {
    const data = dataModel.newAnnouncement(
      content,
      auth.name,
      auth.uid,
      course._id,
      firebase.fieldValue.serverTimestamp()
    );
    await firebase.db.collection(`announcements`).add(data);
    setContent("");
  }

  return (
    <Segment raised>
      {/* <Header size="medium" color="purple">
        New Announcement
      </Header> */}

      <Textarea
        name="description"
        value={content}
        onChange={event => setContent(event.target.value)}
        placeholder="Announcement Message"
        minRows={4}
        style={{
          width: "100%",
          resize: "none",
          borderRadius: "5px",
          padding: "5px",
          borderColor: "#caccd0"
        }}
      />

      <AsyncButton
        onClick={handleSubmit}
        style={{
          marginTop: "1rem"
        }}
        disabled={content === ""}
        color="purple">
        Add New Announcement
      </AsyncButton>
    </Segment>
  );
}
