import styled, { css } from "styled-components";

export const GlobalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: normal;

  /* padding-top: 4rem; */
  margin-top: 3.2rem;
  /* margin-bottom: 4rem; */
  background-color: #12304d;
  color: white;
  min-height: 300px;
`;

export const PageHeader = styled.div`
  margin-top: 4rem;
  margin-bottom: 2rem;
  background-color: #cddc38;
`;

export const PageHeader2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* justify-content: flex-start; */
  line-height: normal;
  padding-top: 4rem;
  padding-bottom: 4rem;
  /* min-height: 100px; */
`;

export const Icon = styled.div`
  font-size: 2rem;
`;

export const PageTitle = styled.div`
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 1rem;
`;

export const Image = styled.img`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px 2px, rgba(0, 0, 0, 0.2) 0px 0px 2px;
  width: 100%;
`;

export const SmallImage = styled.img`
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px 2px, rgba(0, 0, 0, 0.2) 0px 0px 2px; */
  width: 100%;
`;

export const Divider = styled.hr`
  border-top: 0;
  border-bottom: 1px #ccdc38 solid;
`;

export const BlockHeader = styled.h1`
  text-align: center;
  font-weight: 300;
  color: #11304d;
  margin-bottom: 2rem;

  & > span {
    font-size: 64px;
    opacity: 0.6;
  }

  & > h2 {
    font-size: 32px;
    font-weight: 500;
  }
  & > h3 {
    margin: 0;
    opacity: 0.6;
    font-weight: 300;
    font-size: 24px;
    text-transform: uppercase;
  }
`;

export const Block = styled.div`
  padding: 3rem 0;
  color: #40474e;

  ${props =>
    props.orange &&
    css`
      background-color: #ffc107;
    `}

  ${props =>
    props.green &&
    css`
      background-color: #cddc38;
    `}

  ${props =>
    props.lightgreen &&
    css`
      background-color: #e9f2a8;
    `}
`;

export const Header = styled.div`
  font-size: 2rem;
  font-weight: 100;
  margin-bottom: 1rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

export const Paragraph = styled.p``;
export const SubHeader = styled.p`
  font-size: 1.4rem;
`;

export const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;
export const Rows = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

export const Column = styled.div`
  flex: 1;
  padding: 2rem 2rem;
  color: black;

  ${props =>
    props.bottom &&
    css`
      align-self: flex-end;
    `}
  ${props =>
    props.center &&
    css`
      text-align: center;
    `}

  ${props =>
    props.vcenter &&
    css`
      align-self: center;
    `}

  h3 {
    font-size: 21px;
    font-weight: 500;
    margin: 0 0 0.5rem 0;
  }

  p {
    font-weight: 300;
    font-size: 18px;
    line-height: 2;
  }

  ${props =>
    props.large &&
    css`
      & > h3 {
        font-size: 26px;
      }
      & > p {
        font-size: 21px;
      }
    `}
`;

export const Row = styled.div`
  flex: 1;
  padding: 1rem 0;

  h3 {
    font-size: 1.2rem;
    margin: 0 0 0.5rem 0;
  }
  p {
    font-size: 1.1rem;
    line-height: 1.6;
  }
`;

export const Button = styled.button`
  padding: 12px 12px !important;
  margin: 3rem 0 4px 0;
  border: 2px #11304d solid;
  color: #11304d;
  text-transform: capitalize !important;
  width: 76%;
  font-size: 1.2rem;
  cursor: pointer;
`;
