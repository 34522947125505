import React, { useState } from "react";
import { find } from "lodash";
import { useAuth } from "../../../Session";
import { useFirebase } from "../../../Firebase";
import { useHistory } from "react-router-dom";
import { useParams, Link, Route, Switch, Redirect } from "react-router-dom";
import { Grid, Menu, Header, Segment, List, Button } from "semantic-ui-react";
import { StudentProgress } from "../../../Common/StudentProgress";
import * as statistics from "../../../../utils/statistics";

import RecentActivityPage from "./RecentActivity";
import AssignmentsPage from "./Assignments";
import MessagesPage from "./Messages";
import AsyncButton from "../../../UI/AsyncButton";
import DeleteModal, { DeleteModalControlled } from "../../../Common/DeleteModal";

export default function StudentDashboard({ course, current, students }) {
  const params = useParams();
  const student = find(students, { _id: params.user_id });

  return (
    <div>
      <PageHeader student={student} course={course} current={current} />
      <Grid divided padded>
        <Grid.Column width={13}>
          <Switch>
            <Route exact path={`/manage/${params.course_id}/students/${params.user_id}`}>
              <Redirect to={`/manage/${params.course_id}/students/${params.user_id}/recent`} />
            </Route>

            <Route exact path={`/manage/${params.course_id}/students/${params.user_id}/recent`}>
              <RecentActivityPage courseId={params.course_id} studentId={student._id} />
            </Route>

            <Route exact path={`/manage/${params.course_id}/students/${params.user_id}/progress`}>
              <AssignmentsPage course={course} studentId={student._id} />
            </Route>

            <Route exact path={`/manage/${params.course_id}/students/${params.user_id}/messages`}>
              <MessagesPage courseId={params.course_id} studentId={student._id} />
            </Route>
          </Switch>
        </Grid.Column>

        <Grid.Column width={3}>
          <PageSidebar course={course} student={student} />
        </Grid.Column>
      </Grid>
    </div>
  );
}

const PageHeader = ({ student, course, current }) => {
  const params = useParams();

  const totalExercises = Object.values(course.exerciseOrder)
    .map(list => list.length)
    .reduce((a, b) => a + b, 0);

  // console.log("Course: ", course);
  // console.log("Current: ", Object.values(current));
  // console.log("Student: ", student);

  return (
    <Segment inverted>
      <Menu inverted secondary>
        <Menu.Item>
          <Header inverted size="small" icon="user" content={student.userName} />
        </Menu.Item>
        <Menu.Item
          name="Recent Activity"
          icon="history"
          active={params.view === "recent"}
          as={Link}
          to={`/manage/${params.course_id}/students/${params.user_id}/recent`}
        />
        <Menu.Item
          name="Progress"
          icon="tasks"
          active={params.view === "progress"}
          as={Link}
          to={`/manage/${params.course_id}/students/${params.user_id}/progress`}
        />
        <Menu.Item
          name="Messages"
          icon="envelope"
          active={params.view === "messages"}
          as={Link}
          to={`/manage/${params.course_id}/students/${params.user_id}/messages`}
        />
        <Menu.Menu position="right">
          <Menu.Item>
            <StudentProgress values={statistics.countExerciseOfStudent(student._id, current, totalExercises)} />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </Segment>
  );
};

const PageSidebar = ({ course, student }) => {
  const firebase = useFirebase();
  const history = useHistory();
  const auth = useAuth();
  const [showCancel, setShowCancel] = useState(false);

  var dateOptions = { year: "numeric", month: "long", day: "numeric" };

  async function downloadAll() {
    let downloadStudentLatestSubmissionsOfCourse = firebase.functions.httpsCallable(
      "downloadStudentLatestSubmissionsOfCourse"
    );

    return downloadStudentLatestSubmissionsOfCourse({
      courseId: course._id,
      userId: auth.uid,
      studentUserId: student._id,
      title: `${student.userName}: All assignments`,
      subject: course.name
    });
  }

  async function downloadToReview() {
    let downloadStudentToReivewSubmissionsOfCourse = firebase.functions.httpsCallable(
      "downloadStudentToReivewSubmissionsOfCourse"
    );

    return downloadStudentToReivewSubmissionsOfCourse({
      courseId: course._id,
      userId: auth.uid,
      studentUserId: student._id,
      title: `${student.userName}: Unreviewed assignments`,
      subject: course.name
    });
  }

  async function cancelEnrollment() {
    setShowCancel(true);
  }

  async function handleCancelEnrollment(approved) {
    setShowCancel(false);
    if (approved) {
      firebase.enrolled(course._id, student._id).delete();
      history.push(`/manage/${course._id}/students`);
    }
  }

  return (
    <React.Fragment>
      <DeleteModalControlled
        title="Cancel enrollment"
        message={
          <div>
            <p>
              Cancelling this enrollment will <u>delete</u> the following data in the course:
              <br />
              <ol>
                <li>All student messages</li>
                <li>All assignments, grades and submissions history</li>
              </ol>
              <p className="animated-warning">This action is PERMANENT and the data could not be restored</p>
            </p>
          </div>
        }
        expected={student.userName}
        icon="trash alternate"
        open={showCancel}
        onClick={handleCancelEnrollment}
      />
      <List relaxed>
        <List.Item header="Name" content={student.userName} />
        <List.Item header="Email" content={student.email} />
        <List.Item
          header="Enrolled on"
          content={student.enrolledAt.toDate().toLocaleDateString("en-US", dateOptions)}
        />
        <List.Item>
          <AsyncButton basic fluid size="tiny" content="Download all Assignments" onClick={downloadAll} />
        </List.Item>
        <List.Item>
          <AsyncButton basic fluid size="tiny" content="Download unreviewed assignments" onClick={downloadToReview} />
        </List.Item>
        <List.Item>
          <AsyncButton basic fluid size="tiny" content="Cancel Enrollment" onClick={cancelEnrollment} />
        </List.Item>
      </List>
    </React.Fragment>
  );
};
