import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const StyledContainer = ({ children }) => (
  <div className="styled-container pb-5" style={{ minHeight: "500px" }}>
    <div className="container">{children}</div>
  </div>
);

export const Container = ({ children }) => (
  // <div className="container" style={{ boxSizing: "content-box" }}>
  <div className="container">{children}</div>
);

// export const PageHeader = ({ children, muted }) => (
//   <div className="pb-2 mt-3 d-flex justify-content-between align-items-baseline">
//     <h3>{children}</h3>
//     {muted}
//   </div>
// );

export const Spacer = styled.div`
  flex: 1;
`;

export const NarrowContainer = styled.div`
  min-width: 500px;
  width: 40%;

  text-align: center;

  margin-left: auto;
  margin-right: auto;
`;

export const Card = styled.div`
  background-color: white;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 12px;
`;

export const CardButton = styled.div`
  text-align: center;
  padding: 8px 1rem;
  border-radius: 5px;
  font-size: 0.8rem;
  background: #8bc34a;
  cursor: pointer;

  /* margin-left: 10px;
  margin-right: 10px;
  margin-top: 0.5rem;
  margin-bottom: 0.7rem; */

  &:hover {
    background: #acd47f;
  }

  & a {
    color: white;
    font-weight: 400;

    &:hover {
    }
  }
`;

export const PageHeader = styled.div`
  font-size: 3rem;
  /* font-weight: 600; */
  padding-top: 2rem;
  padding-bottom: 1rem;
`;

export const PageSubHeader = styled.div`
  font-size: 1.6rem;
  font-weight: 300;
  padding-bottom: 1rem;
`;
export const PageNotice = styled.div`
  font-size: 1.1rem;
  font-weight: 300;
  padding-bottom: 1rem;
`;

export const PageLargeNotice = styled.div`
  font-size: 1.6rem;
  font-weight: 300;
  padding-bottom: 2rem;
  padding-top: 2rem;
  text-align: center;
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: #013c5f;
  font-size: 1.25rem;
  font-weight: 500;

  /* border-left: 4px #013b5f solid; */
  padding-left: 6px;

  margin-top: 1.5rem;
  margin-bottom: 0.45rem;

  &:first-of-type {
    margin-top: 1rem;
  }
`;

export const CardHeader = styled.div`
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  font-size: 1.25rem;
  color: #013c5f;
  font-weight: 400;
`;

export const SubHeader = ({ children }) => (
  <div className="mb-2 mt-4">
    <h4>{children}</h4>
  </div>
);

export const SubSubHeader = ({ children }) => (
  <div className="mb-2 mt-4">
    <h5>{children}</h5>
  </div>
);

export const MutedHeader = ({ children }) => <h6 className="text-muted mt-2 ml-1">{children}</h6>;

export const PageLead = ({ children }) => (
  <div className="text-center pb-4">
    <p className="lead">{children}</p>
  </div>
);

export const Breadcrumb = ({ children, noMargin }) => (
  <nav className="site-breadcrumb-container">
    <ol className={"site-breadcrumb container " + (noMargin ? "mb-0" : "")}>{children}</ol>
  </nav>
);

export const BreadcrumbItem = ({ children, to }) => <li>{to ? <Link to={to}>{children}</Link> : children}</li>;

export const Loading = () => (
  <div className="d-flex align-items-center flex-column text-primary">
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
    {/* <h4 className="text-primary pt-3">Loading...</h4> */}
  </div>
);

export const FancyLoading = () => (
  <div className="d-flex my-3 align-items-center flex-column text-primary">
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
    {/* <h4 className="text-primary pt-3">Loading...</h4> */}
  </div>
);

export const Linkify = styled.span`
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
  color: #007bff;
  font-size: 0.9rem;
  line-height: initial;

  &:hover {
    font-weight: 600;
    color: #00629e;
  }
`;

export const FancyHeader = styled.div`
  padding-top: 10px;
  padding-right: 5px;
  padding-bottom: 10px;
  padding-left: 5px;
  /* min-height: 65px; */
  /* box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.22); */
  box-shadow: 0 1px 1px rgba(50, 58, 70, 0.1);

  background: #24476a;
  /* background: #d3e3e6; */
  /* background: #83a4d4;
  background: -webkit-linear-gradient(to right, #bfcbd8 48%, #a6c3de);
  background: linear-gradient(to right, #bfcbd8 48%, #a6c3de );  */
  /* content: "";
    display: block;
    height: 165px;
    width: 100%;
    position: absolute;
    background-color: #25476a;
    z-index: 0; */

  ${props =>
    props.noheight &&
    css`
      min-height: inherit;
    `}
`;

// export const FancyHeaderContainer = ({ children }) => <div className="d-flex align-items-center">{children}</div>;

export const FancyHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  /* min-height: 45px; */
  /* color: white; */

  ${props =>
    props.noheight &&
    css`
      min-height: inherit;
    `}
`;

export const FancyHeaderSmall = styled.div`
  & > span {
    margin-right: 10px;
    font-size: 0.9em;
  }
`;

export const FancyHeaderIcon = styled.span`
  color: white;
  margin-right: 18px;
  font-size: 2.75rem;

  ${props =>
    props.small &&
    css`
      font-size: 1.75rem;
    `}
`;

export const FancyHeaderTitle = ({ children, muted }) => (
  <div className="pb-0 mb-0 d-flex justify-content-between align-items-baseline">
    <h3 className="mb-0">{children}</h3>
    {muted}
  </div>
);

export const FancyHeaderTitleLink = styled.div`
  padding-bottom: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: white;
  font-size: 1.75rem;
  font-weight: 300;

  &:hover {
    border-bottom: 1px #013c5f solid;
  }

  & > a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
      text-decoration: inherit;
    }
  }
`;

export const FancyHeaderTitleSmall = ({ children, muted }) => (
  <div className="pb-0 mb-0 d-flex justify-content-between align-items-baseline">
    <h4 className="mb-0">{children}</h4>
    {muted}
  </div>
);

export const FancyHeaderSpacer = styled.div`
  flex-grow: 1;
`;

export const FancyHeaderSubTitle = ({ children }) => (
  <div className="mb-0 mt-0">
    <h6 className="text-muted mb-1">{children}</h6>
  </div>
);

export const FancyHeaderTitleContainer = styled.div`
  /* flex-grow: 1; */
  margin-right: 1rem;
`;
export const FancyHeaderButton = styled.div`
  font-size: 1rem;
  align-self: flex-end;
  color: rgb(1, 59, 95);
  padding: 5px;
  margin-left: 20px;
  padding-bottom: 3px;
  border-bottom: 1px rgb(1, 59, 95) solid;
  cursor: pointer;

  &:hover {
    color: rgb(0, 123, 255);
    border-bottom: 1px rgb(0, 123, 255) solid;
  }

  & > a {
    color: inherit;

    &:hover {
      text-decoration: inherit;
    }
  }

  ${props =>
    props.nounderline &&
    css`
      border-bottom: 0;

      &:hover {
        border-bottom: 0;
      }
    `}
`;

export const FancyHeaderInfo = styled.div`
  padding-left: 10px;
  margin-left: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  & > div {
    text-align: center;
    font-size: 0.9rem;
  }

  & > div:first-of-type {
    font-weight: 600;
    /* font-size: 1rem; */
  }
`;

export const FancyHeaderVerticalTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;

  display: flex;

  align-items: baseline;
  line-height: initial;

  & a {
    color: inherit;
  }
`;
export const FancyHeaderVerticalInfo = styled.div`
  padding-left: 10px;
  padding-right: 10px;

  display: flex;

  align-items: baseline;
  font-size: 0.9rem;
  line-height: initial;

  & > div {
    text-align: center;
    margin-right: 2px;
    margin-left: 2px;
    display: flex;
    align-items: center;
    font-weight: 300;
  }

  & > div:first-of-type {
    font-weight: 400;
    /* font-size: 1rem; */
  }

  ${props =>
    props.purple &&
    css`
      color: #aa076b;
    `}
  ${props =>
    props.green &&
    css`
      color: #2c6d0e;
    `}
`;

export const FancySubmenu = styled.div`
  padding-top: 6px;
  padding-right: 25px;
  padding-bottom: 6px;
  padding-left: 25px;
  background: #cbd8e6;
  font-size: 0.9rem;
`;

export const FancySubmenuButton = styled.div`
  align-self: flex-end;
  color: rgb(1, 59, 95);
  padding: 5px;
  margin-left: 20px;
  padding-bottom: 3px;
  border-bottom: 1px rgb(1, 59, 95) solid;
  cursor: pointer;

  &:hover {
    color: rgb(0, 123, 255);
    border-bottom: 1px rgb(0, 123, 255) solid;
  }

  & > a {
    color: inherit;

    &:hover {
      text-decoration: inherit;
    }
  }

  ${props =>
    props.nounderline &&
    css`
      border-bottom: 0;

      &:hover {
        border-bottom: 0;
      }
    `}
`;

export const getFileIcon = contentType => {
  if (contentType.startsWith("application/pdf")) return "file pdf outline";
  if (contentType === "application/zip") return "file archive outline";

  if (contentType.startsWith("image")) return "file image outline";
  if (contentType.startsWith("video")) return "file video outline";
  if (contentType.startsWith("audio")) return "file audio outline";

  if (contentType === "application/msword") return "file word outline";
  if (contentType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    return "file word outline";

  if (contentType === "application/vnd.ms-powerpoint") return "file powerpoint outline";
  if (contentType === "application/vnd.openxmlformats-officedocument.presentationml.presentation")
    return "file powerpoint outline";

  if (contentType === "application/vnd.ms-excel") return "file excel outline";
  if (contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") return "file excel outline";

  if (contentType === "application/x-rar-compressed") return "file archive outline";
  if (contentType === "application/x-tar") return "file archive outline";
  if (contentType === "application/x-rar-compressed") return "file archive outline";
  if (contentType === "application/x-gzip") return "file archive outline";
  if (contentType === "application/x-bzip") return "file archive outline";
  if (contentType === "application/x-bzip2") return "file archive outline";

  return "file alternate outline";
};
