import styled from "styled-components";

export const Modal = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 !important;

  overflow: hidden;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #12304d;

  position: absolute;

  z-index: 100;
  width: 500px;

  right: 10px;
  top: 70px;
  color: black;

  /* position: fixed;
  left: 50%;
  margin-left: -250px;
  width: 500px;
  z-index: 100;
  bottom: 50px; */

  /* animation-duration: 1.5s;
  animation-name: slide_out_from_bottom; */
`;

export const Header = styled.div`
  display: flex;
  background-color: #12304d;
  border-bottom: 5px #cddc38 solid;
  color: white;
  padding: 8px 15px;
  align-items: center;
  justify-content: space-between;
  /* font-weight: 300; */

  div {
    cursor: pointer;
    &:hover {
      color: #007bff;
    }
  }
`;

export const Title = styled.div``;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  font-size: 0.9rem;

  & > div {
    margin: 5px 5px;

    &:nth-child(1) {
      & > div:nth-child(1) {
        font-size: 0.8rem;
      }
      flex: 1;
    }

    &:nth-child(2) {
      /* color: red; */
      opacity: 0.8;
      font-size: 0.8rem;
      margin-right: 10px;
      margin-left: 10px;
    }

    &:nth-child(3) {
      /* color: #007bff; */
      margin-right: 10px;
    }

    &:nth-child(4) {
      /* color: #007bff; */
    }
  }
`;
