import React from "react";
import { findKey } from "lodash";
import { Route, Switch, useParams, useRouteMatch, Link, Redirect } from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";

import { useAuth } from "../../../Session";
import { useFirebase } from "../../../Firebase";
import { useFirestoreDoc, useFirestoreQuery } from "../../../../utils/firebaseHooks";

import { Header, Breadcrumb, Divider, Icon, Grid, Placeholder } from "semantic-ui-react";

// Menu
import { CourseMenu } from "./CourseMenu";

// Sub-Pages
import AssignmentsPage, { AssignmentsPageMenu } from "./SubPages/PageAssignments";
import AssignmentDetailsPage, { AssignmentDetailsPageMenu } from "./SubPages/PageDetails";
import MessagesPage, { MessagesPageMenu } from "./SubPages/PageMessages";
import AnnouncementPage from "./SubPages/PageAnnouncements";

export default function CoursePage({ match }) {
  const firebase = useFirebase();
  const authUser = useAuth();
  const userId = authUser.uid;
  const courseId = match.params.course_id;
  let { path } = useRouteMatch();

  const [course, isLoadingCourse] = useFirestoreDoc(firebase.course(courseId));
  const [assignments, isLoadingAssignments] = useFirestoreQuery(firebase.assignmentsOfCourse(userId, courseId));

  // User is not enrolled to course
  if (!(courseId in authUser.enrolled)) {
    console.log(">>>>> Not  sigend");
    return <Redirect to={ROUTES.COURSES} />;
  }

  return (
    <React.Fragment>
      {/* Page Header */}
      <Header as="h1">
        <Icon name="book" />
        <Header.Content>{authUser.enrolled[courseId]}</Header.Content>
      </Header>

      {/* Breadcrumbs */}
      <Breadcrumb>
        <Breadcrumb.Section>
          <Icon name="home" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section link as={Link} to={ROUTES.COURSES}>
          My Courses
        </Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section active>{authUser.enrolled[courseId]}</Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section>Assignments</Breadcrumb.Section>
      </Breadcrumb>

      {/* Divider */}
      <Divider
        style={{
          paddingBottom: "1rem"
        }}
      />

      {isLoadingCourse || isLoadingAssignments ? (
        <PagePlaceholder />
      ) : (
        <Switch>
          {/* Announcement Page */}
          <Route exact path={`${path}/announcements`}>
            <Grid>
              <Grid.Column width={4}>
                <CourseMenu activeItem="announcements" />
              </Grid.Column>
              <Grid.Column width={12}>
                <AnnouncementPage course={course} />
              </Grid.Column>
            </Grid>
          </Route>

          {/* Messages Page */}
          <Route exact path={`${path}/messages`}>
            <Grid>
              <Grid.Column width={4}>
                <MessagesPageMenu />
              </Grid.Column>
              <Grid.Column width={12}>
                <MessagesPage />
              </Grid.Column>
            </Grid>
          </Route>

          {/* Assignment List Page */}
          <Route exact path={path}>
            <Grid>
              <Grid.Column width={4}>
                {/* <CourseMenu activeItem="assignments" /> */}
                <AssignmentsPageMenu assignments={assignments} />
              </Grid.Column>
              <Grid.Column width={12}>
                <AssignmentsPage course={course} assignments={assignments} userId={userId} courseId={courseId} />
              </Grid.Column>
            </Grid>
          </Route>

          {/* Assignment Details Page */}
          <Route path={`${path}/:exercise_id`}>
            <PageDetailsContainer course={course} assignments={assignments} />
          </Route>
        </Switch>
      )}
    </React.Fragment>
  );
}

function PageDetailsContainer({ course, assignments }) {
  const { exercise_id } = useParams();

  return (
    <Grid divided>
      {/* <Grid.Column width={4} style={{ paddingRight: "1em" }}> */}
      <Grid.Column width={4}>
        <AssignmentDetailsPageMenu course={course} assignments={assignments} currentExerciseId={exercise_id} />
      </Grid.Column>
      <Grid.Column width={12} style={{ paddingLeft: "1.5em" }}>
        {/* <Grid.Column width={12}> */}
        <AssignmentDetailsPage
          key={exercise_id}
          exerciseId={exercise_id}
          assignment={
            assignments[
              findKey(assignments, {
                exerciseId: exercise_id
              })
            ]
          }
        />
      </Grid.Column>
    </Grid>
  );
}

function PagePlaceholder() {
  return (
    <Grid>
      <Grid.Column width={4}>
        <Placeholder>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder>
      </Grid.Column>
      <Grid.Column width={12}>
        <Placeholder>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder>
      </Grid.Column>
    </Grid>
  );
}
