import React from "react";
import { useHistory } from "react-router-dom";

import { Container } from "semantic-ui-react";
import * as Styled from "./index.styles";

import ContentImage from "./user_content.png";
import { ColoredLogo } from "../../App/Logo";
import { Icon } from "../../UI/Icons";
import Button from "../../UI/Landing/Button";

export default function Landing() {
  const history = useHistory();

  return (
    <>
      <Styled.GlobalHeader>
        <Styled.Header style={{ fontSize: "3rem", marginBottom: "2rem" }}>
          <ColoredLogo />
        </Styled.Header>
        <Styled.Header>
          A Learning Management System designed for
          <br /> bootcamp instructors and students
        </Styled.Header>
      </Styled.GlobalHeader>

      <Styled.GreenBlock>
        <Container>
          <Styled.GreenGroup>
            <Styled.GreenIcons>
              <span className="fal fa-user-graduate" />
              <span className="fal fa-user-graduate" />
              <span className="fal fa-user-graduate" />
              <span className="fal fa-user-graduate" />
            </Styled.GreenIcons>
            <p>
              Optimize <b>tutorial-based instruction</b> <br />
              and provide <b>live support</b> to your students
              <br />
              with <b>real-time classroom management</b>.
            </p>
          </Styled.GreenGroup>
        </Container>
      </Styled.GreenBlock>

      <Styled.Block style={{ margin: "5rem 0" }}>
        <Container>
          <Styled.BlockWithPicture>
            <Styled.Vertical>
              <Icon className="fad fa-hands-helping" />
              <Styled.SubHeader>
                Our smart platform enhances training-course collaboration between teachers and learners.
              </Styled.SubHeader>
              <Button onClick={() => history.push("/features")}>See what synclass can do</Button>
            </Styled.Vertical>
            <Styled.Image srcSet={`${ContentImage} 2x`} />
          </Styled.BlockWithPicture>
        </Container>
      </Styled.Block>

      <Styled.CenteredBlock>
        <Icon className="fad fa-chart-line" />
        <p>
          Synclass empowers <b>course tutors</b> and <b>bootcamp<br />
          instructors</b> to better <b>support their students</b> with an<br/>
          end-to-end learning management solution.
        </p>

        <Container>
          <Styled.CircledGroup>
            <Styled.Circle>
              <span className="far fa-pencil-alt" />
              <h5>Deliver course content and instruction</h5>
            </Styled.Circle>
            <Styled.Circle>
              <span className="far fa-tasks" />
              <h5>Monitor progress at a glance</h5>
            </Styled.Circle>
            <Styled.Circle>
              <span className="far fa-diagnoses" />
              <h5>Review in-progress work and give live support</h5>
            </Styled.Circle>
          </Styled.CircledGroup>
        </Container>
        <Button onClick={() => history.push("/features")}>Explore synclass features</Button>
      </Styled.CenteredBlock>

      {/* 
      <Container
        style={{
          paddingBottom: "2rem",
          marginBottom: "2rem"
        }}>
        <Styled.Block>
          <Styled.Image src={ContentImage} />
        </Styled.Block>

        <Styled.Block style={{ marginTop: "100px" }}>
          <Styled.Paragraph>
            We provide an end-to-end solution to teach your class,
            <br /> from serving the content - to rapidly reviewing assignments.
          </Styled.Paragraph>
          <Button primary size="big" icon icon labelPosition="right" onClick={() => history.push(`/features`)}>
            Learn More <Icon name="right arrow" />
          </Button>
        </Styled.Block>
      </Container> */}
    </>
  );
}
