import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useAuth } from "../../../../Session";
import { useFirebase } from "../../../../Firebase";
import { useFirestoreDoc, useFirestoreQuery } from "../../../../../utils/firebaseHooks";

import { Header, Message, Icon, Menu, Segment, Button, Divider, Transition, Dropdown } from "semantic-ui-react";

import Textarea from "react-textarea-autosize";
import UploadZone from "../../../../UI/UploadZone";
import { Submission } from "../../../../UI/Submission/Submission";
import AsyncButton from "../../../../UI/AsyncButton";
import { AdminMessagesList } from "../../../../Common/AdminMessagesList";

export default function AssignmentPage({ course, current, exercise, enrolledByUserId }) {
  const firebase = useFirebase();
  const params = useParams();
  const history = useHistory();

  const [activeMenu, setActiveMenu] = useState("latest");
  const [submissions, isLoading] = useFirestoreQuery(
    firebase
      .assignmentSubmissions(params.userId, exercise._id)
      .where("courseId", "==", course._id)
      .orderBy("submittedAt", "desc"),
    [params.userId]
  );

  const [messages, isLoadingMessages] = useFirestoreQuery(
    firebase.messagesOfStudentInExercise(exercise._id, course._id, params.userId).orderBy("timestamp", "desc"),
    [params.userId]
  );

  const latestSubmission = submissions && submissions.length > 0 ? submissions[0] : null;
  const allowReview = latestSubmission && latestSubmission.state !== "DONE" && latestSubmission.state !== "REDO";
  const showReview = (latestSubmission && latestSubmission.state === "IN_REVIEW") || false;

  async function toggleReview() {
    if (latestSubmission.state === "SUBMITTED") {
      await latestSubmission._ref.update({
        state: "IN_REVIEW",
        reviewStartTime: firebase.fieldValue.serverTimestamp()
      });
    } else if (latestSubmission.state === "IN_REVIEW") {
      await latestSubmission._ref.update({
        state: "SUBMITTED",
        reviewStartTime: null
      });
    }
  }

  async function handleReviewDone(status, reviewData) {
    if (status !== "DONE" && status !== "REDO") return;
    if (!latestSubmission) return;

    await latestSubmission._ref.update({
      state: status,
      reviewEndTime: firebase.fieldValue.serverTimestamp(),
      reviewData: reviewData
    });
  }

  async function reopen() {
    await latestSubmission._ref.update({
      state: "REDO"
    });
  }

  async function cancelAssignment() {
    await firebase.assignment(params.userId, exercise._id).delete();
    history.push(`/manage/${course._id}/${exercise._id}/assignments`);
  }

  function renderReview() {
    return (
      <React.Fragment>
        <Divider section horizontal>
          <Header size="medium" content="Review Assignment" icon="pencil" />
        </Divider>
        <Button
          fluid
          color="orange"
          icon="time"
          content={showReview ? "Cancel Review" : "Start Review"}
          basic={showReview}
          onClick={toggleReview}
        />
        {latestSubmission && (
          <ReviewForm
            visible={showReview}
            submission={latestSubmission}
            userId={params.userId}
            exerciseId={exercise._id}
            onReviewDone={handleReviewDone}
          />
        )}
      </React.Fragment>
    );
  }

  function renderPageContent() {
    if (activeMenu === "latest") {
      if (latestSubmission) {
        return <Submission key={latestSubmission._id} submission={latestSubmission} />;
      } else {
        return <Message info content="No submissions have been made yet" />;
      }
    }

    if (activeMenu === "all") {
      if (submissions.length > 0) {
        return submissions.map(submission => <Submission key={submission._id} submission={submission} />);
      } else {
        return <Message info content="No submissions have been made yet" />;
      }
    }

    if (activeMenu === "messages") {
      return <AssignmentMessages messages={messages} isLoading={isLoadingMessages} />;
    }
  }

  // Render
  return (
    <React.Fragment>
      <Header size="medium" textAlign="center">
        <Icon name="user" circular />
        &nbsp;{enrolledByUserId && enrolledByUserId[params.userId] && enrolledByUserId[params.userId][0].userName}
      </Header>

      <Menu pointing secondary>
        <Menu.Item
          name="Latest Submission"
          color="blue"
          icon="arrow alternate circle right"
          onClick={() => setActiveMenu("latest")}
          active={activeMenu === "latest"}
        />
        <Menu.Item onClick={() => setActiveMenu("messages")} active={activeMenu === "messages"}>
          <Icon name="comments" /> Messages ({isLoadingMessages ? "-" : messages.length})
        </Menu.Item>
        <Menu.Item onClick={() => setActiveMenu("all")} active={activeMenu === "all"}>
          <Icon name="history" /> Submission History ({isLoading ? "-" : submissions.length})
        </Menu.Item>
        <Menu.Menu position="right">
          <Dropdown item text="Actions">
            <Dropdown.Menu>
              <Dropdown.Item disabled={latestSubmission && latestSubmission.state !== "DONE"} onClick={reopen}>
                Reopen Assignment
              </Dropdown.Item>
              <Dropdown.Item onClick={cancelAssignment}>Cancel Assignment</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>

      {isLoading ? (
        <Message info>Loading...</Message>
      ) : submissions ? (
        renderPageContent()
      ) : (
        <Message info>Nothing have been submitted yet</Message>
      )}

      {!isLoading && activeMenu === "latest" && allowReview && renderReview()}
    </React.Fragment>
  );
}

function ReviewForm({ visible, userId, exerciseId, submission, onReviewDone }) {
  const auth = useAuth();
  const firebase = useFirebase();

  const [text, setText] = useState("");

  const basePath = `assignments/${userId}/${submission.courseId}/${exerciseId}/${submission.version}/review`;

  /* Load temp Private-Area to upload files */
  const [submissionPrivateArea, isLoading] = useFirestoreDoc(
    firebase.assignmentSubmissionPrivate(userId, exerciseId, submission._id)
  );

  /* File deleted  */
  async function handleFileDeleted(fileName) {
    let attachments = { ...submissionPrivateArea.attachments };
    delete attachments[fileName];

    await submissionPrivateArea._ref.update({
      attachments
    });
  }

  /* File uploaded successfully */
  async function handleFileUploaded(fileName, metadata) {
    let attachments = {
      ...submissionPrivateArea.attachments,
      [fileName]: metadata
    };

    await submissionPrivateArea._ref.set(
      {
        attachments,
        courseId: submission.courseId
      },
      { merge: true }
    );
  }

  /* Done Review */
  function completeReview(status) {
    /* (1) Complete review details (in private area) */
    const reviewData = {
      courseId: submission.courseId,
      comments: text,
      reviewerUserId: auth.uid,
      reviewerUserName: auth.name,
      attachments: submissionPrivateArea.attachments || {}
    };

    // Update private area
    submissionPrivateArea._ref.set(reviewData, { merge: true });

    // Mark review as done
    onReviewDone(status, reviewData);
  }

  return (
    <Transition visible={visible} animation="slide down" duration="300">
      <Segment color="orange">
        <div>
          <Textarea
            minRows={4}
            style={{
              width: "100%"
            }}
            className="clear-textarea"
            placeholder="Review text for student"
            autoFocus
            value={text}
            onChange={event => setText(event.target.value)}
          />
        </div>
        {!isLoading && (
          <UploadZone
            basePath={basePath}
            currentFiles={submissionPrivateArea.attachments || {}}
            onFileDeleted={handleFileDeleted}
            onFileUploaded={handleFileUploaded}
            maxFileSize={10}
          />
        )}
        <Button.Group fluid widths="2">
          <AsyncButton content="Please Resubmit" negative onClick={() => completeReview("REDO")} />
          <Button.Or />
          <AsyncButton content="Done" positive onClick={() => completeReview("DONE")} />
        </Button.Group>
      </Segment>
    </Transition>
  );
}

function AssignmentMessages({ messages, isLoading }) {
  if (isLoading) {
    return (
      <Message icon>
        <Icon name="circle notched" loading />
        <Message.Content>
          <Message.Header>Just one second</Message.Header>
          We are fetching that content for you.
        </Message.Content>
      </Message>
    );
  } else {
    return <AdminMessagesList messages={messages} showSearch={false} />;
  }
}
