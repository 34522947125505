import styled from "styled-components";

export const AddButton = styled.div`
  border: 1px dotted #00629e;
  border-radius: 0.25rem;
  background-color: #fff;
  background-clip: border-box;
  width: 100%;

  padding: 0.8rem;
  display: flex;
  justify-content: start;
  align-items: center;
  line-height: initial;

  opacity: 0.7;

  margin-bottom: 1rem;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  & > span:nth-child(1) {
    color: #00629e;
    font-size: 1.2rem;
    margin-right: 1rem;
  }

  & > span:nth-child(2) {
    color: #00629e;
    font-size: 1.2rem;
  }
`;

export const AlignedLabel = styled.label`
  margin-top: 20px;
  /* display: flex; */
  font-size: 1.2rem;
  /* border-bottom: 1px #8cc2dd dotted; */
  display: block;

  &:first-of-type {
    margin-top: 0px;
  }

  & > div {
    /* flex: 7; */
  }

  & > span {
    /* flex: 3; */
    display: inline-block;
    min-width: 250px;
  }

  & > span > span {
    width: 25px;
  }
`;

export const CardUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed rgba(0, 0, 0, 0.225);
  border-radius: 0.25rem;

  & > div {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  & > div > span {
    font-size: 2rem;
  }
`;

export const CardTitle = styled.h5`
  margin-bottom: 0.75rem;
`;

export const CardTitleSmall = styled.h6`
  margin-top: 1rem;
  margin-bottom: 0.75rem;
`;

export const Options = styled.div`
  border-top: 1px #8cc2dd dotted;
`;

export const FileEntry = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  margin-bottom: 6px;

  &:last-of-type {
    margin-bottom: 0;
  }

  & > div {
    width: 120px;
    height: 12px;
  }
`;

export const FileLink = styled.a`
  margin-right: 10px;
`;

export const FileEntryButton = styled.span`
  font-size: 0.9rem;
  margin-left: 10px;
  /* color: #007bff; */
  cursor: pointer;

  &:hover {
    font-weight: 600;
    color: #00629e;
  }

  & > span {
    font-size: 0.75rem;
  }
`;
