import React from "react";
import { Link } from "react-router-dom";

import { useFirebase } from "../../../Firebase";
import { useFirestoreQuery } from "../../../../utils/firebaseHooks";

import { Header, Message, Icon, Breadcrumb, Divider } from "semantic-ui-react";
import { AdminMessagesList } from "../../../Common/AdminMessagesList";
import { ManagePageContainer } from "../../../UI/ManagePageContainer";

export default function MessagesPage({ course, current, exercise }) {
  const firebase = useFirebase();

  const [messages, isLoading] = useFirestoreQuery(firebase.messagesOfCourse(course._id).orderBy("timestamp", "asc"));

  const breadcrumb = [
    // { key: "Home", content: <Icon name="home" /> },
    { key: "Course Content", content: course.name, as: Link, to: `/manage/${course._id}` },
    { key: "Exercises", content: "Messages", active: true }
  ];

  return (
    <ManagePageContainer>
      <Header
        as="h1"
        icon="file alternate outline"
        content="Messages"
        subheader="See all messages sent in this course"
      />
      <Breadcrumb icon="right angle" sections={breadcrumb} />
      <Divider />
      {isLoading ? (
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>Just one second</Message.Header>
            We are fetching that content for you.
          </Message.Content>
        </Message>
      ) : (
        <AdminMessagesList messages={messages} showTitle={true} />
      )}
    </ManagePageContainer>
  );
}
