import React from "react";

import { Switch, Route } from "react-router-dom";
import { useFirebase } from "../../../Firebase";
import { useFirestoreQuery } from "../../../../utils/firebaseHooks";
import { Link } from "react-router-dom";
import { Header, Icon, Breadcrumb, Divider, Button, Placeholder } from "semantic-ui-react";
import { ManagePageContainer } from "../../../UI/ManagePageContainer";

import StudentList from "./StudentList";
import StudentDashboard from "./StudentDashboard";

export default function StudentListPage({ course, current }) {
  const firebase = useFirebase();

  // Load Stuent List
  const [enrolled, isLoading] = useFirestoreQuery(firebase.enrolledStudents(course._id).orderBy("userName", "asc"));

  const breadcrumb = [
    { key: "Home", content: <Icon name="home" /> },
    { key: "Students", content: "Students" }
    // { key: "Exercises", content: "Messages", active: true }
  ];

  var placeholder = (
    <Placeholder fluid>
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder>
  );

  return (
    <ManagePageContainer>
      <Header as="h1">
        <Button
          color="purple"
          // size="tiny"
          floated="right"
          as={Link}
          to={`/manage/${course._id}/invite`}>
          <Icon name="send" /> Invite Students
        </Button>
        <Icon name="user outline" />
        <Header.Content>
          Student List
          <Header.Subheader>List of all enrolled students</Header.Subheader>
        </Header.Content>
      </Header>

      <Breadcrumb icon="right angle" sections={breadcrumb} />

      <Divider />

      {isLoading ? (
        placeholder
      ) : (
        <Switch>
          <Route exact path="/manage/:course_id/students">
            <StudentList students={enrolled} course={course} />
          </Route>
          <Route path="/manage/:course_id/students/:user_id/:view?">
            <StudentDashboard students={enrolled} course={course} current={current} />
          </Route>
        </Switch>
      )}
    </ManagePageContainer>
  );
}
