import { useState, useEffect } from "react";

export const useFirestoreDoc = ref => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [doc, setDoc] = useState(null);

  useEffect(() => {
    return ref.onSnapshot(
      doc => {
        setDoc({
          _id: doc.id,
          _ref: doc.ref,
          ...doc.data()
        });
        setIsLoading(false);
      },
      error => {
        setError(error.code);
        setIsLoading(false);
      }
    );
  }, []);

  return [doc, isLoading, error];
};

export const useFirestoreQuery = (ref, deps = []) => {
  const [isLoading, setIsLoading] = useState(true);
  const [docs, setDocs] = useState(null);

  useEffect(() => {
    if (!isLoading) setIsLoading(true);

    return ref.onSnapshot(docs => {
      let result = [];

      docs.forEach(doc => {
        result.push({
          ...doc.data(),
          _id: doc.id,
          _ref: doc.ref
        });
      });
      setDocs(result);
      setIsLoading(false);
    });
  }, deps);

  return [docs, isLoading];
};

export const useRealtimeData = ref => {
  const [data, setData] = useState(null);

  useEffect(() => {
    ref.on("value", snapshot => {
      if (snapshot.val() != null) {
        setData(snapshot.val());
      }
    });

    return () => {
      ref.off();
    };
  }, []);

  return data;
};
