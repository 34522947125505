export function canManage(auth, courseId) {
  if (null == auth.claims) {
    return true; // until claims available
  }

  if (!auth.claims.owner) {
    return false;
  }

  if (-1 !== auth.claims.owner.indexOf(courseId)) {
    return true;
  }

  return false;
}
