import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import SignOutButton from "../../Pages/SignOut";
import DownloadsModal from "../../UI/DownloadsModal";

import * as ROUTES from "../../../constants/routes";

import { AuthUserContext } from "../../Session";

import { Menu, Container, Icon, Dropdown } from "semantic-ui-react";
import { Logo } from "../Logo";

const Navigation = ({ wide }) => (
  <AuthUserContext.Consumer>
    {authUser => (authUser ? <NavigationAuth authUser={authUser} wide={wide} /> : <NavigationNonAuth />)}
  </AuthUserContext.Consumer>
);

export const ManageNavigation = () => {
  const authUser = useContext(AuthUserContext);

  return (
    <Menu
      inverted
      className="synclass"
      fixed="top"
      style={{
        padding: "0.25rem"
      }}>
      <Menu.Item as="a" header className="logo">
        <Icon name="sync alternate" />
        <span>SYN</span>
        <span>CLASS</span>
      </Menu.Item>

      <Menu.Item name="Course List" icon="caret left square" as={Link} to={ROUTES.MANAGE} />
      <Menu.Menu position="right">
        {/* <Menu.Item name="Downloads" icon="arrow alternate circle down" /> */}
        <DownloadsModal />
        <Dropdown item simple text={authUser.name}>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={ROUTES.ACCOUNT}>
              Account Settings
            </Dropdown.Item>
            <SignOutButton />
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
};

export const NavigationAuth = ({ authUser, wide }) => {
  return (
    <Menu
      inverted
      className="synclass"
      fixed="top"
      style={{
        padding: "0.25rem"
      }}>
      <Container>
        <Menu.Item as="a" header className="logo">
          <Icon name="sync alternate" />
          <span>SYN</span>
          <span>CLASS</span>
        </Menu.Item>

        <Menu.Item name="Courses" icon="tasks" as={Link} to={ROUTES.COURSES} />
        <Menu.Item name="Messages" icon="comments" as={Link} to={ROUTES.MESSAGES} />
        <Menu.Menu position="right">
          <Dropdown item simple text={authUser.name}>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={ROUTES.ACCOUNT}>
                Account Settings
              </Dropdown.Item>
              {/* <Dropdown.Item>Logout</Dropdown.Item> */}
              <SignOutButton />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Container>
    </Menu>
  );
};

export const NavigationNonAuth = () => {
  const location = useLocation();

  return (
    <Menu
      inverted
      className="synclass"
      fixed="top"
      style={{
        padding: "0.25rem",
        border: "0"
      }}>
      <Container>
        <Menu.Item header className="logo" as="a" href="https://synclass.com">
          <Logo />
        </Menu.Item>

        {/* <Menu.Item name="Features" as={Link} to={ROUTES.FEATURES} active={location.pathname === ROUTES.FEATURES} /> */}
        <Menu.Item name="Features" as="a" href="https://synclass.com/features" />
        {/* <Menu.Item name="Pricing" as={Link} to={ROUTES.PRICING} active={location.pathname === ROUTES.PRICING} /> */}
        <Menu.Item name="Sign Up" as={Link} to={ROUTES.SIGN_UP} active={location.pathname === ROUTES.SIGN_UP} />
        <Menu.Menu position="right">
          <Menu.Item
            name="Login"
            as={Link}
            to={ROUTES.SIGN_IN}
            className="menu-special"
            active={location.pathname === ROUTES.SIGN_IN}
          />
          {/* <Menu.Item name="Contact Us" icon="envelope open outline" /> */}
        </Menu.Menu>
      </Container>
    </Menu>
  );
};

export default Navigation;
