import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import { Icon, Header, Button, Placeholder, Message, Grid } from "semantic-ui-react";

export function CourseList({ user }) {
  // Not loaded yet
  if (!user) {
    return (
      <Placeholder>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder>
    );
  }

  // Empty list
  if (!user.enrolled || _.isEmpty(user.enrolled)) {
    return <Message info>You are currently not enrolled to any course.</Message>;
  }

  return (
    <>
      <Grid divided="vertically" verticalAlign="middle">
        {Object.keys(user.enrolled).map(courseId => (
          <Grid.Row key={courseId} columns={2}>
            <Grid.Column style={{ margin: "0" }} width="10">
              <Header size="medium">{user.enrolled[courseId]}</Header>
            </Grid.Column>
            <Grid.Column textAlign="right" style={{ margin: "0" }} width="6">
              <Button primary as={Link} to={`/user/courses/${courseId}`} style={{ margin: 0 }}>
                Course Dashboard
                <Icon name="right chevron" />
              </Button>
            </Grid.Column>
          </Grid.Row>
        ))}
      </Grid>
    </>
  );
}
