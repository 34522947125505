import React from "react";
import { Route } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Menu, Icon, Header, Label, Divider } from "semantic-ui-react";
import "./sidebar.css";

export default function Sidebar({ course, submittedAssignments }) {
  return (
    <Menu
      pointing
      secondary
      vertical
      // fixed="left"
      style={{
        // marginTop: "4rem",
        paddingTop: "2rem",
        background: "#11304d1a",
        minWidth: "15rem"
      }}>
      <Menu.Item>
        <Header className="sidebar-header" size="tiny" textAlign="center" icon as={Link} to={`/manage/${course._id}`}>
          <Icon name="book" />
          {course.name}
        </Header>
        <div className="sidebar-statistics">
          <Label.Group color="green">
            <Label icon="user" content={course.enrolledCounter} as={Link} to={`/manage/${course._id}/students`} />
            <Label
              icon="envelope"
              content={course.invitationCounter > 0 ? course.invitationCounter : "Invite"}
              as={Link}
              to={`/manage/${course._id}/invite`}
            />
          </Label.Group>
        </div>
      </Menu.Item>

      <Divider />

      <NavMenuItem icon="announcement" label="Announcements" to={`/manage/${course._id}/announcements`} />
      <NavMenuItem icon="list" label="Course Content" to={`/manage/${course._id}`} />
      <NavMenuItem icon="tasks" label="Progress Matrix" to={`/manage/${course._id}/matrix`} />
      <NavMenuItem icon="user" label="Students" to={`/manage/${course._id}/students`} />
      <NavMenuItem icon="comments" label="Messages" to={`/manage/${course._id}/messages`} />

      {submittedAssignments && submittedAssignments.length > 0 && (
        <>
          <Divider />
          <Menu.Item header>Upcoming Tasks</Menu.Item>

          <Menu.Item as={Link} to={`/manage/${course._id}/upcoming`}>
            <Label color="green">{submittedAssignments ? submittedAssignments.length : null}</Label> Waiting for review
          </Menu.Item>
        </>
      )}
      <Divider />
      <NavMenuItem icon="cog" label="Settings" to={`/manage/${course._id}/edit`} />
    </Menu>
  );
}

export const Information = styled.div`
  padding: 4px;
  text-align: center;

  margin-bottom: 0.5rem;

  & > div:last-of-type {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    color: #23476aab;
  }
`;

export function NavMenuItem({ label, icon, to }) {
  return (
    <Route
      path={to}
      exact={true}
      children={({ match }) => (
        <Menu.Item name="content" as={Link} to={to} active={match ? true : false}>
          <Icon name={icon} />
          {label}
        </Menu.Item>
      )}
    />
  );
}
