import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";
import "firebase/functions";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_MEASUREMENT_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.fieldValue = app.firestore.FieldValue;

    this.db = app.firestore();
    this.rtdb = app.database();

    this.storage = app.storage();

    this.functions = app.functions();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = async () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** User API ***
  user = uid => this.db.doc(`users/${uid}`);
  users = () => this.db.collection("users");

  /* Courses API */
  course = course_id => this.db.doc(`courses/${course_id}`);
  courses = () => this.db.collection("courses");

  /* Announcements API */
  announcement = announcementId => this.db.collection(`announcements/${announcementId}`);
  announcements = courseId => this.db.collection(`announcements`).where("courseId", "==", courseId);

  /* Exercises API */
  exercises = course_id => this.course(course_id).collection("exercises");
  exercise = (course_id, exercise_id) => this.exercises(course_id).doc(exercise_id);

  /* Enrollment */
  enrolled = (courseId, userId) => this.db.doc(`/courses/${courseId}/enrolled/${userId}`);
  /* Only admin should be able to do this */
  enrolledStudents = courseId => this.db.collection(`/courses/${courseId}/enrolled`);

  /* Invitations */
  invitation = inviteId => this.db.doc(`invitations/${inviteId}`);
  invitedStudents = courseId => this.db.collection(`invitations`).where("courseId", "==", courseId);
  invitedCourses = email => this.db.collection(`invitations`).where("email", "==", email);

  /* Assignments (assigned exercise-user) */
  assignment = (userId, exerciseId) => this.db.doc(`assignments/${userId}/assignedExercises/${exerciseId}`);
  assignments = userId => this.db.collection(`assignments/${userId}/assignedExercises/`);
  assignmentsOfCourse = (userId, courseId) =>
    this.db
      .collection(`assignments/${userId}/assignedExercises/`)
      .where("courseId", "==", courseId)
      .orderBy("assignedAt");

  assignmentsOfCourseCollection = courseId =>
    this.db.collectionGroup(`assignedExercises`).where("courseId", "==", courseId);

  assignmentTempSubmission = (userId, exerciseId) =>
    this.db.doc(`assignments/${userId}/assignedExercises/${exerciseId}/tempSubmission/0`);

  assignmentSubmission = (userId, exerciseId, submissionId) =>
    this.db.doc(`assignments/${userId}/assignedExercises/${exerciseId}/submissions/${submissionId}`);

  assignmentSubmissionPrivate = (userId, exerciseId, submissionId) =>
    this.db.doc(`assignments/${userId}/assignedExercises/${exerciseId}/submissions/${submissionId}/private/0`);

  assignmentSubmissions = (userId, exerciseId) =>
    this.db.collection(`assignments/${userId}/assignedExercises/${exerciseId}/submissions/`);

  /* Async Downloads */
  downloadsOfUser = userId => this.db.doc(`downloads/${userId}`);

  /* Messages */
  message = messageId => this.db.doc(`messages/${messageId}`);
  messages = () => this.db.collection(`messages`);

  newsletter = () => this.db.collection(`newsletter`);
  contactus = () => this.db.collection(`contactus`);

  messagesOfCourse = courseId => this.db.collection(`messages`).where("courseId", "==", courseId);
  messagesOfStudent = userId => this.db.collection(`messages`).where("userId", "==", userId);
  messagesOfStudentInCourse = (courseId, userId) =>
    this.db
      .collection(`messages`)
      .where("courseId", "==", courseId)
      .where("userId", "==", userId);

  messagesOfExerciseInCourse = (exerciseId, courseId) =>
    this.db
      .collection(`messages`)
      .where("courseId", "==", courseId)
      .where("exerciseId", "==", exerciseId);

  messagesOfStudentInExercise = (exerciseId, courseId, userId) =>
    this.db
      .collection(`messages`)
      .where("exerciseId", "==", exerciseId)
      .where("courseId", "==", courseId)
      .where("userId", "==", userId);

  /* Matrix */
  matrix = courseId => this.rtdb.ref(`/matrix/${courseId}`);
  matrixOfExercise = (courseId, groupName, exerciseId) =>
    this.rtdb.ref(`/matrix/${courseId}/${groupName}/${exerciseId}`);

  onAuthUserListener = (next, fallback) => {
    return this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();
            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = [];
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              ...dbUser
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });
  };
}

export default Firebase;
