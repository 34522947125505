import React from "react";
import { isNumber } from "lodash";
import styled from "styled-components";
import { Popup, Table, Icon } from "semantic-ui-react";

import { ASSIGNMENT_ICON } from "../../constants/icons";

function getColor(color) {
  if (color === "grey") return "#888888";
  if (color === "red") return "#db2828";
  if (color === "green") return "#21ba45";
  if (color === "orange") return "#f2711c";
  if (color === "blue") return "#2185d0";
  return "white";
}

export const ProgressDiv = styled.div`
  border-radius: 4px;
  background: #e5e5e5;
  overflow: hidden;
  height: 1rem;
  display: inline-block;
  font-size: 4px;
  /* border: 1px solid rgba(34, 36, 38, 0.15); */
  text-align: left;
`;
export const BarDiv = styled.div`
  display: inline-block;
  /* min-width: 10px; */
  height: 100%;
  color: white;
  text-align: center;
  /* background: red; */
  background: ${props => getColor(props.color)};
`;

const STATUS_ORDER = ["ASSIGNED", "SUBMITTED", "IN_REVIEW", "REDO", "DONE"];

function ProgressBar(props) {
  const total = props.values["TOTAL"];

  function calcSize(value) {
    return `${(100 * value) / total}%`;
  }

  return (
    <ProgressDiv {...props} style={{ width: "200px" }}>
      {STATUS_ORDER.map(status => (
        <BarDiv key={status} color={ASSIGNMENT_ICON[status].color} style={{ width: calcSize(props.values[status]) }} />
      ))}
      {/* <BarDiv color="red" style={{ width: "50%" }} />
      <BarDiv color="blue" style={{ width: "20%" }} /> */}
    </ProgressDiv>
  );
}

export function StudentProgress({ values }) {
  return (
    <React.Fragment>
      <Popup position="top center" trigger={<ProgressBar values={values} />}>
        <Table compact size="small" singleLine definition>
          <Table.Body>
            {STATUS_ORDER.map(status => (
              <Table.Row key={status}>
                <Table.Cell textAlign="center" collapsing>
                  {isNumber(values[status]) ? values[status] : "?"}
                </Table.Cell>
                <Table.Cell>
                  <Icon name={ASSIGNMENT_ICON[status].icon} color={ASSIGNMENT_ICON[status].color} />{" "}
                  {ASSIGNMENT_ICON[status].title}
                </Table.Cell>
              </Table.Row>
            ))}
            <Table.Row>
              <Table.Cell>
                <b>{values["TOTAL"] || "-"}</b>
              </Table.Cell>
              <Table.Cell>
                <b>Total</b>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Popup>
    </React.Fragment>
  );
}
