import React from "react";
import { Link } from "react-router-dom";
import { SmallLogo } from "./Logo";
import { Segment, Container, List, Divider } from "semantic-ui-react";

export function Footer({ noMargin = false }) {
  return (
    <Segment style={{ margin: noMargin ? "0" : "5em 0em 0em", padding: "5em 0em" }} vertical>
      {!noMargin && <Divider style={{ marginBottom: "2em" }} />}
      <Container textAlign="center">
        <SmallLogo />
        <List horizontal divided link size="small">
          <List.Item as="a" href="https://synclass.com/features">
            Features
          </List.Item>
          {/* <List.Item as={Link} to="/pricing">
            Pricing
          </List.Item> */}
          <List.Item as={Link} to="/terms">
            Terms and Conditions
          </List.Item>
          <List.Item as={Link} to="/privacy">
            Privacy Policy
          </List.Item>
          <List.Item as={Link} to="/contact">
            Contact Us
          </List.Item>
        </List>
      </Container>
    </Segment>
  );
}
