import React from "react";

import { useFirebase } from "../../../Firebase";
import { useFirestoreQuery } from "../../../../utils/firebaseHooks";

import { Header, Icon, Breadcrumb, Divider, Placeholder } from "semantic-ui-react";
import { ManagePageContainer } from "../../../UI/ManagePageContainer";
import { InviteForm } from "../StudentInvitePage/InviteForm";

export default function StudentListPage({ course }) {
  const firebase = useFirebase();
  const [invitations, isLoading] = useFirestoreQuery(firebase.invitedStudents(course._id));

  function inviteUsers(emails) {
    let inviteUser = firebase.functions.httpsCallable("inviteUser");

    let promises = [];
    emails.forEach(email => {
      promises.push(inviteUser({ courseId: course._id, email: email }));
    });
    return Promise.all(promises);
  }

  async function cancelInvitation(inviteId) {
    await firebase.invitation(inviteId).delete();
  }

  var page = (
    <Placeholder fluid>
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder>
  );

  // Wait for loading
  if (!isLoading) {
    page = (
      <React.Fragment>
        <InviteForm onClick={inviteUsers} onCancel={cancelInvitation} invitations={invitations} />
      </React.Fragment>
    );
  }

  return (
    <ManagePageContainer>
      <Header as="h1" icon="send" content="Student Invitations" subheader="Invite new students to your course" />

      <Breadcrumb>
        <Breadcrumb.Section>
          <Icon name="home" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section active>Student Invitations</Breadcrumb.Section>
      </Breadcrumb>

      <Divider />

      {page}
    </ManagePageContainer>
  );
}
