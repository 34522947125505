import React from "react";

import { useAuth } from "../../Session";

import PasswordChangeForm from "./PasswordChange";
import { Header, Breadcrumb, Divider, Container, Grid, Message, Icon, Form, Button, Segment } from "semantic-ui-react";
import * as UI from "../../UI";

const AccountPage = () => {
  const auth = useAuth();

  return (
    <Container style={{ marginTop: "7em" }}>
      <Header as="h1" color="purple">
        <Icon name="comments" />
        <Header.Content>My Account</Header.Content>
      </Header>
      {/* <Breadcrumb>
        <Breadcrumb.Section>
          <Icon name="home" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section active>My Account</Breadcrumb.Section>
      </Breadcrumb> */}

      <Divider
        style={{
          paddingBottom: "1rem"
        }}
      />

      <Segment>
        <h3>Accound Details</h3>
        <Form>
          <Form.Field>
            <label>Name</label>
            <input readOnly value={auth.name} />
          </Form.Field>
          <Form.Field>
            <label>Email</label>
            <input readOnly value={auth.email} />
          </Form.Field>
        </Form>
      </Segment>
      <Segment>
        <h3>Change Password</h3>
        <PasswordChangeForm />
      </Segment>
    </Container>
  );
};

export default AccountPage;
