export function newMessage(assignment, content, displayName, timestamp) {
  return {
    userId: assignment.userId,
    courseId: assignment.courseId,

    exerciseId: assignment.exerciseId,
    exerciseTitle: assignment.exerciseTitle,

    isResolved: false,
    isReviewed: false,

    timestamp, //: firebase.fieldValue.serverTimestamp(),
    displayName,
    content
  };
}

// Returns the fields needs to be updated to resolve a message
export function resolveMessage(message, userName, userId, response, timestamp) {
  return {
    isReviewed: false,
    isResolved: true,

    response,
    responseAuthor: userName,
    responseAuthorId: userId,
    responseTimestamp: timestamp
  };
}

export function newAssignment(courseId, userId, exercise, timestamp) {
  if (exercise.type === undefined) {
    throw `You can't assign exercise ${exercise._id} - has no type`;
  }
  return {
    courseId,
    exerciseId: exercise._id,
    userId,

    status: "ASSIGNED",
    versions: 1,

    assignedAt: timestamp,
    lastUpdate: timestamp,

    // Cache
    exerciseTitle: exercise.title,
    type: exercise.type
  };
}

export function newCourse(name, description, ownerId, timestamp) {
  return {
    name,
    description,
    ownerId: ownerId,
    roles: {
      [ownerId]: "owner"
    },
    createdAt: timestamp,
    lastEditedAt: timestamp,
    groups: [],
    exerciseOrder: {},
    enrolledCounter: 0,
    invitationCounter: 0
  };
}

export function newExercise(title, description, timestamp) {
  return {
    title: title,
    createdAt: timestamp,
    lastEditedAt: timestamp,
    policy: "ON_DEMAND",
    description: description,
    attachments: {}
  };
}

export function newAnnouncement(content, authorName, authorId, courseId, timestamp) {
  return {
    content,
    courseId,
    authorName,
    authorId,
    createdAt: timestamp
  };
}

export function newsLetter(email, reason, timestamp) {
  return {
    email,
    reason,
    timestamp
  };
}
export function contactUs(name, from, subject, message, timestamp) {
  return {
    name,
    from,
    subject,
    message,
    timestamp
  };
}
