import React from "react";
import { useHistory } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

import { withFirebase } from "../../Firebase";

const SignOutButton = ({ firebase }) => {
  const history = useHistory();

  async function logout() {
    await firebase.doSignOut();
    history.push("/");
  }

  return <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>;
};

export default withFirebase(SignOutButton);
