import { ReviewQueue } from "./ReviewQueue";
import React from "react";

import { useAuth } from "../../../Session";
import { useFirebase } from "../../../Firebase";

import AsyncButton from "../../../UI/AsyncButton";
import { Header, Icon, Breadcrumb, Divider } from "semantic-ui-react";
import { ManagePageContainer } from "../../../UI/ManagePageContainer";

export default function WaitingForReviewPage({ course, submittedAssignments }) {
  const firebase = useFirebase();
  const auth = useAuth();

  const breadcrumb = [
    { key: "Home", content: <Icon name="home" /> },
    { key: "upcoming", content: "Waiting for review" }
  ];

  async function downloadToReview() {
    let downloadLatestSubmissionsToReviewOfCourse = firebase.functions.httpsCallable(
      "downloadLatestSubmissionsToReviewOfCourse"
    );

    return downloadLatestSubmissionsToReviewOfCourse({
      courseId: course._id,
      userId: auth.uid,
      title: "All recent Submissions",
      subject: course.name
    });
  }

  return (
    <ManagePageContainer>
      <Header as="h1" icon="file alternate outline" content="Waiting for review" />
      <Breadcrumb icon="right angle" sections={breadcrumb} />
      <Divider />

      <ReviewQueue course={course} submittedAssignments={submittedAssignments} />
      {submittedAssignments && submittedAssignments.length > 0 && (
        <AsyncButton primary onClick={downloadToReview}>
          Download Files
        </AsyncButton>
      )}
    </ManagePageContainer>
  );
}
