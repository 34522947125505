import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import * as Styled from "./index.styles";

import { Container, Segment } from "semantic-ui-react";

export default function Landing() {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Styled.PageHeader>
        <Container>
          <Styled.PageHeader2>
            <Styled.Icon>
              <span className="fad fa-sync" />
            </Styled.Icon>
            <Styled.PageTitle>Pricing</Styled.PageTitle>
          </Styled.PageHeader2>
        </Container>
      </Styled.PageHeader>

      {/* Features */}
      <Styled.Block green>
        <Container>
          <Styled.Columns>
            <Styled.Column>
              <Segment>
                <Styled.Icon>
                  <span className="fal fa-fw fa-chalkboard-teacher" />
                </Styled.Icon>
                <Styled.Header>Instructor</Styled.Header>
                <Styled.Price>Free</Styled.Price>
                <Styled.Button onClick={() => history.push("/signup")}>Sign up now</Styled.Button>
                <Styled.Paragraph>Includes unlimited courses and students</Styled.Paragraph>
                <Styled.Small>
                  <Link to={`/features`}>Full feature list</Link>
                </Styled.Small>
              </Segment>
            </Styled.Column>

            <Styled.Column>
              <Segment style={{ background: "#ffffffbd" }}>
                <Styled.Icon>
                  <span className="fal fa-users-class" />
                </Styled.Icon>
                <Styled.Header>Team</Styled.Header>
                <Styled.Price>Coming Soon</Styled.Price>
                <Styled.Button onClick={() => history.push("/notify")}>Notify Me</Styled.Button>
                <Styled.Paragraph>
                  Includes billing solutions and advanced team permissions (multiple instructors, reviewers, ...)
                </Styled.Paragraph>
              </Segment>
            </Styled.Column>

            <Styled.Column>
              <Segment style={{ background: "#ffffffbd" }}>
                <Styled.Icon>
                  <span className="fal fa-school" />
                </Styled.Icon>
                <Styled.Header>Bootcamps</Styled.Header>
                <Styled.Price>&nbsp;</Styled.Price>
                <Styled.Button onClick={() => history.push("/contact")}>Contact Us</Styled.Button>
                <Styled.Paragraph>
                  Tailor-made solutions like SAML integration, custom domain and style
                </Styled.Paragraph>
              </Segment>
            </Styled.Column>
          </Styled.Columns>
        </Container>
      </Styled.Block>

      {/* <Styled.Divider /> */}
    </>
  );
}
