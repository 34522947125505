import React, { useState } from "react";
import AsyncButton from "../UI/AsyncButton";
import { Button, Header, Icon, Modal, Message, Input, Transition } from "semantic-ui-react";

/*
  icon: Icon
  title: The title of the modal
  message: Message to appear before `expected` data
  expected: The string needs to be copied
  onClick: Async function that 
 */
export default function DeleteModal({ onClick, title, icon, message, expected, trigger }) {
  const [visible, setVisible] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [input, setInput] = useState("");

  const handleChange = (e, { value }) => setInput(value);

  function closeModal() {
    setModalOpen(false);
    setVisible(true);
  }

  async function yes() {
    if (expected !== input) {
      setVisible(!visible);
    } else {
      await onClick();
      closeModal();
    }
  }

  return (
    <Modal
      trigger={React.cloneElement(trigger || <Button>Show Modal</Button>, {
        onClick: () => setModalOpen(true)
      })}
      basic
      size="small"
      closeIcon
      onClose={closeModal}
      onOpen={() => setInput("")}
      open={modalOpen}>
      <Header icon={icon} content={title} />
      <Modal.Content>
        <p>{message}</p>
        <Message color="yellow">
          <Message.Header>{expected}</Message.Header>
        </Message>
        <p>To approve, copy the text and click Yes</p>
        <Transition animation="shake" duration="500" visible={visible}>
          <Input value={input} onChange={handleChange} error={!visible} type="text" fluid inverted autoFocus />
        </Transition>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={closeModal}>
          <Icon name="remove" /> No
        </Button>
        <AsyncButton color="green" inverted onClick={yes}>
          <Icon name="checkmark" /> Yes
        </AsyncButton>
      </Modal.Actions>
    </Modal>
  );
}

export function DeleteModalControlled({ onClick, open, title, icon, message, expected }) {
  const [visible, setVisible] = useState(true);
  const [input, setInput] = useState("");

  const handleChange = (e, { value }) => setInput(value);

  function closeModal() {
    onClick(false);
    setInput("");
    setVisible(true);
  }

  async function yes() {
    if (expected !== input) {
      setVisible(!visible);
    } else {
      await onClick(true);
      closeModal();
    }
  }

  return (
    <Modal basic size="small" closeIcon onClose={closeModal} onOpen={() => setInput("")} open={open}>
      <Header icon={icon} content={title} />
      <Modal.Content>
        <div>{message}</div>
        <Message color="yellow">
          <Message.Header>{expected}</Message.Header>
        </Message>
        <p>To approve, copy the text and click Yes</p>
        <Transition animation="shake" duration="500" visible={visible}>
          <Input value={input} onChange={handleChange} error={!visible} type="text" fluid inverted autoFocus />
        </Transition>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={closeModal}>
          <Icon name="remove" /> No
        </Button>
        <AsyncButton color="green" inverted onClick={yes}>
          <Icon name="checkmark" /> Yes
        </AsyncButton>
      </Modal.Actions>
    </Modal>
  );
}
