import React from "react";

import { useParams, useRouteMatch, Link, Route, Switch } from "react-router-dom";
import { useFirebase } from "../../../Firebase";
import { useFirestoreDoc } from "../../../../utils/firebaseHooks";

import { Header, Grid, Breadcrumb, Divider, Placeholder, Message, Icon } from "semantic-ui-react";
import { ManagePageContainer } from "../../../UI/ManagePageContainer";
import { ExerciseMenu } from "./ExerciseMenu";

import ContentType from "./SubPages/ContentType";
import EditPage from "./SubPages/Edit";
import MessagesPage from "./SubPages/Messages";
import PreferencesPage from "./SubPages/Preferences";
import AssignmentIndex from "./AssignmentIndex";

export default function ExerciseIndex({ course, current }) {
  const params = useParams();
  const firebase = useFirebase();

  // Load Exercise Information
  const [exercise, isLoading] = useFirestoreDoc(firebase.exercise(course._id, params.exercise_id));

  // Breadcrumb
  const breadcrumb = [
    // { key: "Home", content: <Icon name="home" /> },
    { key: "Course Content", content: course.name, as: Link, to: `/manage/${course._id}` },
    { key: "Exercises", content: isLoading ? <Placeholder.Line /> : exercise.title, active: true }
  ];

  return (
    <ManagePageContainer>
      {isLoading ? (
        <Header as="h1" icon="spinner" content="" />
      ) : (
        <Header as="h1" icon="file alternate outline" content={exercise.title} />
      )}
      <Breadcrumb icon="right angle" sections={breadcrumb} />
      <Divider style={{ paddingBottom: "1em" }} />

      {isLoading ? (
        <Grid>
          <Grid.Column width={4}></Grid.Column>
          <Grid.Column width={12}>
            <PageIsLoading />
          </Grid.Column>
        </Grid>
      ) : !exercise.type ? (
        <Grid>
          <Grid.Column width={4}>
            <Message positive>
              <Message.Header>Choose Content Type</Message.Header>
              <p>Which kind of content do you want to create?</p>
            </Message>
          </Grid.Column>
          <Grid.Column width={12}>
            <ContentType course={course} current={current} exercise={exercise} />
          </Grid.Column>
        </Grid>
      ) : (
        <ExerciseRoutes course={course} current={current} exercise={exercise} />
      )}
    </ManagePageContainer>
  );
}

function ExerciseRoutes({ course, current, exercise }) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {/* Edit Page */}
      <Route exact path={`${path}`}>
        <Grid>
          <Grid.Column width={4}>
            <ExerciseMenu activeItem="edit" />
          </Grid.Column>
          <Grid.Column width={12}>
            <EditPage course={course} current={current} exercise={exercise} />
          </Grid.Column>
        </Grid>
      </Route>

      {/* Messages Page */}
      <Route exact path={`${path}/messages`}>
        <Grid>
          <Grid.Column width={4}>
            <ExerciseMenu activeItem="messages" />
          </Grid.Column>
          <Grid.Column width={12}>
            <MessagesPage course={course} current={current} exercise={exercise} />
          </Grid.Column>
        </Grid>
      </Route>

      {/* Assignments Page */}
      <Route path={`${path}/assignments`}>
        <AssignmentIndex course={course} current={current} exercise={exercise} />
      </Route>

      {/* Preferences Page */}
      <Route path={`${path}/preferences`}>
        <Grid>
          <Grid.Column width={4}>
            <ExerciseMenu activeItem="preferences" />
          </Grid.Column>
          <Grid.Column width={12}>
            <PreferencesPage course={course} current={current} exercise={exercise} />
          </Grid.Column>
        </Grid>
      </Route>
    </Switch>
  );
}

function PageIsLoading({}) {
  return (
    <Message icon>
      <Icon name="circle notched" loading />
      <Message.Content>
        <Message.Header>Just one second</Message.Header>
        We are fetching that content for you.
      </Message.Content>
    </Message>
  );
}
