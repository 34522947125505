import React from "react";
import { Link } from "react-router-dom";
import { Table, Icon, Button } from "semantic-ui-react";
import * as csv from "../../../UI/csv";

export default function StudentList({ course, students }) {
  function downloadAsCsv() {
    const list = students.map(student => ({
      name: student.userName,
      email: student.email,
      "enrolled date": student.enrolledAt.toDate()
    }));
    csv.downloadCSV(list, `${course.name}_students.csv`);
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Joined Date</Table.HeaderCell>
          {/* <Table.HeaderCell></Table.HeaderCell> */}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {students.map(student => (
          <Table.Row key={student._id}>
            <Table.Cell>
              <Link to={`/manage/${course._id}/students/${student._id}`}>{student.userName}</Link>
            </Table.Cell>
            <Table.Cell>{student.email}</Table.Cell>
            <Table.Cell>{student.enrolledAt.toDate().toLocaleString("en-US")}</Table.Cell>
            {/* <Table.Cell textAlign="right" collapsing style={{ paddingTop: 0, paddingBottom: 0 }}></Table.Cell> */}
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer fullWidth>
        <Table.Row>
          <Table.HeaderCell>{students.length} Students</Table.HeaderCell>
          <Table.HeaderCell colSpan="2">
            <Button
              compact
              basic
              floated="right"
              icon
              labelPosition="left"
              primary
              size="small"
              onClick={downloadAsCsv}>
              <Icon name="download" /> Download as CSV
            </Button>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
}
