import React, { useState, useEffect } from "react";
import _ from "lodash";

import { useFirebase } from "../../../Firebase";

import { Card, Button, Header, Divider } from "semantic-ui-react";
import AsyncButton from "../../../UI/AsyncButton";

export function InvitationList({ user }) {
  const firebase = useFirebase();
  const [isLoading, setLoading] = useState(true);
  const [invitations, setInvitations] = useState([]);

  useEffect(() => {
    if (user === null) {
      return;
    }
    const unsubscribe = firebase.invitedCourses(user.email).onSnapshot(snapshot => {
      let result = [];
      snapshot.forEach(doc => {
        result.push({
          ...doc.data(),
          id: doc.id,
          ref: doc.ref
        });
      });
      setInvitations(result);
      setLoading(false);
    });

    return unsubscribe;
  }, [user]);

  const handleInvite = (inviteId, action) => {
    let acceptInvitation = firebase.functions.httpsCallable("acceptInvitation");

    if (action === "delete") {
      return firebase.invitation(inviteId).delete();
    } else if (action === "accept") {
      return acceptInvitation({ inviteId });
    }
  };

  if (isLoading || _.isEmpty(invitations)) {
    return <></>;
  }

  return (
    <>
      <Header as="h2" color="blue">
        Pending Invitations
      </Header>
      <Card.Group>
        {invitations.map(invite => (
          <Card key={invite.id}>
            <Card.Content header={invite.courseName} />
            {/* <Card.Content description="No course description provided" /> */}
            <Card.Content extra>
              <Button.Group fluid size="tiny">
                <AsyncButton
                  basic
                  color="green"
                  onClick={() => {
                    return handleInvite(invite.id, "accept");
                  }}>
                  Approve
                </AsyncButton>
                <AsyncButton
                  basic
                  color="red"
                  onClick={() => {
                    return handleInvite(invite.id, "delete");
                  }}>
                  Decline
                </AsyncButton>
              </Button.Group>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
      <Divider />
    </>
  );
}
