import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import useIsMounted from "../../utils/isMounted";

function withAsyncButton(Component) {
  return function WihLoadingComponent({ onClick, ...props }) {
    const [loading, setLoading] = useState(false);
    const isMounted = useIsMounted();

    if (onClick) {
      const newOnClick = async (event, data) => {
        setLoading(true);
        await onClick(event, data);
        if (isMounted()) {
          setLoading(false);
        }
      };

      if (loading) {
        return <Component loading {...props} />;
      }

      return <Component onClick={newOnClick} {...props} />;
    }

    return <Component {...props} />;
  };
}

export default withAsyncButton(Button);
