import React from "react";
import TimeAgo from "react-timeago";
import { ASSIGNMENT_ICON } from "../../../constants/icons";

import { Segment, Header, Icon } from "semantic-ui-react";
import AttachedFiles from "../AttachedFiles";

import "./Submission.css";

export function Submission({ submission }) {
  return (
    <Segment.Group>
      {/* Header */}
      <Segment className="submission-header" inverted color={ASSIGNMENT_ICON[submission.state].color}>
        <div>
          <Icon name={ASSIGNMENT_ICON[submission.state].icon} />
          <b>{ASSIGNMENT_ICON[submission.state].title}</b>
        </div>
        <div>{submission.submittedAt && <TimeAgo date={submission.submittedAt.toDate()} />}</div>
      </Segment>

      {/* Subheader */}
      <Segment className="submission-subheader" secondary color={ASSIGNMENT_ICON[submission.state].color}>
        <small>
          <b>VERSION</b> <span>{submission.version}</span> &nbsp;
          {submission.submittedAt && (
            <>
              |&nbsp; <b>SUBMITTED ON</b> <span>{submission.submittedAt.toDate().toLocaleString("en-US")}</span>
            </>
          )}
        </small>
      </Segment>

      {/* Submission Body */}
      {submission.attachments && (
        <Segment>
          <Header size="small">Attached Files</Header>
          <AttachedFiles attachments={submission.attachments} />
        </Segment>
      )}

      {/* Review Data */}
      {submission.reviewData && (
        <Segment tertiary color={ASSIGNMENT_ICON[submission.state].color}>
          <p>{submission.reviewData.comments}</p>
          <AttachedFiles attachments={submission.reviewData.attachments} />
          <small className="text-muted">
            {submission.reviewData.reviewerUserName} | {formatDate(submission.reviewEndTime)}
          </small>
        </Segment>
      )}
    </Segment.Group>
  );
}

function formatDate(timestamp) {
  if (!timestamp) {
    return <></>;
  }
  const d = timestamp.toDate();
  // console.log(d);
  return <TimeAgo date={d} />;
}
