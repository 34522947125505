import styled from "styled-components";

export const MatrixTable = styled.table`
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
`;

export const MatrixContainer = styled.div`
  /* border-bottom: 1px #0623338a solid; */
  /* border-top: 1px #0623338a solid; */
  /* margin: 1rem; */
  overflow: auto;
`;

export const NameTd = styled.td`
  /* border: 1px #a9a9a9 solid; */
  padding-left: 0.5rem;
  padding-right: 1rem;
  border-top: 1px #96c1da solid;
  white-space: nowrap;
`;

// 96c1da

export const ExTd = styled.td`
  /* border-top: 1px #a9a9a9 solid; */

  text-align: center;
  width: 2rem;
  line-height: 1;

  max-width: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  border-top: 1px #96c1da solid;
  font-weight: 600;
  font-size: 0.9rem;
  padding: 6px;

  &:hover {
    color: red;
  }
`;

export const ThRotated = styled.th`
  height: 160px;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  position: relative;
  vertical-align: bottom;
  padding: 0;
  font-size: 12px;
  line-height: 0.8;
  font-weight: normal;

  &:last-child > div {
    border-right: 0;
  }

  & > div {
    background: linear-gradient(to top, rgba(195, 232, 253, 0), #ffffff 78%);
    height: 170px;
    border-bottom: 0;
    border-left: 0;
    transform: skew(-40deg) translateX(178%);
    /* border-right: 1px #96c1da solid; */
  }

  & > div > span {
    position: absolute;
    width: 220px;
    height: 85px;
    bottom: -43%;
    left: 10px;
    transform: skew(40deg) rotate(-50deg);
    transform-origin: 0 0;
    text-align: left;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 12px;
  }
`;
