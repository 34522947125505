import React from "react";
import styled from "styled-components";

import { Container, Segment, Header } from "semantic-ui-react";

export default function Error404Page() {
  return (
    <Container style={{ marginTop: "12em" }}>
      <Layout>
        <i className="fad fa-exclamation-circle fa-6x" />
        <div>
          <h3>Page not found</h3>
          <br />
          <p>
            Whoops! It looks like you've reached a non-existing page
            <br />
            <br />
            Try again later
          </p>
        </div>
      </Layout>
    </Container>
  );
}

const Layout = styled.div`
  text-align: center;
  & div {
    margin-top: 1rem;
  }
`;
