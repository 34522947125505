import React from "react";

import { useFirebase } from "../Firebase";
import { keyBy } from "lodash";
import { Link } from "react-router-dom";
import { Icon, Table, Label } from "semantic-ui-react";

import { ASSIGNMENT_ICON } from "../../constants/icons";
import { EXERCISE_TYPE } from "../../constants/icons";

import * as DataModel from "../../services/dataModel";
import HoldButton from "./HoldButton";

function isNew(timestamp) {
  if (!timestamp) {
    return false;
  }
  const now = new Date();
  const delta = now - timestamp.toDate();

  if (delta < 1000 * 60 * 60 * 24 * 5) {
    return true;
  }

  return false;
}

function presentAssignments(course, exercises, assignments) {
  const exercisesById = keyBy(exercises, "_id");
  const assignmentsById = keyBy(assignments, "exerciseId");

  return [exercisesById, assignmentsById];
}

export default function AdminAssignmentList({ course, userId, exercises, assignments }) {
  const firebase = useFirebase();

  const [exercisesById, assignmentsById] = presentAssignments(course, exercises, assignments);

  async function assignExercise(exercise) {
    const data = DataModel.newAssignment(course._id, userId, exercise, firebase.fieldValue.serverTimestamp());
    await firebase.assignment(userId, exercise._id).set(data);
  }

  return course.groups.map(groupName => (
    <GroupAssignments
      key={groupName}
      name={groupName}
      exercisesOrder={course.exerciseOrder[groupName]}
      exercises={exercisesById}
      assignments={assignmentsById}
      assignExercise={assignExercise}
    />
  ));

  // return Object.keys(groupedAssignments).map(groupName => (
  //   <GroupAssignments key={groupName} name={groupName} assignments={groupedAssignments[groupName]} />
  // ));
}

function GroupAssignments({ name, assignExercise, exercisesOrder, exercises, assignments }) {
  function isAssigned(id) {
    return id in assignments;
  }

  function assignmentStatus(id) {
    return id in assignments ? assignments[id].status : undefined;
  }

  function assignmentDate(id) {
    return id in assignments ? assignments[id].assignedAt : undefined;
  }

  function exercisePath(id) {
    if (!(id in assignments)) return undefined;
    return `/manage/${assignments[id].courseId}/${id}/assignments/${assignments[id].userId}`;
  }

  return (
    <Table unstackable selectable singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={3}>{name}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {exercisesOrder.map((id, index) => (
          <Table.Row key={id}>
            {/* Status Icon */}
            <Table.Cell collapsing style={{ backgroundColor: "#f9fafb" }}>
              <Icon
                name={ASSIGNMENT_ICON[assignmentStatus(id)].icon}
                color={ASSIGNMENT_ICON[assignmentStatus(id)].color}
              />
            </Table.Cell>
            {/* Exercise Name */}
            <Table.Cell>
              <Icon name={EXERCISE_TYPE[exercises[id].type].icon} />{" "}
              <Link to={() => exercisePath(id)}>{exercises[id].title}</Link>
            </Table.Cell>
            {/* Status / Ribbon */}
            {isAssigned(id) ? (
              <Table.Cell collapsing textAlign="right">
                {ASSIGNMENT_ICON[assignmentStatus(id)].title}
              </Table.Cell>
            ) : (
              <Table.Cell collapsing textAlign="right" style={{ padding: 0 }}>
                <HoldButton size="small" color="green" onClick={() => assignExercise(exercises[id])}>
                  <Icon name="plus circle" /> Assign
                </HoldButton>
              </Table.Cell>
            )}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
