import React, { useState } from "react";
import NewItemForm from "./NewItemForm";
import { RenameForm } from "./NewGroupForm";

import { isEmpty } from "lodash";
import { ContentEntry } from "./ContentEntry";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Header, Ref, Segment, Icon, Popup } from "semantic-ui-react";
import Flex, { FlexItem } from "styled-flex-component";
import AsyncIconButton from "../../../UI/AsyncIconButton";
import { DeleteModalControlled } from "../../../Common/DeleteModal";

export const ContentList = React.memo(
  ({
    groups,
    exerciseOrder,
    groupedExercise,
    current,
    exerciseStatistics,
    onAddNewContent,
    onOrderChanged,
    onGroupsUpdated
  }) => {
    const [toDelete, setToDelete] = useState(null);

    async function onDragEnd(result) {
      const { destination, source, draggableId } = result;

      // Cancelled
      if (!destination) {
        return;
      }

      // If user dropped on the same place
      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return;
      }

      // Single Column update
      const sourceGroup = Array.from(exerciseOrder[source.droppableId]);
      const destGroup =
        source.droppableId === destination.droppableId
          ? sourceGroup
          : Array.from(exerciseOrder[destination.droppableId] || []);

      sourceGroup.splice(source.index, 1); // Delete from source
      destGroup.splice(destination.index, 0, draggableId); // Add in new location

      await onOrderChanged({
        ...exerciseOrder,
        [source.droppableId]: sourceGroup,
        [destination.droppableId]: destGroup
      });
    }

    function handleDelete(group, exercise) {
      setToDelete([group, exercise]);
    }

    async function handleDeleteApproved(status) {
      if (status) {
        const [group, exercise] = toDelete;
        const newOrder = Array.from(exerciseOrder[group]).filter(exerciseId => exerciseId !== exercise._id);
        await onOrderChanged({
          ...exerciseOrder,
          [group]: newOrder
        });
        await exercise._ref.delete();
        setToDelete(null);
      } else {
        setToDelete(null);
      }
    }

    function getOrderFor(groupName) {
      if (isEmpty(groupedExercise[groupName])) {
        return [];
      }
      return groupedExercise[groupName];
    }

    async function moveGroupUp(index) {
      if (index > 0) {
        let newGroups = Array.from(groups);
        let temp = newGroups[index - 1];
        newGroups[index - 1] = newGroups[index];
        newGroups[index] = temp;
        return onGroupsUpdated(newGroups);
      }
    }

    async function moveGroupDown(index) {
      if (index + 1 < groups.length) {
        let newGroups = Array.from(groups);
        let temp = groups[index];
        newGroups[index] = newGroups[index + 1];
        newGroups[index + 1] = temp;
        return onGroupsUpdated(newGroups);
      }
    }

    async function deleteGroup(index) {
      // Make sure group is empty
      if (getOrderFor(groups[index]).length !== 0) {
        return;
      }

      let newGroups = Array.from(groups);
      newGroups.splice(index, 1);
      onGroupsUpdated(newGroups);

      // Delete empty group
      if (groups[index] in exerciseOrder) {
        let newExerciseOrder = { ...exerciseOrder };
        delete newExerciseOrder[groups[index]];
        return onOrderChanged(newExerciseOrder);
      }
    }

    async function renameGroup(index, newName) {
      let newExerciseOrder = {
        ...exerciseOrder,
        [newName]: exerciseOrder[groups[index]] || []
      };
      delete newExerciseOrder[groups[index]];

      await onOrderChanged(newExerciseOrder);

      let newGroups = Array.from(groups);
      newGroups[index] = newName;
      return onGroupsUpdated(newGroups);
    }

    // For Each GROUP - render it's entry
    const list = groups.map((group, index) => (
      <div key={group}>
        <Droppable droppableId={group}>
          {provided => (
            <Ref innerRef={provided.innerRef}>
              <Segment.Group compact className="course-content-list" {...provided.droppableProps}>
                <GroupHeader
                  index={index}
                  group={group}
                  isEmpty={getOrderFor(group).length === 0}
                  renameGroup={renameGroup}
                  moveGroupUp={moveGroupUp}
                  moveGroupDown={moveGroupDown}
                  deleteGroup={deleteGroup}
                />
                <GroupContent
                  current={current}
                  entries={getOrderFor(group)}
                  onExerciseDelete={entry => handleDelete(group, entry)}
                  exerciseStatistics={exerciseStatistics}
                />
                {provided.placeholder}
                <NewItemForm onSave={name => onAddNewContent(group, name)} />
              </Segment.Group>
            </Ref>
          )}
        </Droppable>
      </div>
    ));

    return (
      <React.Fragment>
        <DeleteModalControlled
          title="Delete Exercise"
          message="Deleting the exericse is PERMANENTLY and will delete all student assignments"
          expected={toDelete && toDelete[1].title}
          icon="trash alternate"
          open={!!toDelete}
          onClick={handleDeleteApproved}
        />
        {/* <Confirm open={true} content="Are you sure you want to delete the exercise?" /> */}
        <DragDropContext onDragEnd={onDragEnd}>{list}</DragDropContext>
      </React.Fragment>
    );
  }
);

function GroupHeader({ group, index, isEmpty, renameGroup, deleteGroup, moveGroupUp, moveGroupDown }) {
  const [editMode, setEditMode] = useState(false);

  function handleSubmit(newName) {
    renameGroup(index, newName);
    setEditMode(false);
  }

  return (
    <Segment secondary>
      {editMode ? (
        <RenameForm initialValue={group} onCancel={() => setEditMode(false)} onSubmit={handleSubmit} />
      ) : (
        <Flex full alignCenter>
          <FlexItem grow="1">
            <b>{group}</b>
          </FlexItem>
          <FlexItem>
            {!isEmpty ? (
              <Popup
                trigger={<Icon name="trash alternate" />}
                content="Group is not empty"
                position="top center"
                size="small"
                inverted
              />
            ) : (
              <AsyncIconButton name="trash alternate" onClick={() => deleteGroup(index)} />
            )}
            &nbsp;&nbsp;
            <AsyncIconButton name="pencil" onClick={() => setEditMode(true)} />
            &nbsp; | &nbsp;
            <AsyncIconButton name="arrow circle up" onClick={() => moveGroupUp(index)} />
            <AsyncIconButton name="arrow circle down" onClick={() => moveGroupDown(index)} />
          </FlexItem>
        </Flex>
      )}
    </Segment>
  );
}

const GroupContent = React.memo(({ entries, exerciseStatistics, onExerciseDelete }) => {
  if (isEmpty(entries)) {
    return <></>;
  }

  return entries.map((entry, index) => {
    if (!isEmpty(entry)) {
      return (
        <ContentEntry
          key={entry._id}
          entry={entry}
          index={index}
          onDelete={onExerciseDelete}
          stats={exerciseStatistics ? exerciseStatistics[entry._id] : null}
        />
      );
    }
  });
});
