import React, { Component } from "react";
import { Header } from "semantic-ui-react";

import ReactMarkdown from "react-markdown";
import AttachedFiles from "./AttachedFiles";

export class DisplayExercise extends Component {
  render() {
    const { exercise } = this.props;

    const attachmentPresent = exercise.attachments && Object.keys(exercise.attachments).length > 0;
    const descriptionPresent = exercise.description;

    return (
      <React.Fragment>
        {descriptionPresent || attachmentPresent ? (
          <>
            {descriptionPresent && (
              <div style={{ whiteSpace: "pre" }}>
                <ReactMarkdown source={exercise.description} />
              </div>
            )}
            {attachmentPresent && (
              <>
                <Header as="h4">Attached Files</Header>
                <AttachedFiles attachments={exercise.attachments} />
              </>
            )}
          </>
        ) : (
          <div>Exercise has no description</div>
        )}
      </React.Fragment>
    );
  }
}
