import React, { useEffect, useState } from "react";
import * as Styled from "./index.styles";

import { useFirebase } from "../../Firebase";
import * as dataModel from "../../../services/dataModel";

import { Container, Segment, Form, Button } from "semantic-ui-react";

export default function ContactPage() {
  const firebase = useFirebase();

  const [name, setName] = useState("");
  const [from, setFrom] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    await firebase
      .contactus()
      .add(dataModel.contactUs(name, from, subject, message, firebase.fieldValue.serverTimestamp()));
    setLoading(false);
    setSuccess(true);
  }

  return (
    <>
      <Styled.PageHeader>
        <Container>
          <Styled.PageHeader2>
            <Styled.Icon>
              <span className="fad fa-paper-plane" />
            </Styled.Icon>
            <Styled.PageTitle>Contact Us</Styled.PageTitle>
          </Styled.PageHeader2>
        </Container>
      </Styled.PageHeader>

      {/* Features */}
      <Styled.Block>
        <Container style={{ width: "700px" }}>
          <Segment padded>
            {success ? (
              <Styled.Thanks style={{ border: "0" }}>
                <span className="fad fa-heart fa-3x" style={{ color: "#e6b112", marginBottom: "1rem" }} />
                <p>Thanks for contacting us</p>
                <p>We have received your email and reply to you soon!</p>
              </Styled.Thanks>
            ) : (
              <Form onSubmit={handleSubmit}>
                <Form.Field>
                  <label>Name</label>
                  <input placeholder="First Name" required value={name} onChange={e => setName(e.target.value)} />
                </Form.Field>
                <Form.Field>
                  <label>Email</label>
                  <input
                    placeholder="Email address"
                    type="email"
                    required
                    value={from}
                    onChange={e => setFrom(e.target.value)}
                  />
                </Form.Field>

                <Form.Field>
                  <label>Subject</label>
                  <input placeholder="" value={subject} onChange={e => setSubject(e.target.value)} />
                </Form.Field>
                <Form.Field>
                  <label>Message</label>
                  <textarea value={message} onChange={e => setMessage(e.target.value)} />
                </Form.Field>
                <Button secondary disabled={loading} type="submit">
                  Send
                </Button>
              </Form>
            )}
          </Segment>
        </Container>
      </Styled.Block>

      {/* <Styled.Divider /> */}
    </>
  );
}
