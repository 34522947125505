import React from "react";
import Sidebar from "./Sidebar";

import styled from "styled-components";

import { useParams, Redirect } from "react-router-dom";
import { useFirebase } from "../../../Firebase";
import { useAuth } from "../../../Session";
import { useFirestoreDoc, useFirestoreQuery, useRealtimeData } from "../../../../utils/firebaseHooks";

import RouteSelector from "./RouteSelector";

const PageContainer = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  min-height: calc(100%);
  padding-top: 4em;
`;

const PageContent = styled.div`
  flex: 1;
  padding: 3em 2em;
`;

export default function CourseIndex() {
  const firebase = useFirebase();
  const auth = useAuth();
  const params = useParams();
  const courseId = params.course_id;

  // Load Course Information
  const [course, isLoading, error] = useFirestoreDoc(firebase.course(courseId));
  const current = useRealtimeData(firebase.matrix(courseId));

  // Course Extra Information
  const [submittedAssignments, isLoadingAssignment] = useFirestoreQuery(
    firebase.assignmentsOfCourseCollection(courseId).where("status", "in", ["SUBMITTED", "IN_REVIEW"])
  );

  // Wait for loading
  if (isLoading) {
    return <></>;
  }

  // Assert Permissions
  if (!hasPermissions(auth, course) || error) {
    return <Redirect to="/user/courses" />;
  }

  return (
    <React.Fragment>
      <PageContainer>
        <Sidebar course={course} submittedAssignments={submittedAssignments} />
        <PageContent>
          <RouteSelector course={course} current={current} submittedAssignments={submittedAssignments} />
        </PageContent>
      </PageContainer>
    </React.Fragment>
  );
}

function hasPermissions(auth, course) {
  if (null == auth.claims) {
    return true; // until claims available
  }

  if (!auth.claims.owner) {
    return false;
  }

  if (-1 !== auth.claims.owner.indexOf(course._id)) {
    return true;
  }

  return false;
}
