export const LANDING = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const ACCOUNT = "/account";
export const PASSWORD_FORGET = "/pw-forget";
export const ADMIN = "/admin";
export const ADMIN_DETAILS = "/admin/:id";
export const FEATURES = "/features";
export const PRICING = "/pricing";
export const CONTACT = "/contact";
export const NOTIFY = "/notify";
export const PRIVACY = "/privacy";
export const TERMS = "/terms";

export const MESSAGES = "/user/messages";
export const COURSES = "/user/courses";
export const COURSE_DASHBOARD = "/user/courses/:course_id";
export const COURSE_ASSIGNMENT = "/user/courses/:course_id/:exercise_id";

export const MANAGE = "/user/courses";
export const MANAGE_NEW = "/manage/new";

export const MANAGE_EDIT = "/manage/:course_id/edit";
export const MANAGE_DASHBOARD = "/manage/:course_id";
export const MANAGE_EXERCISE = "/manage/:course_id/:exercise_id";
export const MANAGE_EXERCISE_ASSIGNMENTS = "/manage/:course_id/:exercise_id/assignments";
export const MANAGE_EXERCISE_ASSIGNMENT = "/manage/:course_id/:exercise_id/assignments/:user_id";
export const MANAGE_EXERCISE_MESSAGES = "/manage/:course_id/:exercise_id/messages";

export const MANAGE_STUDENT_LIST = "/manage/:course_id/students";
export const MANAGE_INVITE = "/manage/:course_id/invite";
export const MANAGE_UPCOMING = "/manage/:course_id/upcoming";

export const MANAGE_STUDENT = "/manage/:course_id/students/:user_id";
export const MANAGE_STUDENT_MESSAGES = "/manage/:course_id/students/:user_id/messages";

export const MANAGE_MESSAGES = "/manage/:course_id/messages";

export const MANAGE_MATRIX = "/manage/:course_id/matrix";
export const MANAGE_ANNOUNCEMENTS = "/manage/:course_id/announcements";
