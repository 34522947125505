import React, { useState } from "react";
import { Icon, Button, Table, Form, Input } from "semantic-ui-react";

export default function NewGroupForm({ onSave }) {
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState("");
  const handleChange = (e, { value }) => setName(value);

  function onSubmit() {
    onSave(name);
    setName("");
    setEditMode(false);
  }

  return (
    <Table>
      <Table.Body>
        <Table.Row className="add_content_row" onClick={() => editMode === false && setEditMode(true)}>
          <Table.Cell textAlign="center" collapsing>
            <Icon name="add" fitted color="grey" />
          </Table.Cell>
          <Table.Cell colSpan="3" style={{ padding: 0 }}>
            {editMode ? (
              <Form onSubmit={onSubmit}>
                <Input value={name} onChange={handleChange} fluid action>
                  <input name="name" placeholder="Group name" autoFocus />
                  <Button
                    type="button"
                    secondary
                    content="Cancel"
                    icon="times"
                    onClick={() => {
                      setEditMode(false);
                      setName("");
                    }}
                  />
                  <Button primary content="Save" icon="check" type="submit" />
                </Input>
              </Form>
            ) : (
              "Add Group"
            )}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

export function RenameForm({ initialValue, onCancel, onSubmit }) {
  const [name, setName] = useState(initialValue);

  const handleChange = (e, { value }) => setName(value);

  function handleSubmit() {
    onSubmit(name);
    setName("");
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Input value={name} onChange={handleChange} fluid action>
        <input name="name" placeholder="Group name" autoFocus />
        <Button
          type="button"
          secondary
          content="Cancel"
          icon="times"
          onClick={() => {
            onCancel();
            setName("");
          }}
        />
        <Button primary content="Save" icon="check" type="submit" />
      </Input>
    </Form>
  );
}
