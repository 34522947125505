import React, { useState } from "react";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";

import { Icon, Button, Segment } from "semantic-ui-react";
import AsyncButton from "../UI/AsyncButton";
import Textarea from "react-textarea-autosize";

export default function AdminMessage({
  message,
  showTitle = true,
  editMode,
  authorName,
  onReply,
  onReview,
  onResolve,
  onCancel,
  onSubmit
}) {
  const [response, setResponse] = useState("");
  const color = message.isReviewed ? "black" : message.isResolved ? "green" : "orange";

  function renderButtons() {
    return message.isReviewed ? (
      editMode ? (
        <Button.Group widths={3} size="small" attached="bottom">
          <AsyncButton negative onClick={onCancel}>
            Cancel
          </AsyncButton>
          <AsyncButton primary disabled></AsyncButton>
          <AsyncButton onClick={() => onSubmit(response)} color="green">
            <Icon name="send" /> Send and Resolve
          </AsyncButton>
        </Button.Group>
      ) : (
        <Button.Group widths={3} size="small" attached="bottom">
          <AsyncButton negative onClick={onCancel}>
            Cancel
          </AsyncButton>
          <AsyncButton primary onClick={onReply}>
            Reply and Resolve
          </AsyncButton>
          <AsyncButton positive onClick={onResolve}>
            <Icon name="check" /> Resolve
          </AsyncButton>
        </Button.Group>
      )
    ) : (
      <Button.Group widths={3} size="small" attached="bottom">
        <AsyncButton onClick={() => onReview(true)}>Review and Aprroach</AsyncButton>
        <AsyncButton onClick={onReply}>Review and Reply</AsyncButton>
        <AsyncButton positive onClick={onResolve}>
          <Icon name="check" /> Resolve
        </AsyncButton>
      </Button.Group>
    );
  }

  return (
    <>
      {showTitle && (
        <Segment
          tertiary
          inverted={!!message.isReviewed}
          color={color}
          attached="top"
          style={{ paddingTop: "0.85em", paddingBottom: "0.85em" }}>
          <Icon name="envelope outline" />
          <Link to={`/manage/${message.courseId}/${message.exerciseId}`} style={{ color: "inherit" }}>
            <b>{message.exerciseTitle}</b>
          </Link>
        </Segment>
      )}
      <Segment
        inverted={!!message.isReviewed}
        secondary={!message.isReviewed}
        tertiary={!!message.isReviewed}
        color={showTitle ? undefined : color}
        attached={showTitle ? true : "top"}>
        <p style={{ whiteSpace: "pre" }}>{message.content}</p>
        <small>
          <b>
            <Icon name="user" /> {message.displayName}
          </b>{" "}
          | {message.timestamp && <TimeAgo date={message.timestamp.toDate()} minPeriod={5} />}
        </small>
      </Segment>

      {message.isResolved && (
        <Segment attached>
          {message.response ? (
            <p style={{ whiteSpace: "pre" }}>{message.response}</p>
          ) : (
            <p>
              <small style={{ opacity: "0.5" }}>Resolved without written response</small>
            </p>
          )}
          <small>
            <b>
              <Icon name="user" /> {message.responseAuthor} |{" "}
              {message.responseTimestamp && <TimeAgo date={message.responseTimestamp.toDate()} minPeriod={5} />}
            </b>
          </small>
        </Segment>
      )}

      {editMode && (
        <Segment attached>
          <Textarea
            placeholder="Write response here"
            minRows={1}
            style={{
              width: "100%",
              marginBottom: "1rem"
            }}
            className="clear-textarea"
            autoFocus
            value={response}
            onChange={event => setResponse(event.target.value)}
          />
          <small>
            <b>
              <Icon name="user" /> {authorName}
            </b>
          </small>
        </Segment>
      )}

      {!message.isResolved && renderButtons()}
    </>
  );
}
