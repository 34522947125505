import React, { Component } from "react";

import { withFirebase } from "../../Firebase";
import { Form, Message } from "semantic-ui-react";

const INITIAL_STATE = {
  passwordOne: "",
  passwordTwo: "",
  error: null,
  message: null
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { passwordOne } = this.state;

    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE, message: "Password updated successfully" });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { passwordOne, passwordTwo, error, message } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === "";

    return (
      <Form onSubmit={this.onSubmit}>
        <Form.Field>
          <label>New password</label>
          <input
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
            type="password"
            placeholder="New Password"
            className="form-control mb-3"
          />
        </Form.Field>

        <Form.Field>
          <label>Confirm new password</label>
          <input
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            type="password"
            placeholder="Confirm New Password"
            className="form-control mb-3"
          />
        </Form.Field>

        <Form.Button disabled={isInvalid}>Update password</Form.Button>

        {message && <Message positive content={message} />}
        {error && <Message negative content={error.message} />}
      </Form>
    );
  }
}

export default withFirebase(PasswordChangeForm);
