import React, { useEffect, useState } from "react";
import * as Styled from "./index.styles";
import { useFirebase } from "../../Firebase";
import * as dataModel from "../../../services/dataModel";

import { Container, Segment } from "semantic-ui-react";

export default function NotifyPage() {
  const firebase = useFirebase();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    try {
      await firebase
        .newsletter()
        .add(dataModel.newsLetter(email, "teams-notify", firebase.fieldValue.serverTimestamp()));
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  }

  return (
    <>
      <Styled.PageHeader>
        <Container>
          <Styled.PageHeader2>
            <Styled.Icon>
              <span className="fad fa-alarm-clock fa-fw" />
            </Styled.Icon>
            <Styled.PageTitle>Notify Me</Styled.PageTitle>
          </Styled.PageHeader2>
        </Container>
      </Styled.PageHeader>

      {/* Features */}
      <Styled.Block green style={{ paddingBottom: "10rem" }}>
        <Container style={{ width: "800px" }}>
          <Segment style={{ background: "#ffffffbd" }} padded>
            <Styled.Header>
              <span className="fal fa-users-class" /> Synclass Teams notifications
            </Styled.Header>

            {success ? (
              <Styled.Thanks>
                <span className="fad fa-heart fa-3x" style={{ color: "#e6b112", marginBottom: "1rem" }} />
                <p>Thanks for interesting in Synclass Teams</p>
                <p>We can't wait to have you onboard with us</p>
              </Styled.Thanks>
            ) : (
              <>
                <Styled.Paragraph>
                  Fill the form to enter our beta waiting list and join Synclass Teams newsletter.
                </Styled.Paragraph>
                <Styled.Form onSubmit={handleSubmit}>
                  <Styled.Input
                    type="email"
                    placeholder="Enter your email address"
                    autoFocus
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                  />
                  <Styled.Button disabled={loading} type="submit">
                    Notify Me
                  </Styled.Button>
                </Styled.Form>
                {error && (
                  <Styled.Error>
                    Whoops! We are sorry but it looks like we had an error :( <br />
                    Please try again later
                  </Styled.Error>
                )}
                <Styled.Paragraph style={{ textAlign: "center" }}>
                  By submitting form, you agree to recieve promotional emails
                </Styled.Paragraph>
              </>
            )}
          </Segment>
        </Container>
      </Styled.Block>

      {/* <Styled.Divider /> */}
    </>
  );
}
