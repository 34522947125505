import React from "react";

import { Icon } from "semantic-ui-react";

export function Logo() {
  return (
    <div>
      <Icon name="sync alternate" />
      <span>SYN</span>
      <span>CLASS</span>
    </div>
  );
}

export function ColoredLogo() {
  return (
    <div className="logo">
      <Icon name="sync alternate" />
      <span>SYN</span>
      <span>CLASS</span>
    </div>
  );
}

export function SmallLogo() {
  return (
    <div style={{ fontSize: "0.9em" }}>
      <Icon name="sync alternate" />
      <span>SYN</span>
      <span>CLASS</span>
    </div>
  );
}
