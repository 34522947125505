import React from "react";

import { Header, Icon, Table, Segment, Button } from "semantic-ui-react";
import AsyncButton from "../../../UI/AsyncButton";

import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";

export class InviteForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emails: []
    };

    this.handleClicked = this.handleClicked.bind(this);
  }

  async handleClicked(event) {
    await this.props.onClick(this.state.emails);
    this.setState({ emails: [] });
  }

  render() {
    const { emails } = this.state;
    const { invitations } = this.props;

    let pendingList = null;
    if (invitations !== undefined && invitations.length > 0) {
      pendingList = (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {invitations.map(invite => (
              <Table.Row key={invite._id}>
                <Table.Cell>{invite.email}</Table.Cell>
                <Table.Cell>{invite.timestamp.toDate().toLocaleString("en-US")}</Table.Cell>
                <Table.Cell textAlign="right" style={{ padding: "0 1rem" }}>
                  <AsyncButton basic size="small" compact onClick={async () => invite._ref.delete()}>
                    <Icon name="trash alternate" /> Delete
                  </AsyncButton>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    }

    return (
      <>
        <Segment raised>
          <Header size="medium" color="purple">
            Invite using email
          </Header>
          <ReactMultiEmail
            placeholder="email@address.com"
            emails={emails}
            onChange={_emails => {
              this.setState({ emails: _emails });
            }}
            validateEmail={email => {
              return isEmail(email); // return boolean
            }}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  {email}
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              );
            }}
          />
          <AsyncButton style={{ marginTop: "1rem" }} color="purple" onClick={this.handleClicked}>
            <Icon name="send" /> Send invites
          </AsyncButton>
        </Segment>

        {pendingList && (
          <>
            <Header size="medium" color="purple">
              Pending Invitations
            </Header>

            {pendingList}
          </>
        )}
      </>
    );
  }
}
