import React from "react";
import isEmpty from "lodash/isEmpty";

import { useHistory, Link } from "react-router-dom";
import { useFirestoreQuery } from "../../../../utils/firebaseHooks";
import { useAuth } from "../../../Session";
import { useFirebase } from "../../../Firebase";

import * as Styled from "./index.styles";
import { Header, Breadcrumb, Divider, Icon, Grid, Segment, Message } from "semantic-ui-react";
import { CourseList } from "./CourseList";
import { InvitationList } from "./InvitationList";
import { RecentActivity } from "../../../Common/RecentActivity";
import AdminCourseList from "./AdminCourseList";
import Form from "../../../UI/Form";

export default function MyCoursesPage() {
  const user = useAuth();
  const firebase = useFirebase();
  user._id = user.uid;

  // Load My Managed Course
  const [manageCourses, isLoading] = useFirestoreQuery(
    firebase
      .courses()
      .where("ownerId", "==", user.uid)
      .where("roles." + user.uid, "==", "owner")
  );

  const haveEnrolledCourses = user.enrolled && !isEmpty(user.enrolled);
  const haveManageCourses = !isLoading && manageCourses && manageCourses.length > 0;

  return (
    <>
      <Header as="h1">
        <Icon name="book" />
        <Header.Content>My Courses</Header.Content>
      </Header>
      <Breadcrumb>
        <Breadcrumb.Section>
          <Icon name="home" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section active>My Courses</Breadcrumb.Section>
      </Breadcrumb>
      <Divider
        style={{
          paddingBottom: "1rem"
        }}
      />

      {!haveEnrolledCourses && !haveManageCourses ? (
        isLoading ? (
          <></>
        ) : (
          <>
          <Banner />
          <InvitationList user={user} />
          </>
        )
      ) : (
        <>
          <Banner muted />
          <Grid columns="2">
            <Grid.Row>
              <Grid.Column width="11">
                <InvitationList user={user} />

                {/* {haveEnrolledCourses && <Styled.SemiHeader>Enrolled Courses</Styled.SemiHeader>} */}
                {haveEnrolledCourses && <Styled.SemiHeader>Enrolled Courses</Styled.SemiHeader>}
                {haveEnrolledCourses && (
                  <div style={{ marginTop: "1.5rem" }}>
                    <CourseList user={user} />
                  </div>
                )}

                {!isLoading && haveManageCourses && <Styled.SemiHeader>Managed Courses</Styled.SemiHeader>}
                {!isLoading && haveManageCourses && <AdminCourseList courses={manageCourses} />}
              </Grid.Column>

              <Grid.Column width="5">
                <Styled.SemiHeader>Recent Activity</Styled.SemiHeader>
                {user && <RecentActivity user={user} />}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      )}
    </>
  );
}

function Banner({ muted = false }) {
  const user = useAuth();
  const history = useHistory();

  return (
    <Segment raised style={{ background: muted ? "white" : "#F2F7D0", marginBottom: muted ? "3rem" : 0 }}>
      <Grid columns="2" divided>
        <Grid.Row>
          <Grid.Column width="9" textAlign="center">
            <Styled.Icon>
              <span className="fal fa-fw fa-chalkboard-teacher fa-2x" />
            </Styled.Icon>
            <Styled.BannerTitle>Enroll Course</Styled.BannerTitle>
            <Styled.BannerSmall>Student</Styled.BannerSmall>
            <Styled.Email>{user.email}</Styled.Email>
            <Styled.Paragraph>
              Contact course staff to enroll
              <br /> course using this email
            </Styled.Paragraph>
          </Grid.Column>

          <Grid.Column width="7" textAlign="center">
            <Styled.Icon>
              <span className="fal fa-fw fa-pencil-alt fa-2x" />
            </Styled.Icon>
            <Styled.BannerTitle>Serve Course</Styled.BannerTitle>
            <Styled.BannerSmall>Instructor</Styled.BannerSmall>
            <Form.Button onClick={() => history.push("/manage/new")} style={{ margin: 0 }}>
              Create Now
            </Form.Button>
            <Styled.Paragraph>
              Synclass for Instructors allows you to host your own course
              <br />
              <Link to={`/features`}>click here to learn more</Link>
            </Styled.Paragraph>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
}
