import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { ASSIGNMENT_ICON } from "../../../../constants/icons";
import { Menu, Icon } from "semantic-ui-react";

export function AssignmentMenu({ course, exercise, enrolledByUserId, exerciseMatrix }) {
  const history = useHistory();
  const params = useParams();

  return (
    <Menu vertical fluid>
      {Object.keys(enrolledByUserId).map(userId =>
        userId in exerciseMatrix ? (
          <Menu.Item
            key={userId}
            active={params.userId === userId}
            onClick={() => history.push(`/manage/${course._id}/${exercise._id}/assignments/${userId}`)}>
            <Icon
              name={ASSIGNMENT_ICON[exerciseMatrix[userId]["s"]].icon}
              color={ASSIGNMENT_ICON[exerciseMatrix[userId]["s"]].color}
            />
            {enrolledByUserId[userId][0].userName}
          </Menu.Item>
        ) : (
          <Menu.Item
            key={userId}
            active={params.userId === userId}
            onClick={() => history.push(`/manage/${course._id}/${exercise._id}/assignments/${userId}`)}>
            {enrolledByUserId[userId][0].userName}
          </Menu.Item>
        )
      )}
    </Menu>
  );
}
