import styled, { css } from "styled-components";

export const PageHeader = styled.div`
  margin-top: 3rem;
  /* margin-bottom: 2rem; */
  background-color: #cddc38;
`;

export const PageHeader2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* justify-content: flex-start; */
  line-height: normal;
  padding-top: 4rem;
  padding-bottom: 4rem;
  /* min-height: 100px; */
`;

export const Icon = styled.div`
  font-size: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const PageTitle = styled.div`
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 1rem;
`;

export const Block = styled.div`
  padding: 2rem 0;

  & div.segment {
    height: 100%;
  }

  ${props =>
    props.green &&
    css`
      background-color: #cddc38;
    `}
`;

export const Form = styled.form`
  display: flex;
`;

export const Button = styled.button`
  padding: 12px 2.5rem;
  margin: 4px 0 4px 1rem;
  border: 2px #11304d solid;
  color: #11304d;
  text-transform: capitalize;
  font-size: 1.2rem;
  white-space: nowrap;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }
`;

export const Input = styled.input`
  padding: 12px 12px;
  margin: 4px 0;
  border: 2px #11304d solid;
  color: #11304d;
  width: 76%;
  font-size: 1.2rem;

  flex-grow: 1;
`;

export const Header = styled.div`
  font-size: 1.5rem;
  color: black;
  /* font-weight: bold; */
  line-height: normal;
  text-align: left;

  margin-bottom: 0.5rem;
`;
export const Price = styled.div`
  text-transform: uppercase;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  opacity: 0.7;
`;
export const Paragraph = styled.p`
  text-align: left;
  padding: 1rem 0;
  margin: 0;
`;

export const Error = styled.p`
  text-align: center;
  color: #941616;
  margin-top: 0.5rem;
`;

export const Thanks = styled.div`
  padding: 20px 2.5rem;
  margin: 2rem 0 1rem 0;
  border: 2px #11304d solid;
  color: #11304d;
  font-size: 1rem;
  text-align: center;
  white-space: nowrap;
  background: white;
`;
