import React from "react";
import styled from "styled-components";

import Arrow from "./assets/Arrow@2x.png";
import Start from "./assets/Start@2x.png";
import First from "./assets/First@2x.png";

export default function Tips({ groups, exercises }) {
  console.log("@@", exercises);

  let type = null;

  if (exercises.length === 0) type = "content";
  if (groups.length === 0) type = "groups";

  return (
    <Container>
      {type === "groups" && (
        <>
          <img srcset={`${Arrow} 2x`} alt="Arrow up" />
          <img srcset={`${Start} 2x`} alt="Start Here" />
        </>
      )}
      {type === "content" && <img srcset={`${First} 2x`} alt="Add your first content" style={{ marginTop: "3rem" }} />}
    </Container>
  );
}

const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

  & > img:first-child {
    margin-bottom: 1rem;
  }
`;
