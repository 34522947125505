import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import { Footer } from "./footer";

import Navigation, { ManageNavigation, NavigationNonAuth } from "./Navigation";

import LandingPage from "../Pages/Landing";
import FeaturesPage from "../Pages/Features";
import PricingPage from "../Pages/Pricing";
import ContactPage from "../Pages/Contact";
import NotifyPage from "../Pages/Contact/notify";
import PrivacyPage from "../Pages/Legal/privacy";
import TermsPage from "../Pages/Legal/terms";

import SignUpPage from "../NewPages/Landing/SignUp";
import SignInPage from "../NewPages/Landing/SignIn";
import PasswordForgetPage from "../NewPages/Landing/PasswordForget";
import AccountPage from "../Pages/Account";
import Error404Page from "../Pages/Error404";

/* Manage pages */
import CourseIndex from "../NewPages/Manage/CourseIndex";

/* Students Pages */
import CoursePage from "../NewPages/Students/CoursePage";
import MyCoursesPage from "../NewPages/Students/MyCoursesPage";
import MessagesPage from "../NewPages/Students/MessagesPage";
import CreateCoursePage from "../NewPages/Manage/CreateCoursePage";

import * as ROUTES from "../../constants/routes";
import { withAuthentication, AuthUserContext } from "../Session";
import { Container } from "semantic-ui-react";

const App = props => {
  const authUser = useContext(AuthUserContext);

  if (!authUser) {
    return (
      <Router>
        <div>
          <NavigationNonAuth />
          <Switch>
            <Route path="/" component={GeneralPages} />
          </Switch>
        </div>
      </Router>
    );
  }

  // Authenticated Pages
  return (
    <Router>
      <div>
        {/* Render the right navigation */}
        <Switch>
          <Route exact path={ROUTES.MANAGE_NEW} component={Navigation} />
          <Route path={ROUTES.MANAGE_DASHBOARD} component={ManageNavigation} />
          <Route component={Navigation} />
        </Switch>

        {/*  Page Rendering */}
        <Switch>
          <Route exact path={ROUTES.SIGN_IN}>
            <Redirect to={`/user/courses`} />
          </Route>
          <Route exact path={ROUTES.MANAGE_NEW} component={CreateCoursePage} />
          <Route path={ROUTES.MANAGE_DASHBOARD} component={CourseIndex} />
          <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
          <Route path="/user" component={StudentPages} />
          {/* <Route exact path={ROUTES.LANDING} component={LandingPage} /> */}
          {/* <Route path="/" component={GeneralPages} /> */}
          <Route path="/">
            <Redirect to={`/user/courses`} />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export const GeneralPages = props => (
  <>
    <Switch>
      <Route exact path={ROUTES.PRICING} component={PricingPage} />
      <Route exact path={ROUTES.FEATURES} component={FeaturesPage} />
      <Route exact path={ROUTES.NOTIFY} component={NotifyPage} />
      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      <Route exact path={ROUTES.PRIVACY} component={PrivacyPage} />
      <Route exact path={ROUTES.TERMS} component={TermsPage} />
      <Route exact path={ROUTES.CONTACT} component={ContactPage} />
      <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route exact path={ROUTES.PASSWORD_FORGET}>
        <Container style={{ marginTop: "7em" }}>
          <PasswordForgetPage />
        </Container>
      </Route>
      <Route>
        <Container style={{ marginTop: "7em" }}>
          <Error404Page />
        </Container>
      </Route>
    </Switch>
    <Footer noMargin />
  </>
);

export const StudentPages = props => (
  <div>
    <Container style={{ marginTop: "7em" }}>
      <Route exact path={ROUTES.COURSES} component={MyCoursesPage} />
      <Route path={ROUTES.COURSE_DASHBOARD} component={CoursePage} />
      <Route exact path={ROUTES.MESSAGES} component={MessagesPage} />
    </Container>
    <Footer />
  </div>
);

export default withAuthentication(App);
