import React from "react";
import styled from "styled-components";

import ReactMarkdown from "react-markdown";
import Textarea from "react-textarea-autosize";

import Flex, { FlexItem } from "styled-flex-component";

import { Button, Header, Form, Icon, Message } from "semantic-ui-react";

const BlueDivider = styled.div`
  border-bottom: 2px #2285d0 solid;
  margin-top: 0.3em;
  margin-bottom: 1.5rem;
`;

class RichtextArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.defaultValue,
      changed: false,
      saving: false,
      preview: false
    };

    this.input = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.saveText = this.saveText.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value, changed: true });
  }

  async saveText(text) {
    await this.props.onSave(text);

    this.setState({
      saving: false
    });
  }

  async handleSave(event) {
    this.setState(
      {
        changed: false,
        saving: true
      },
      () => {
        this.saveText(this.state.value);
      }
    );
  }

  render() {
    const { changed, saving, preview } = this.state;

    let status = "All changes saved";
    if (changed) {
      status = "don't forget to save changes";
    } else {
      if (saving) {
        status = "Saving...";
      }
    }

    return (
      <>
        <Flex alignEnd>
          <FlexItem grow="1">
            <Header size="medium" color="blue">
              Instructions
            </Header>
          </FlexItem>
          <FlexItem>
            <span style={{ paddingRight: "1em" }}>{status}</span>
            <Button primary size="small" disabled={!changed} onClick={this.handleSave}>
              <Icon name="save" /> Save Changes
            </Button>
          </FlexItem>
        </Flex>
        <BlueDivider />

        {/* <SubHeader>
          <div className="d-flex justify-content-between align-items-center">
            <Left>
              <Title>{this.props.title}</Title>
            </Left>
            <Center>
              <EditMode
                selected={preview}
                onClick={() => {
                  this.setState({ preview: !preview });
                }}>
                <span className="far fa-fw fa-sm fa-eye" /> Show Preview
              </EditMode>
            </Center>
            <Right>
              <SaveInformation className={changed ? "text-danger" : "text-muted"}>{status}</SaveInformation>
              <Button disabled={!changed} size="sm" onClick={this.handleSave}>
                <span className="far fa-save" /> save changes
              </Button>
            </Right>
          </div>
        </SubHeader> */}

        {preview ? (
          <ReactMarkdown source={this.state.value} />
        ) : (
          <Form>
            <Textarea
              value={this.state.value}
              onChange={this.handleChange}
              style={{
                minHeight: 200
              }}
            />
          </Form>
        )}

        <Message info size="small" floating>
          <b>Markdown Enabled</b>: and can be used to format your document.{" "}
          <a
            href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
            target="_blank"
            rel="noopener noreferrer">
            See examples here
          </a>
        </Message>
      </>
    );
  }
}

RichtextArea.defaultProps = {
  title: "Edit",
  defaultValue: ""
};

export default RichtextArea;
