import React, { useMemo } from "react";
import { groupBy } from "lodash";
import { Link, Switch, Route, useRouteMatch } from "react-router-dom";
import { Grid, Button, Icon } from "semantic-ui-react";
import { useFirebase } from "../../../Firebase";
import { useFirestoreQuery } from "../../../../utils/firebaseHooks";

import { ExerciseMenu } from "./ExerciseMenu";
import { AssignmentMenu } from "./AssignmentMenu";
import AssignmentListPage from "./SubPages/AssignmentList";
import AssignmentPage from "./SubPages/Assignment";

export default function AssignmentIndex({ course, current, exercise }) {
  const firebase = useFirebase();
  const { path } = useRouteMatch();

  // Hooks
  const [enrolled, isLoading] = useFirestoreQuery(firebase.enrolledStudents(course._id).orderBy("userName", "asc"));
  const enrolledByUserId = useMemo(() => groupBy(enrolled, "_id"), [enrolled]);
  const exerciseMatrix = current ? current[exercise._id] || {} : {};

  return (
    <Switch>
      {/* General Assignment Page (with general Menu) */}
      <Route exact path={`${path}`}>
        <Grid>
          <Grid.Column width={4}>
            <ExerciseMenu activeItem="assignments" />
          </Grid.Column>

          <Grid.Column width={12}>
            <AssignmentListPage
              course={course}
              exercise={exercise}
              enrolledByUserId={enrolledByUserId}
              exerciseMatrix={exerciseMatrix}
            />
          </Grid.Column>
        </Grid>
      </Route>

      {/* Specific Assignment Page (with assignment list) */}
      <Route exact path={`${path}/:userId`}>
        <Grid divided>
          <Grid.Column width={4}>
            {/* Back button */}
            <Button basic color="teal" as={Link} to={`/manage/${course._id}/${exercise._id}/assignments`} fluid>
              <Icon name="chevron left" />
              Student Assignments
            </Button>

            {/* Students */}
            <AssignmentMenu
              course={course}
              exercise={exercise}
              enrolledByUserId={enrolledByUserId}
              exerciseMatrix={exerciseMatrix}
            />
          </Grid.Column>

          <Grid.Column width={12}>
            <AssignmentPage course={course} exercise={exercise} current={current} enrolledByUserId={enrolledByUserId} />
          </Grid.Column>
        </Grid>
      </Route>
    </Switch>
  );
}
