import styled from "styled-components";

const Button = styled.button`
  padding: 14px 28px !important;
  margin: 4px 0 !important;
  border: 2px #11304d solid;
  color: #11304d;
  font-size: 1.2rem;
  font-weight: 300;
  cursor: pointer;
`;

export default Button;