import React, { useState } from "react";
import { Icon, Button, Form, Input, Segment } from "semantic-ui-react";

export default function NewItemForm({ onSave }) {
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState("");
  const handleChange = (e, { value }) => setName(value);

  function onSubmit() {
    onSave(name);
    setName("");
    setEditMode(false);
  }

  return (
    <React.Fragment>
      <Segment className="add_content_row no-padding" onClick={() => editMode === false && setEditMode(true)}>
        <div className="course-content-list-item">
          <div>
            <Icon name="add" fitted color="grey" />
          </div>
          <div className="course-content-list-item_add">
            {editMode ? (
              <Form onSubmit={onSubmit}>
                <Input fluid action value={name} onChange={handleChange}>
                  <input name="name" placeholder="Content Name" autoFocus />
                  <Button
                    secondary
                    content="Cancel"
                    icon="times"
                    type="button"
                    onClick={() => {
                      setEditMode(false);
                      setName("");
                    }}
                  />
                  <Button primary type="submit" content="Save" icon="check" />
                </Input>
              </Form>
            ) : (
              "Add Content"
            )}
          </div>
        </div>
      </Segment>
    </React.Fragment>
  );
}
