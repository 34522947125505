import React from "react";

import { useFirebase } from "../../../../Firebase";
import { useFirestoreQuery } from "../../../../../utils/firebaseHooks";

import { Message, Icon } from "semantic-ui-react";
import { AdminMessagesList } from "../../../../Common/AdminMessagesList";

export default function MessagesPage({ course, current, exercise }) {
  const firebase = useFirebase();

  const [messages, isLoading] = useFirestoreQuery(
    firebase.messagesOfExerciseInCourse(exercise._id, course._id).orderBy("timestamp", "asc")
  );

  if (isLoading) {
    return (
      <React.Fragment>
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>Just one second</Message.Header>
            We are fetching that content for you.
          </Message.Content>
        </Message>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <AdminMessagesList messages={messages} />
    </React.Fragment>
  );
}
