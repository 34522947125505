import React from "react";
import { Link } from "react-router-dom";
import { ASSIGNMENT_ICON } from "../../../../../constants/icons";

import * as DataModel from "../../../../../services/dataModel";

import { useAuth } from "../../../../Session";
import { useFirebase } from "../../../../Firebase";

import { Header, Table, Icon, Menu, Dropdown } from "semantic-ui-react";
import HoldButton from "../../../../UI/HoldButton";

export default function AssignmentListPage({ course, exercise, enrolledByUserId, exerciseMatrix }) {
  const firebase = useFirebase();
  const auth = useAuth();

  // Functions
  async function assignExercise(userId) {
    const data = DataModel.newAssignment(course._id, userId, exercise, firebase.fieldValue.serverTimestamp());
    await firebase.assignment(userId, exercise._id).set(data);
  }

  async function assignAllUsers() {
    const users = Object.keys(enrolledByUserId).filter(userId => !(userId in exerciseMatrix));

    const batch = firebase.db.batch();
    users.forEach(userId => {
      const data = DataModel.newAssignment(course._id, userId, exercise, firebase.fieldValue.serverTimestamp());
      batch.set(firebase.assignment(userId, exercise._id), data);
    });
    return batch.commit();
  }

  async function downloadLatest() {
    let downloadExerciseLatestSubmissions = firebase.functions.httpsCallable("downloadExerciseLatestSubmissions");

    return downloadExerciseLatestSubmissions({
      courseId: course._id,
      exerciseId: exercise._id,
      userId: auth.uid,
      title: `${exercise.title}: latest submitted assignments`,
      subject: course.name
    });
  }

  async function downloadToReview() {
    let downloadExerciseToReviewSubmissions = firebase.functions.httpsCallable("downloadExerciseToReviewSubmissions");

    return downloadExerciseToReviewSubmissions({
      courseId: course._id,
      exerciseId: exercise._id,
      userId: auth.uid,
      title: `${exercise.title}: assignments to review`,
      subject: course.name
    });
  }

  // Render
  return (
    <React.Fragment>
      <Header size="medium" dividing color="blue">
        Student Assignments
      </Header>

      <ActionsMenu
        assignAllUsers={assignAllUsers}
        downloadLatest={downloadLatest}
        downloadToReview={downloadToReview}
      />

      <Table selectable striped singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>Student Name</Table.HeaderCell>
            {/* <Table.HeaderCell>Last Updated</Table.HeaderCell> */}
            <Table.HeaderCell textAlign="right"></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {Object.keys(enrolledByUserId).map(userId =>
            userId in exerciseMatrix ? (
              <Table.Row key={userId}>
                <Table.Cell collapsing textAlign="center">
                  <Icon
                    name={ASSIGNMENT_ICON[exerciseMatrix[userId]["s"]].icon}
                    color={ASSIGNMENT_ICON[exerciseMatrix[userId]["s"]].color}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Link to={`/manage/${course._id}/${exercise._id}/assignments/${userId}`}>
                    {enrolledByUserId[userId][0].userName}
                  </Link>
                </Table.Cell>
                {/* <Table.Cell>5 days ago</Table.Cell> */}
                <Table.Cell collapsing textAlign="right">
                  {ASSIGNMENT_ICON[exerciseMatrix[userId]["s"]].title}
                </Table.Cell>
              </Table.Row>
            ) : (
              <Table.Row key={userId}>
                <Table.Cell collapsing textAlign="center"></Table.Cell>
                <Table.Cell>
                  <Link to={`/manage/${course._id}/${exercise._id}/assignments/${userId}`}>
                    {enrolledByUserId[userId][0].userName}
                  </Link>
                </Table.Cell>
                {/* <Table.Cell></Table.Cell> */}
                <Table.Cell collapsing textAlign="right" style={{ padding: 0 }}>
                  <HoldButton size="small" color="green" onClick={() => assignExercise(userId)}>
                    <Icon name="plus circle" /> Assign
                  </HoldButton>
                </Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
    </React.Fragment>
  );
}

function ActionsMenu({ assignAllUsers, downloadLatest, downloadToReview }) {
  return (
    <Menu inverted size="tiny" color="blue">
      <Menu.Item header>Actions</Menu.Item>
      <Menu.Item onClick={assignAllUsers}>
        <Icon name="plus square" />
        Assign All
      </Menu.Item>

      <Dropdown item text="Download">
        <Dropdown.Menu>
          <Dropdown.Item onClick={downloadLatest}>Download All assignments</Dropdown.Item>
          <Dropdown.Item onClick={downloadToReview}>Download assignments to review</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  );
}
