import React from "react";
import { RecentCourseActivity } from "../../../Common/RecentActivity";

export default function RecentActivityPage({ courseId, studentId }) {
  return (
    <div>
      <RecentCourseActivity userId={studentId} courseId={courseId} asUser={false} limit={14} />
    </div>
  );
}
