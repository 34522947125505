import React from "react";
import { isEmpty } from "lodash";

import { Form, Container, Button, Message } from "semantic-ui-react";
import { Formik } from "formik";
import TextArea from "react-textarea-autosize";

export const CourseForm = ({ initialValues, onSubmit }) => {
  let initialFormValues = {
    name: "",
    description: "",
    ...initialValues
  };

  let validate = values => {
    let errors = {};
    // if (!values.name) {
    //   errors.name = 'Required';
    // }
    return errors;
  };

  return (
    <Container text>
      <Formik
        initialValues={initialFormValues}
        validate={validate}
        onSubmit={onSubmit}
        render={({ values, status, errors, handleChange, handleSubmit, isSubmitting, dirty }) => (
          <Form>
            {status && <Message negative content={status.msg} />}
            <Form.Input
              value={values.name}
              onChange={handleChange}
              required
              type="text"
              label="Course Name"
              name="name"
              error={errors.name !== undefined}
            />
            <Message error content="DDD" />
            <Form.Field>
              <label>Course Description</label>
              <TextArea
                name="description"
                value={values.description}
                onChange={handleChange}
                placeholder="Course Description"
              />
            </Form.Field>
            <Button
              primary
              fluid
              type="submit"
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors) || !dirty}>
              {initialValues ? "Save" : "Create Course"}
            </Button>
          </Form>
        )}
      />
    </Container>
  );

  // return (
  //   <div>
  // <Formik
  //   initialValues={formValues}
  //   validate={validate}
  //   onSubmit={onSubmit}
  //   render={({ errors, status, touched, isSubmitting }) => (
  //         <Form className="ui form">
  //           <div className="form-group">
  //             <label htmlFor="name">Course Name</label>
  //             <OverlayTrigger
  //               overlay={<Tooltip id="tooltip-disabled">Changing name of active course is not allowed</Tooltip>}>
  //               <Field
  //                 type="text"
  //                 name="name"
  //                 className="form-control"
  //                 required
  //                 disabled={course && course.enrolledCounter}
  //               />
  //             </OverlayTrigger>
  //             <ErrorMessage name="name" component="div" />
  //             <div className="text-muted">This will be used as the public course name</div>
  //           </div>

  //           <div className="form-group">
  //             <label htmlFor="description">Course Description (optional)</label>
  //             <Field component="textarea" name="description" rows="4" className="form-control" />
  //             <ErrorMessage name="name" component="div" />
  //           </div>

  //           {status && status.msg && <div>{status.msg}</div>}

  //           <button className="btn btn-primary w-100" type="submit" disabled={isSubmitting}>
  //             Submit
  //           </button>
  //         </Form>
  //       )}
  //     />
  //   </div>
  // );
};
