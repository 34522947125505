import React from "react";
import { Link } from "react-router-dom";
import { EXERCISE_TYPE } from "../../../../../constants/icons";

import { Header, Segment, Grid, Divider, Icon, List } from "semantic-ui-react";
import Flex from "styled-flex-component";
import AsyncButton from "../../../../UI/AsyncButton";

export default function ContentType({ course, current, exercise }) {
  async function updateType(type) {
    await exercise._ref.update({ type });
  }

  return (
    <React.Fragment>
      <Segment>
        <Grid columns={2} stackable textAlign="center" style={{ paddingTop: "4rem", paddingBottom: "4rem" }}>
          <Divider vertical>Or</Divider>

          <Grid.Row textAlign="center">
            <Grid.Column>
              <Header icon as={Link} to={"/"}>
                <Icon name={EXERCISE_TYPE.document.icon} />
                Plain Text Document
              </Header>
              <Flex center style={{ textAlign: "left" }}>
                <List>
                  <List.Item icon="check square outline" content="Editable Content" />
                  <List.Item icon="check square outline" content="Attachments" />
                  <List.Item icon="check square outline" content="Optional Markdown Formatting" />
                  <List.Item icon="minus square outline" content="No submission required" />
                </List>
              </Flex>
            </Grid.Column>
            <Grid.Column>
              <Header icon>
                <Icon name={EXERCISE_TYPE.exercise.icon} />
                Exercise
              </Header>
              <Flex center style={{ textAlign: "left" }}>
                <List>
                  <List.Item icon="check square outline" content="Editable Content" />
                  <List.Item icon="check square outline" content="Attachments" />
                  <List.Item icon="check square outline" content="Optional Markdown Formatting" />
                  <List.Item icon="check square outline" content="Assignment Submission required" />
                  <List.Item icon="check square outline" content="Student can attach files" />
                  <List.Item icon="check square outline" content="Assignment requires review" />
                </List>
              </Flex>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <AsyncButton color="green" size="large" content="Choose" onClick={() => updateType("document")} />
            </Grid.Column>
            <Grid.Column>
              <AsyncButton color="green" size="large" content="Choose" onClick={() => updateType("exercise")} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </React.Fragment>
  );
}
