export function countExercise(currentExercise, total) {
  let initialData = {
    ASSIGNED: 0,
    SUBMITTED: 0,
    IN_REVIEW: 0,
    REDO: 0,
    DONE: 0,
    TOTAL: total
  };

  if (!currentExercise) {
    return initialData;
  }

  Object.values(currentExercise).forEach(entry => {
    initialData[entry.s]++;
  });

  return initialData;
}

export function countExerciseOfStudent(studentId, current, total) {
  let initialData = {
    ASSIGNED: 0,
    SUBMITTED: 0,
    IN_REVIEW: 0,
    REDO: 0,
    DONE: 0,
    TOTAL: total
  };

  if (!current) {
    return initialData;
  }

  const currentExercise = Object.values(current)
    .filter(stats => studentId in stats)
    .map(stats => stats[studentId]);

  Object.values(currentExercise).forEach(entry => {
    initialData[entry.s]++;
  });

  return initialData;
}
