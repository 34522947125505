import styled from "styled-components";

export const Block = styled.div``;

export const BannerTitle = styled.div`
  font-size: 1.5rem;
  color: black;
  line-height: normal;
  text-align: center;
  margin-bottom: 0.5rem;
`;

export const BannerSmall = styled.div`
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1.5rem;
  opacity: 0.7;
`;

export const Email = styled.div`
  padding: 12px 12px !important;
  margin: 1rem 0 4px 0rem;
  border: 2px #11304d solid;
  color: #11304d;
  font-size: 1.2rem;
  background: white;
`;

export const Paragraph = styled.div`
  margin: 2rem 0 1rem 0;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 1.1rem;
  line-height: 1.5;
`;
export const Icon = styled.div`
  margin: 1rem 0 1rem 0;
`;

export const SemiHeader = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.9rem;
  border-bottom: 1px #0000003b solid;
  padding: 2px 0px;
  color: #12304d;

  &:not(:first-of-type) {
    margin-top: 1rem;
  }
`;
