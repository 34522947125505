import React from "react";
import TimeAgo from "react-timeago";
import AsyncButton from "../UI/AsyncButton";
import { Icon, Segment } from "semantic-ui-react";

export function AnnouncementList({ announcements, isAdmin = false }) {
  return announcements.map(announcement => (
    <Announcement key={announcement._id} announcement={announcement} isAdmin={isAdmin} />
  ));
}

export function Announcement({ announcement, isAdmin = false }) {
  async function handleDelete() {
    return announcement._ref.delete();
  }

  return (
    <Segment>
      <p style={{ whiteSpace: "pre" }}>{announcement.content}</p>
      <small>
        <b>
          <Icon name="user" /> {announcement.authorName}
        </b>{" "}
        | {announcement.createdAt && <TimeAgo date={announcement.createdAt.toDate()} minPeriod={5} />}{" "}
        {isAdmin && (
          <>
            {/* <AsyncButton size="mini" icon="pencil" compact basic /> */}
            <AsyncButton size="mini" icon="trash" compact basic onClick={handleDelete} />
          </>
        )}
      </small>
    </Segment>
  );
}
