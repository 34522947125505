import React, { useState } from "react";
import { isEmpty } from "lodash";

import { useFirebase } from "../Firebase";
import { useAuth } from "../Session";
import * as dataModel from "../../services/dataModel";

import { Message, Menu } from "semantic-ui-react";
import UserMessage from "./UserMessage";

export function UserMessagesList({ messages }) {
  const firebase = useFirebase();
  const authUser = useAuth();
  const [filterBy, setFilterBy] = useState(null);

  async function resolveMessage(message) {
    const updateFields = dataModel.resolveMessage(
      message,
      authUser.name,
      authUser.uid,
      null,
      firebase.fieldValue.serverTimestamp()
    );
    return message._ref.update(updateFields);
  }

  if (isEmpty(messages)) {
    return (
      <React.Fragment>
        <Message info>No messages found</Message>
      </React.Fragment>
    );
  }

  // Count Messages
  const totalCount = messages.length;
  const resolvedCount = messages.filter(message => message.isResolved).length;

  // Filter messages
  let filteredMessages = messages;
  if (filterBy === "unresolved") {
    filteredMessages = messages.filter(message => !message.isResolved);
  }
  if (filterBy === "resolved") {
    filteredMessages = messages.filter(message => message.isResolved);
  }

  return (
    <React.Fragment>
      <Menu secondary compact fluid>
        <Menu.Item header>Filter By</Menu.Item>

        <Menu.Item name={`All Messages ${totalCount}`} active={filterBy === null} onClick={() => setFilterBy(null)} />
        <Menu.Item
          name={`Unresolved ${totalCount - resolvedCount}`}
          active={filterBy === "unresolved"}
          onClick={() => setFilterBy("unresolved")}
        />
        <Menu.Item
          name={`Resolved ${resolvedCount}`}
          active={filterBy === "resolved"}
          onClick={() => setFilterBy("resolved")}
        />
      </Menu>
      {isEmpty(filteredMessages) && <Message>No messages found with this filter</Message>}
      {filteredMessages.map(message => (
        <UserMessage key={message._id} message={message} onResolveClick={resolveMessage} />
      ))}
    </React.Fragment>
  );
}
