import React, { Component } from "react";
import * as ROLES from "../../../constants/roles";
import { withAuthorization, AuthUserContext } from "../../Session";
import { withFirebase } from "../../Firebase";
import { Menu } from "semantic-ui-react";

import TimeAgo from "react-timeago";
import { Linkify } from "../../UI/Linkify";
import * as Elements from "./elements";

class DownloadsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      downloads: [],
      showDownloadModal: false
    };

    this.downloadUrl = this.downloadUrl.bind(this);
  }

  downloadUrl(url) {
    const ref = this.props.firebase.storage.ref(url);
    return ref
      .getDownloadURL()
      .then(function(url) {
        window.location.href = url;
      })
      .catch(function(error) {
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;

          case "storage/unauthorized":
            // User doesn't have permission to access the object
            throw error;

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            throw error;
          default:
            break;
        }
      });
  }

  delete(index) {
    const userId = this.context.uid;
    const { downloads } = this.state;
    let newFiles = [...downloads.files];
    newFiles.splice(index, 1);

    return this.props.firebase.downloadsOfUser(userId).update({
      files: newFiles
    });
  }

  componentDidMount() {
    const userId = this.context.uid;

    /* Load Course information */
    this.unsubscribe = this.props.firebase.downloadsOfUser(userId).onSnapshot(doc => {
      let data = { ...doc.data(), doc: doc };
      this.setState({
        downloads: data,
        showDownloadModal: data && data.files && data.files.length > 0
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { downloads, showDownloadModal } = this.state;

    return (
      <>
        {/* <span className="a" onClick={() => this.setState({ showDownloadModal: !showDownloadModal })}>
          <span className="fas fa-arrow-alt-circle-down fa-sm fa-fw" /> Downloads
        </span> */}
        <Menu.Item
          name="Downloads"
          icon="arrow alternate circle down"
          onClick={() => this.setState({ showDownloadModal: !showDownloadModal })}
        />

        {showDownloadModal && (
          <Elements.Modal>
            <Elements.Header>
              <Elements.Title>Downloads</Elements.Title>
              <div className="link" onClick={() => this.setState({ showDownloadModal: false })}>
                <span className="fas fa-chevron-up" />
              </div>
            </Elements.Header>
            <Elements.List>
              {downloads && downloads.files && downloads.files.length > 0 ? (
                downloads.files.map((file, index) => (
                  <Elements.Item key={file.url || index}>
                    <div>
                      <div>{file.subject}</div>
                      <div>{file.title}</div>
                    </div>
                    {file.status === "ERROR" && (
                      <>
                        <div>
                          <span className="fa-fw fa-sm fas fa-exclamation-triangle" /> Please try again
                        </div>
                        <div>Failed</div>
                      </>
                    )}
                    {file.status === "IN_PROGRESS" && (
                      <>
                        <div>
                          <span className="fa-fw fa-spin fa-sm fas fa-spinner" />
                        </div>
                        <div>Preparing</div>
                      </>
                    )}

                    {file.status === "COMPLETED" && (
                      <>
                        <div>
                          <TimeAgo date={file.startedOn.toDate()} />
                        </div>
                        <Linkify onClick={() => this.downloadUrl(file.url)}>Download</Linkify>
                      </>
                    )}

                    <div className="linkify">
                      <Linkify onClick={() => this.delete(index)}>
                        <span className="fas fa-times-circle" />
                      </Linkify>
                    </div>
                  </Elements.Item>
                ))
              ) : (
                <Elements.Item>
                  <div style={{ textAlign: "center" }}>
                    <div>No downloads available</div>
                  </div>
                </Elements.Item>
              )}
            </Elements.List>
          </Elements.Modal>
        )}
      </>
    );
  }
}

DownloadsModal.contextType = AuthUserContext;
DownloadsModal = withAuthorization(ROLES.AUTH)(withFirebase(DownloadsModal));

export default DownloadsModal;
