import React from "react";
import { isEmpty } from "lodash";
import { withFirebase } from "../../Firebase";
import { withAuthorization } from "../../Session";
import * as ROLES from "../../../constants/roles";

import AsyncButton from "../../UI/AsyncButton";

import { Segment, Icon, Progress, Header, List } from "semantic-ui-react";
import Dropzone from "./Dropzone";
import { getFileIcon } from "../../UI";

const calcPercentage = snapshot => {
  var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  if (percentage === 0) percentage = 1;
  return percentage;
};

class UploadZone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      beingUploaded: {}
    };

    // Actions
    this.uploadFiles = this.uploadFiles.bind(this);
    this.cancelUpload = this.cancelUpload.bind(this);
    this.deleteFile = this.deleteFile.bind(this);

    // Maintain Live Status
    this._updateStatus = this._updateStatus.bind(this);
    this.markInProgress = this.markInProgress.bind(this);
    this.markError = this.markError.bind(this);
    this.deleteProgress = this.deleteProgress.bind(this);
  }

  getFileRef(fileName) {
    var storageRef = this.props.firebase.storage.ref();
    var fileRef = storageRef.child(`${this.props.basePath}/${fileName}`);
    return fileRef;
  }

  _updateStatus(fileName, task, url, inProgress, error, underDeletion) {
    let beingUploaded = {
      ...this.state.beingUploaded,
      [fileName]: { fileName, task, url, inProgress, error, underDeletion }
    };

    this.setState({
      beingUploaded
    });
  }

  markInProgress(fileName, task) {
    this._updateStatus(fileName, task, null, true, null, false);
  }

  markError(fileName, task, errorMessage) {
    this._updateStatus(fileName, task, null, false, errorMessage, false);
  }

  deleteProgress(fileName) {
    let beingUploaded = { ...this.state.beingUploaded };
    delete beingUploaded[fileName];
    this.setState({ beingUploaded });
  }

  uploadFile(file) {
    var fileRef = this.getFileRef(file.name);

    // TODO: Check filesize

    // Upload file
    var task = fileRef.put(file);

    task.on(
      "state_changed",

      // Progress
      snapshot => {
        this.markInProgress(file.name, task);
      },

      // Error
      error => {
        switch (error.code) {
          case "storage/object-not-found":
          case "storage/canceled":
            this.deleteProgress(file.name);
            break;
          case "storage/unauthorized":
          case "storage/unknown":
          default:
            this.markError(file.name, task, "Error occurred. Please try again");
        }
      },

      // Completion
      () => {
        task.snapshot.ref.getDownloadURL().then(url => {
          this.deleteProgress(file.name);
          this.props.onFileUploaded(file.name, {
            url: url,
            size: task.snapshot.metadata.size,
            contentType: task.snapshot.metadata.contentType,
            path: task.snapshot.metadata.fullPath
          });
        });
      }
    );
  }

  uploadFiles(files) {
    files.map(file => this.uploadFile(file));
  }

  cancelUpload(fileName) {
    if (this.state.beingUploaded[fileName].task) {
      this.state.beingUploaded[fileName].task.cancel();
    }
  }

  async deleteFile(fileName) {
    var fileRef = this.getFileRef(fileName);

    // Delete from storage
    try {
      await fileRef.delete();
    } catch (error) {
      switch (error.code) {
        case "storage/object-not-found":
          break;
        default:
          throw error;
      }
    }

    this.props.onFileDeleted(fileName);
  }

  renderCurrentFiles() {
    const currentFiles = this.props.currentFiles;

    const items = Object.keys(currentFiles).map(fileName => (
      <List.Item key={fileName}>
        <List.Content>
          <List.Icon name={getFileIcon(currentFiles[fileName].contentType)} />
          <a href={currentFiles[fileName].url}>{fileName}</a>{" "}
          <AsyncButton basic size="mini" compact icon onClick={() => this.deleteFile(fileName)}>
            <Icon name="trash alternate" />
            Delete
          </AsyncButton>
        </List.Content>
      </List.Item>
    ));

    return <List>{items}</List>;
  }

  renderBeingUploaded() {
    const { beingUploaded } = this.state;

    const items = Object.values(beingUploaded).map(({ fileName, task, url, inProgress }) => (
      <List.Item key={fileName}>
        <List.Content>
          <List.Icon name="file alternate outline" />
          {fileName}
          <Progress
            size="small"
            percent={calcPercentage(task.snapshot)}
            color="green"
            style={{ width: "100px", display: "inline-block", margin: "0 1em 0 1em", verticalAlign: "middle" }}
          />
          <AsyncButton basic size="mini" compact icon onClick={() => this.cancelUpload(fileName)}>
            <Icon name="times" />
            Cancel
          </AsyncButton>
        </List.Content>
      </List.Item>
    ));

    return <List>{items}</List>;
  }

  renderSubmitButton() {
    const { onSubmit, currentFiles } = this.props;

    if (!onSubmit) {
      return <></>;
    }

    return (
      <AsyncButton disabled={isEmpty(currentFiles)} onClick={() => onSubmit(currentFiles)} primary>
        Submit
      </AsyncButton>
    );
  }

  renderGuidelines() {
    const { maxFileSize, minAmountOfFiles } = this.props;

    const hasGuidelines = maxFileSize || minAmountOfFiles;

    if (hasGuidelines) {
      return (
        <>
          <Header size="small">Submission Guidelines</Header>
          {maxFileSize && (
            <div>
              <small>
                <span className="fas fa-exclamation-triangle text-info" /> Maximum file size <b>{maxFileSize}MB</b>
              </small>
            </div>
          )}
          {minAmountOfFiles && (
            <div>
              <small>
                <span className="fas fa-exclamation-triangle text-info" /> Minimum <b>{minAmountOfFiles}</b> files
              </small>
            </div>
          )}
        </>
      );
    }
  }

  render() {
    const hasFiles = Object.keys(this.props.currentFiles).length + Object.keys(this.state.beingUploaded).length > 0;

    let wrapper = children => <Segment>{children}</Segment>;

    if (this.props.noCard) {
      wrapper = children => children;
    }

    return wrapper(
      <div>
        <Dropzone onFilesAdded={this.uploadFiles} />

        {hasFiles ? <Header size="small">Uploaded Files</Header> : <></>}

        {this.renderCurrentFiles()}
        {this.renderBeingUploaded()}
        {this.renderSubmitButton()}
        {this.renderGuidelines()}
      </div>
    );
  }
}

export default withAuthorization(ROLES.AUTH)(withFirebase(UploadZone));
