import React from "react";
import { countBy } from "lodash";
import { Header, Icon, Statistic, Message } from "semantic-ui-react";

import { AssignmentList } from "../../../../UI/AssignmentList";
import { CourseMenu } from "../CourseMenu";

export default function PageAssignments({ assignments, course, userId, courseId }) {
  return (
    <React.Fragment>
      <Header as="h2" color="teal">
        <Icon name="tasks" />
        <Header.Content>
          Assignments
          <Header.Subheader>See your assignments for this course</Header.Subheader>
        </Header.Content>
      </Header>
      {assignments && assignments.length === 0 && <Message>Nothing assigned yet</Message>}
      <AssignmentList course={course} assignments={assignments} />
    </React.Fragment>
  );
}

export function AssignmentsPageMenu({ assignments }) {
  const stats = countBy(assignments.map(assignment => assignment.status));
  return (
    <React.Fragment>
      <CourseMenu activeItem="assignments" />

      {/* <Segment> */}
      <Statistic.Group horizontal size="mini">
        <Statistic color="blue">
          <Statistic.Value>{assignments.length}</Statistic.Value>
          <Statistic.Label>Total Exercises</Statistic.Label>
        </Statistic>

        <Statistic color="orange" size="small">
          <Statistic.Value>{(stats["SUBMITTED"] || 0) + (stats["IN_REVIEW"] || 0)}</Statistic.Value>
          <Statistic.Label>SUBMITTED</Statistic.Label>
        </Statistic>

        <Statistic color="red" size="small">
          <Statistic.Value>{stats["REDO"] || 0}</Statistic.Value>
          <Statistic.Label>REDO</Statistic.Label>
        </Statistic>

        <Statistic color="green" size="small">
          <Statistic.Value>{stats["DONE"] || 0}</Statistic.Value>
          <Statistic.Label>Completed</Statistic.Label>
        </Statistic>
      </Statistic.Group>
      {/* </Segment> */}
    </React.Fragment>
  );
}
