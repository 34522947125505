import React from "react";
import * as dataModel from "../../../../services/dataModel";
import * as ROUTES from "../../../../constants/routes";

import { useHistory } from "react-router-dom";
import { useFirebase } from "../../../Firebase";
import { useAuth } from "../../../Session";

import { Container, Icon, Breadcrumb, Divider, Header } from "semantic-ui-react";

import { CourseForm } from "./CourseForm";

export default function CreateCoursePage() {
  const firebase = useFirebase();
  const history = useHistory();
  const auth = useAuth();

  async function saveCourse(values, { setSubmitting, setStatus }) {
    const course = dataModel.newCourse(
      values.name,
      values.description,
      auth.uid,
      firebase.fieldValue.serverTimestamp()
    );
    try {
      await firebase.courses().add(course);
      history.push(ROUTES.MANAGE);
    } catch (error) {
      setStatus({ msg: "There was an error while processing the form, Please try again" });
      setSubmitting(false);
    }
  }

  return (
    <Container style={{ marginTop: "7em" }}>
      <Header as="h1">
        <Icon name="book" />
        <Header.Content>Create new course</Header.Content>
      </Header>
      <Breadcrumb>
        <Breadcrumb.Section>
          <Icon name="home" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section active>Create new course</Breadcrumb.Section>
      </Breadcrumb>

      <Divider
        style={{
          paddingBottom: "1rem"
        }}
      />

      <CourseForm onSubmit={saveCourse} />
    </Container>
  );
}
