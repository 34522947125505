import useIsMounted from "../../utils/isMounted";
import React, { useState } from "react";

export function Linkify({ onClick, children }) {
  const [disabled, setDisabled] = useState(false);
  const isMounted = useIsMounted();

  function onClickHandler() {
    if (disabled) {
      return;
    }

    setDisabled(true);
    onClick().then(() => {
      if (isMounted()) {
        setDisabled(false);
      }
    });
  }

  return (
    <div className={disabled ? "linkify linkify-disabled" : "linkify"} onClick={onClickHandler}>
      {children}
    </div>
  );
}
