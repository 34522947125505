import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withFirebase } from "../../../Firebase";
import * as ROUTES from "../../../../constants/routes";

import { Header, Grid, Message, Icon, Form, Button, Segment } from "semantic-ui-react";

const PasswordForgetPage = () => (
  <Grid textAlign="center">
    <Grid.Column style={{ maxWidth: 450 }}>
      <Header as="h1" icon>
        <Icon circular name="lock" />
        <Header.Content>Forgot your password?</Header.Content>
        <Header.Subheader>Enter your email you've used to sign-up</Header.Subheader>
      </Header>
      <PasswordForgetForm />
    </Grid.Column>
  </Grid>
);

const INITIAL_STATE = {
  email: "",
  error: null,
  message: null
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE, message: "Check your email for further instructions" });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { error, message } = this.state;

    return (
      <Segment textAlign="left">
        {message && <Message header="Now it's your turn" content={message} />}
        {error && <Message error header="Whoops" content={error.message} />}

        <Form error={!!error} onSubmit={this.onSubmit}>
          <Form.Field required>
            <Form.Input
              label="E-mail address"
              name="email"
              value={this.state.email}
              onChange={this.onChange}
              type="text"
              placeholder="E-mail address"
            />
          </Form.Field>
          <Button type="submit" color="teal" fluid>
            Reset my password
          </Button>
        </Form>
      </Segment>

      // <form onSubmit={this.onSubmit}>
      //   <input
      // name="email"
      // value={this.state.email}
      // onChange={this.onChange}
      // type="text"
      // placeholder="Email Address"
      //     className="form-control mb-3"
      //   />
      //   <button disabled={isInvalid} type="submit" className="btn btn-large btn-primary btn-block">
      //     Reset My Password
      //   </button>

      //   {message && <div className="mt-3 alert alert-primary">{message}</div>}

      //   {error && <div className="mt-3 alert alert-danger">{error.message}</div>}
      // </form>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
