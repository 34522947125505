import styled, { css } from "styled-components";

export const PageHeader = styled.div`
  margin-top: 3.2rem;
  /* margin-bottom: 2rem; */
  background-color: #cddc38;
`;

export const PageHeader2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* justify-content: flex-start; */
  line-height: normal;
  padding-top: 4rem;
  padding-bottom: 4rem;
  /* min-height: 100px; */
`;

export const Icon = styled.div`
  font-size: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const PageTitle = styled.div`
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 1rem;
`;

export const Image = styled.img`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px 2px, rgba(0, 0, 0, 0.2) 0px 0px 2px;
  width: 100%;
`;

export const SmallImage = styled.img`
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px 2px, rgba(0, 0, 0, 0.2) 0px 0px 2px; */
  width: 100%;
`;

export const Divider = styled.hr`
  border-top: 0;
  border-bottom: 1px #ccdc38 solid;
`;

export const BlockHeader = styled.h1`
  text-align: center;
  margin-bottom: 2rem;
`;

export const Block = styled.div`
  padding: 2rem 0;
  text-align: center;

  & div.segment {
    height: 100%;
  }
  /* color: #40474e; */

  ${props =>
    props.orange &&
    css`
      background-color: #ffc107;
    `}

  ${props =>
    props.green &&
    css`
      background-color: #cddc38;
    `}
`;

export const Header = styled.div`
  font-size: 1.5rem;
  color: black;
  /* font-weight: bold; */
  line-height: normal;
  text-align: center;

  margin-bottom: 0.5rem;
`;
export const Price = styled.div`
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1rem;
  opacity: 0.7;
`;
export const Paragraph = styled.p`
  text-align: center;
  padding: 1rem 3rem;
  margin: 0;
`;

export const Small = styled.p`
  text-align: center;
  padding: 1rem 3rem;
  margin: 0;
  font-size: 0.9rem !important;
`;

export const SubHeader = styled.p`
  font-size: 1.4rem;
`;

export const Columns = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Rows = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

export const Column = styled.div`
  flex: 1;
  padding: 2rem 2rem;
  h3 {
    font-size: 1.2rem;
    margin: 0 0 0.5rem 0;
  }
  p {
    font-size: 1.1rem;
    line-height: 1.6;
  }
`;
export const Row = styled.div`
  flex: 1;
  padding: 1rem 0;

  h3 {
    font-size: 1.2rem;
    margin: 0 0 0.5rem 0;
  }
  p {
    font-size: 1.1rem;
    line-height: 1.6;
  }
`;

export const Button = styled.button`
  padding: 12px 12px !important;
  margin: 4px 0 !important;
  border: 2px #11304d solid;
  color: #11304d;
  text-transform: capitalize !important;
  width: 76%;
  font-size: 1.2rem;
  cursor: pointer;
`;
