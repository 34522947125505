import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../../../Firebase";

import * as Styled from "../SignUp/styles";
import { Container, Message, Button, Segment } from "semantic-ui-react";
import Form from "../../../UI/Form";

function SignInPage() {
  return (
    <>
      <Styled.PageHeader>
        <Styled.PageHeader.Icon>
          <span className="fad fa-sign-in fa-fw" />
        </Styled.PageHeader.Icon>
        <Styled.PageHeader.Title>Login</Styled.PageHeader.Title>
      </Styled.PageHeader>

      <Styled.Block green>
        <Container style={{ width: "600px" }}>
          <SignInForm />
          <Segment style={{ background: "#ffffffba" }} padded>
            <PasswordForgetLink />
          </Segment>
        </Container>
      </Styled.Block>
    </>
  );
}

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
  loading: false
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;
    this.setState({ loading: true });

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ loading: false });
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error, loading } = this.state;

    return (
      <Segment style={{ background: "#ffffffea" }} padded>
        <Form error={!!error} onSubmit={this.onSubmit}>
          <Form.Input
            label="Email address"
            icon="user"
            iconPosition="left"
            name="email"
            value={email}
            onChange={this.onChange}
            required
            placeholder="Enter your email address"
          />
          <Form.Input
            label="Password"
            icon="lock"
            iconPosition="left"
            name="password"
            type="password"
            valye={password}
            onChange={this.onChange}
            required
            placeholder="Enter password"
          />
          {error && <Form.Error>{error.message}</Form.Error>}
          <Form.Button type="submit" disabled={loading}>
            Sign In
          </Form.Button>
        </Form>
      </Segment>
    );
  }
}

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

export default SignInPage;

export { SignInForm };
