import styled, { css } from "styled-components";
import React from "react";
import devices from "../../../../constants/devices";

export const PageHeader = styled.div`
  background-color: #cddc38;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  line-height: normal;

  margin-top: 3rem;

  padding-top: 4rem;
  padding-bottom: 4rem;
`;

PageHeader.Icon = styled.div`
  font-size: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

PageHeader.Title = styled.div`
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 1rem;
`;

export const Block = styled.div`
  padding: 2rem 0 10rem 0;

  & div.segment {
    height: 100%;
  }

  ${props =>
    props.green &&
    css`
      background-color: #cddc38;
    `}
`;
