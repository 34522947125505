import React from "react";
import ReactDOM from "react-dom";

import "./assets/fonts/css/all.css";
import "./semanticindex.css";
import * as serviceWorker from "./serviceWorker";

import App from "./components/App";
import ErrorPage from "./components/App/ErrorPage";
import Firebase, { FirebaseContext } from "./components/Firebase";

import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";

// import * as FullStory from '@fullstory/browser';

// import LogRocket from 'logrocket';
// LogRocket.init('s6ryky/synclass');

// import mixpanel from 'mixpanel-browser';
// import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel';
// mixpanel.init("7b7aa4ea3db96e09b3fa0e492a6e372a");


const bugsnagClient = bugsnag("67d8ab1792eb518d80ca070759156c44");
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin("react");

// FullStory.init({ orgId: '108YA0' });

ReactDOM.render(
  <ErrorBoundary FallbackComponent={ErrorPage}>
    <FirebaseContext.Provider value={new Firebase()}>
      {/* <MixpanelProvider mixpanel={mixpanel}> */}
        <App />
      {/* </MixpanelProvider> */}
    </FirebaseContext.Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
