import styled from "styled-components";

export const GlobalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: normal;

  /* padding-top: 4rem; */
  margin-top: 3.2rem;
  /* margin-bottom: 4rem; */
  background-color: #12304d;
  color: white;
  min-height: 300px;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* align-items: space-between; */
  justify-content: center;
  /* background: white; */
  /* background-image: url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%23d0d0d0' fill-opacity='0.06'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); */

  /* background-size: 100vw; */
  /* background-position: bottom; */
  line-height: normal;
`;

export const GreenBlock = styled(Block)`
  margin-top: 2px;
  background-color: #C8DF00;
  padding: 2.2rem 1rem;
  font-size: 22px;
  font-weight: 300;
  align-items: flex-end;

  & p {
    line-height: 2;
    text-align: left;
  }

  & b {
    font-weight: 400;
  }
`;



export const BlockWithPicture = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Block2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* align-items: space-between; */
  justify-content: center;
  /* background: white; */
  /* background-image: url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%23d0d0d0' fill-opacity='0.06'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); */

  /* background-size: 100vw; */
  /* background-position: bottom; */
  line-height: normal;
  background: #c8d9e8;
`;

export const Banner = styled.div`
  flex: 1;
  color: #11304d;
  text-align: center;
`;

export const BlockImage = styled.div`
  width: 100%;
  backdrop-filter: blur(2px);
`;

export const Header = styled.div`
  font-size: 2rem;
  font-weight: 100;
  margin-bottom: 1rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

export const SubHeader = styled.div`
  font-size: 1.4rem;
  font-weight: 300;
  text-align: center;
  line-height: 1.5;

  color: rgba(0, 0, 0, 0.7);
`;

export const Paragraph = styled.p`
  font-size: 1.6rem;
  font-weight: 300;
  text-align: center;
`;

export const Image = styled.img`
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px 2px, rgba(0, 0, 0, 0.2) 0px 0px 2px; */
  /* width: 100%; */
`;

export const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;

  & > span:first-child {
    font-size: 8rem;
  }

  & > div {
    margin-top: 2rem;
    margin-bottom: 5rem;
    font-size: 22px;
    line-height: 2;
  }
`;

export const CenteredBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > span:first-child {
    font-size: 8rem;
  }

  & > p {
    font-size: 26px;
    line-height: 2;
    font-weight: 300;
    text-align: center;
  }
`;

export const CircledGroup = styled.div`
  display: flex;
  margin: 4rem 0;
  width: 100%;
  justify-content: space-between;
`;


export const Circle = styled.div`
  text-align: center;

  & > span {
    border-radius: 50%;
    background: #11304D;
    color: white;
    padding: 2.2rem 2rem 2rem 2rem;
    font-size: 2.5rem;
    text-align: center;
  }

  & > h5 {
    font-size: 22px;
    line-height: 1.6;
    font-weight: 300;
    width: 250px;
  }

  &:not(:last-child) {
      margin-right: 1rem;
    }
`;


export const GreenGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  padding: 0 2rem;
`;
export const GreenIcons = styled.div`
  font-size: 90px;
  color: #12304d;

  & > span:not(:last-child) {
    margin-right: 3rem;
  }
  
  & > span:nth-child(2) {
    opacity: 0.8;
  }
  & > span:nth-child(3) {
    opacity: 0.6;
  }
  & > span:nth-child(4) {
    opacity: 0.4;
  }
`;
