import React, { useState } from "react";
import TimeAgo from "react-timeago";
import { useParams, Link, Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import { isEmpty } from "lodash";

import { useFirebase } from "../../../../Firebase";
import { useFirestoreDoc, useFirestoreQuery } from "../../../../../utils/firebaseHooks";
import { useAuth } from "../../../../Session";

import { EXERCISE_TYPE, ASSIGNMENT_ICON } from "../../../../../constants/icons";

import { Placeholder, Segment, Message, Header, Menu, Icon, Button, Form } from "semantic-ui-react";
import Textarea from "react-textarea-autosize";
import { DisplayExercise } from "../../../../UI/Exercise";
import UploadZone from "../../../../UI/UploadZone";
import { CompactAssignmentList } from "../../../../UI/AssignmentList";
import { Submission } from "../../../../UI/Submission/Submission";

import AsyncButton from "../../../../UI/AsyncButton";

import * as dataModel from "../../../../../services/dataModel";
import { UserMessagesList } from "../../../../Common/UserMessagesList";

export default function AssignmentDetailsPage({ assignment, exerciseId }) {
  const firebase = useFirebase();
  const authUser = useAuth();
  const userId = authUser.uid;
  const { course_id } = useParams();
  const { path } = useRouteMatch();

  const [exercise, isLoading] = useFirestoreDoc(firebase.exercise(course_id, exerciseId));
  const [submissions] = useFirestoreQuery(
    firebase.assignmentSubmissions(userId, exerciseId).orderBy("submittedAt", "desc")
  );

  const [messages] = useFirestoreQuery(
    firebase.messagesOfStudentInExercise(exerciseId, course_id, userId).orderBy("timestamp", "desc")
  );

  return (
    <>
      {/* Page Header */}
      <Header as="h2">
        {/* <Button.Group size="tiny" floated="right"> */}
        {/* <Button color="violet" size="tiny" floated="right">
          <Icon name="send" /> Submit Answer
        </Button> */}
        <Button
          color="purple"
          size="tiny"
          floated="right"
          as={Link}
          to={`/user/courses/${course_id}/${exerciseId}/ask`}>
          <Icon name="comment outline" /> Ask Question
        </Button>
        {/* </Button.Group> */}
        <Icon name={EXERCISE_TYPE[assignment.type].icon} />

        <Header.Content>
          {assignment.exerciseTitle}
          <Header.Subheader>
            <small>
              {ASSIGNMENT_ICON[assignment.status].title}{" "}
              {<TimeAgo date={assignment.assignedAt.toDate()} minPeriod={59} />}
            </small>
          </Header.Subheader>
        </Header.Content>
      </Header>

      {/* Assignment Sub-Menu */}
      <Route path={[`${path}/:option`, `${path}`]}>
        <SubMenu
          course_id={course_id}
          exerciseId={exerciseId}
          messagesCount={isEmpty(messages) ? null : messages.length}
          submissionsCount={isEmpty(submissions) ? null : submissions.length}
          hideReview={assignment.type === "document"}
        />
      </Route>

      {/* Page Content */}
      {isLoading || !exercise ? (
        <Segment>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        </Segment>
      ) : (
        <Switch>
          {/* Assignment Description */}
          <Route exact path={[`${path}`]}>
            <DescriptionPage exercise={exercise} assignment={assignment} submissions={submissions} isEmpty={isEmpty} />
          </Route>

          {/* Assignment Messages */}
          <Route path={[`${path}/messages`]}>
            <UserMessagesList messages={messages} />
          </Route>

          {/* Assignment New Message */}
          <Route path={[`${path}/ask`]}>
            <NewMessageForm assignment={assignment} />
          </Route>

          {/* Assignment Submissions History */}
          <Route path={[`${path}/history`]}>
            {submissions && !isEmpty(submissions) ? (
              submissions.map(submission => <Submission key={submission._id} submission={submission} />)
            ) : (
              <Message info>Nothing have been submitted yet</Message>
            )}
          </Route>
        </Switch>
      )}
    </>
  );
}

export function AssignmentDetailsPageMenu({ course, assignments, currentExerciseId }) {
  const { course_id } = useParams();

  return (
    <>
      <Button as={Link} to={`/user/courses/${course_id}`}>
        <Icon name="chevron left" />
        Back
      </Button>
      <CompactAssignmentList selectedId={currentExerciseId} course={course} assignments={assignments} />
    </>
  );
}

function SubmissionArea({ assignment }) {
  const firebase = useFirebase();
  const basePath = `assignments/${assignment.userId}/${assignment.courseId}/${assignment.exerciseId}/${assignment.versions}`;

  const [tempSubmissions] = useFirestoreDoc(
    firebase.assignmentTempSubmission(assignment.userId, assignment.exerciseId)
  );

  /* File deleted  */
  async function handleFileDeleted(fileName) {
    let attachments = { ...tempSubmissions.attachments };
    delete attachments[fileName];

    await tempSubmissions._ref.update({
      attachments
    });
  }

  /* File uploaded successfully */
  async function handleFileUploaded(fileName, metadata) {
    let attachments = {
      ...tempSubmissions.attachments,
      [fileName]: metadata
    };

    await tempSubmissions._ref.set({
      attachments
    });
  }

  /* On submit */
  async function handleAssignmentSubmit(currentFiles) {
    const userId = assignment.userId;
    const exerciseId = assignment.exerciseId;
    const courseId = assignment.courseId;

    // console.log(currentFiles);

    // 1. Save Submission
    await firebase.assignmentSubmissions(userId, exerciseId).add({
      version: assignment.versions,
      submittedAt: firebase.fieldValue.serverTimestamp(),
      attachments: currentFiles,
      state: "SUBMITTED",
      grade: null,
      gradeComments: null,
      courseId: courseId
    });

    // 2. Empty tempAssignment
    await tempSubmissions._ref.set({
      attachments: {}
    });
  }

  return (
    <UploadZone
      basePath={basePath}
      currentFiles={(tempSubmissions && tempSubmissions.attachments) || {}}
      onFileDeleted={handleFileDeleted}
      onFileUploaded={handleFileUploaded}
      onSubmit={handleAssignmentSubmit}
      maxFileSize={10}
      // minAmountOfFiles={2}
    />
  );
}

function SubMenu({ course_id, exerciseId, submissionsCount, messagesCount, hideReview }) {
  const { option } = useParams();

  return (
    <Menu pointing secondary>
      <Menu.Item
        name="Description"
        icon="info circle"
        active={isEmpty(option)}
        as={Link}
        to={`/user/courses/${course_id}/${exerciseId}`}
      />
      <Menu.Item
        // icon="comments"
        // name="Messages"
        as={Link}
        to={`/user/courses/${course_id}/${exerciseId}/messages`}
        active={option === "messages"}>
        <Icon name="comments" /> Messages {messagesCount && `(${messagesCount})`}
        {/* {messagesCount && <Label floating>{messagesCount}</Label>} */}
      </Menu.Item>
      {!hideReview && (
        <Menu.Menu position="right">
          <Menu.Item active={option === "history"} as={Link} to={`/user/courses/${course_id}/${exerciseId}/history`}>
            <Icon name="history" /> Review History {submissionsCount && submissionsCount > 1 && `(${submissionsCount})`}
          </Menu.Item>
        </Menu.Menu>
      )}
    </Menu>
  );
}

function DescriptionPage({ exercise, assignment, submissions, isEmpty }) {
  return (
    <>
      {/* Render Exercise */}
      <Segment secondary>
        <DisplayExercise exercise={exercise} />
      </Segment>

      {/* Submission Form (if required) */}
      {exercise.type !== "document" && ["ASSIGNED", "REDO"].includes(assignment.status) && (
        <React.Fragment>
          <Header as="h3" dividing>
            Submit Answer
          </Header>
          <SubmissionArea assignment={assignment} />
        </React.Fragment>
      )}

      {/* Render Last Submissions */}
      {submissions && !isEmpty(submissions) && <Submission key={submissions[0]._id} submission={submissions[0]} />}
      {/* {submissions && submissions.map(submission => <Submission key={submission._id} submission={submission} />)} */}
    </>
  );
}

function NewMessageForm({ assignment }) {
  const firebase = useFirebase();
  const authUser = useAuth();
  const history = useHistory();
  const [content, setContent] = useState("");

  async function sendMessage() {
    const message = dataModel.newMessage(assignment, content, authUser.name, firebase.fieldValue.serverTimestamp());
    await firebase.messages().add(message);
    history.push(`/user/courses/${assignment.courseId}/${assignment.exerciseId}/messages`);
  }

  return (
    <Form>
      <Segment color="purple">
        <Header
          size="small"
          dividing
          style={{
            fontWeight: 600
          }}>
          Ask Question
        </Header>
        <Textarea
          value={content}
          onChange={event => setContent(event.target.value)}
          placeholder="I have a problem with..."
          className="clear-textarea"
          autoFocus
        />

        <div
          style={{
            textAlign: "right"
          }}>
          <AsyncButton
            color="purple"
            onClick={sendMessage}
            style={{
              marginTop: "1em"
            }}>
            <Icon name="send" /> Send
          </AsyncButton>
        </div>
      </Segment>
    </Form>
  );
}
