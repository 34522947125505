import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Styled from "./index.styles";

import { ColoredLogo } from "../../App/Logo";
import { Container, Button, Icon } from "semantic-ui-react";
import AssignmentImage from "./assignment.png";
import FeedbackImage from "./feedback.png";
import MatrixImage from "./matrix.png";
import Msg1Image from "./msg1.png";
import Msg2Image from "./msg2.png";
import Msg3Image from "./msg3.png";

import MarkdownImg from "./markdown.png";
import EasySubmissionsImg from "./submissions.png";
import LeftFeedbackImg from "./LeftFeedback.png";
import RightFeedbackImg from "./RightFeedback.png";

import GoodAnswerImg from "./GoodAnswer.png";
import BadAnswerImg from "./BadAnswer.png";

import RecentMessagesImg from "./RecentMessages.png";
import ChildrenImg from "./Children.png";

export default function Landing() {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Styled.GlobalHeader>
        <Styled.Header style={{ fontSize: "3rem", marginBottom: "2rem" }}>
          <ColoredLogo />
        </Styled.Header>
        <Styled.Header>
          We deliver smart functions to
          <br />
          enrich learning experiences.
        </Styled.Header>
      </Styled.GlobalHeader>

      {/* Features */}
      <Styled.Block green>
        <Container>
          <Styled.BlockHeader>
            <span className="fad fa-tasks-alt" />
            <h2>Course Management</h2>
            <h3>Synchronized</h3>
          </Styled.BlockHeader>
        </Container>
      </Styled.Block>
      <Styled.Block>
        <Container>
          <Styled.Columns>
            <Styled.Column>
              <h3>Markdown Support</h3>
              <p>Write clear richly-formatted assignment instructions with our built-in Markdown editor.</p>
            </Styled.Column>

            <Styled.Column center>
              <img srcset={`${MarkdownImg} 2x`} alt="Markdown support" />
            </Styled.Column>

            <Styled.Column bottom>
              <h3>Attach files</h3>
              <p>Add multiple attachments to each assignment.</p>
            </Styled.Column>
          </Styled.Columns>

          <Styled.Columns>
            <Styled.Column vcenter>
              <h3>Easy submission for students</h3>
              <p>Instantly submit completed assignments and attached files.</p>
            </Styled.Column>

            <Styled.Column center>
              <img srcset={`${EasySubmissionsImg} 2x`} alt="Easy submissions" />
            </Styled.Column>

            <Styled.Column />
          </Styled.Columns>

          <Styled.Columns>
            <Styled.Column center>
              <h3>Fast feedback</h3>
              <p>
                Swiftly review submitted work
                <br />
                and deliver immediate feedback.
              </p>
            </Styled.Column>
          </Styled.Columns>
          <Styled.Columns>
            <Styled.Column center>
              <img srcset={`${LeftFeedbackImg} 2x`} alt="Good feedback" />
            </Styled.Column>
            <Styled.Column center>
              <img srcset={`${RightFeedbackImg} 2x`} alt="Negative feedback" />
            </Styled.Column>
          </Styled.Columns>
        </Container>
      </Styled.Block>

      {/* Review and feedback */}
      <Styled.Block orange>
        <Container>
          <Styled.BlockHeader>
            <span className="fad fa-tasks" />
            <h2>Review and feedback</h2>
            <h3>Simplified</h3>
          </Styled.BlockHeader>

          <Styled.Columns>
            <Styled.Column vcenter>
              <h3>Quick grading</h3>
              <p>Save time and deliver feedback in a single click.</p>
              <br />
              <br />
              <h3>Formative Feedback</h3>
              <p>Deliver immediate feedback to students in tutorial sessions and enable quick-fix iterations.</p>
              <br />
              <br />
              <h3>Submissions history</h3>
              <p>Save and archive submitted assignments for future reference.</p>
            </Styled.Column>

            <Styled.Column center>
              <img style={{ marginBottom: "4rem" }} srcset={`${GoodAnswerImg} 2x`} alt="Assignment is done" />
              <img srcset={`${BadAnswerImg} 2x`} alt="Resubmit assignment" />
            </Styled.Column>
          </Styled.Columns>
        </Container>
      </Styled.Block>

      {/* Track progress */}
      <Styled.Block>
        <Container>
          <Styled.BlockHeader style={{ marginBottom: "0" }}>
            <span className="fad fa-tachometer-alt-fastest" />
            <h2>Track Student Progress</h2>
            <h3>Live</h3>
          </Styled.BlockHeader>

          <Styled.Columns>
            <Styled.Column center>
              <img srcset={`${MatrixImage} 2x`} alt="Progress matrix" />
            </Styled.Column>
          </Styled.Columns>

          <Styled.Columns>
            <Styled.Column>
              <h3>Real-time progress matrix</h3>
              <p>View a live overview detailing the progress of individual students.</p>
            </Styled.Column>
            <Styled.Column>
              <h3>Differentiated learning</h3>
              <p>Customize content for each student based on individual progress and specific learning needs.</p>
            </Styled.Column>
            <Styled.Column>
              <h3>Leave no one behind</h3>
              <p>
                Quickly establish which students are struggling, and make interventions to offer support and accelerate
                progress.
              </p>
            </Styled.Column>
          </Styled.Columns>
        </Container>
      </Styled.Block>

      {/* Collaborate */}
      <Styled.Block lightgreen>
        <Container>
          <Styled.BlockHeader style={{ marginBottom: "0" }}>
            <span className="fad fa-handshake-alt" />
            <h2>Connect and Collaborate</h2>
            <h3>in Real Time</h3>
          </Styled.BlockHeader>

          <Styled.Columns>
            <Styled.Column center>
              <img srcset={`${ChildrenImg} 2x`} alt="Kids collaborate" />
            </Styled.Column>
          </Styled.Columns>

          <Styled.Columns>
            <Styled.Column vcenter>
              <h3>Built-in messaging</h3>
              <p>Communicate with students in-class or remotely.</p>
              <br />
              <br />
              <h3>Classroom-friendly flexibility</h3>
              <p>
                Reply to student messages online or in person.<br/> Messaging works seamlessly in team-teaching contexts.
              </p>
              <br />
              <br />
              <h3>Allocate support fairly</h3>
              <p>Student messages are sequenced within a fair queuing system.</p>
            </Styled.Column>

            <Styled.Column center>
              <img srcset={`${RecentMessagesImg} 2x`} alt="Recent Messages" />
            </Styled.Column>
          </Styled.Columns>
        </Container>
      </Styled.Block>

      <Styled.Block green>
        <Container>
          <Styled.Column center large>
            <p>
              Discover how Synclass can help you deliver
              <br /> a better solution for your bootcamp or course
            </p>
            <h3>Sign-up for free and see for yourself</h3>
            <Styled.Button style={{ width: "200px" }} onClick={() => history.push("/signup")}>
              Get Started
            </Styled.Button>
          </Styled.Column>
        </Container>
      </Styled.Block>
    </>
  );
}
