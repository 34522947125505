import React from "react";
import { List } from "semantic-ui-react";
import { getFileIcon } from "./";

export default function AttachedFiles({ attachments }) {
  const items = Object.keys(attachments).map(fileName => (
    <List.Item key={fileName}>
      <List.Content>
        <List.Icon name={getFileIcon(attachments[fileName].contentType)} />
        <a href={attachments[fileName].url}>{fileName}</a>
      </List.Content>
    </List.Item>
  ));

  return <List>{items}</List>;
}
