import React, { useMemo } from "react";
import { sortBy, mapValues, keyBy } from "lodash";
import { Link } from "react-router-dom";
import { Icon, Table, Label } from "semantic-ui-react";

import { ASSIGNMENT_ICON } from "../../constants/icons";
import { EXERCISE_TYPE } from "../../constants/icons";

function isNew(timestamp) {
  const now = new Date();
  const delta = now - timestamp.toDate();

  if (delta < 1000 * 60 * 60 * 24 * 5) {
    return true;
  }

  return false;
}

function presentAssignments(course, assignments) {
  const assignmentsByExercise = keyBy(assignments, "exerciseId");
  const filteredGroups = mapValues(course.exerciseOrder, exerciseList =>
    exerciseList.filter(exerciseId => exerciseId in assignmentsByExercise)
  );

  // using course.groups to maintain original order
  const presentGroupNames = course.groups
    .filter(groupName => groupName in filteredGroups && filteredGroups[groupName].length > 0)
    .reduce((result, groupName, index) => {
      result[groupName] = filteredGroups[groupName].map(exerciseId => assignmentsByExercise[exerciseId]);
      return result;
    }, {});

  return presentGroupNames;
}

export function AssignmentList({ course, assignments }) {
  const groupedAssignments = useMemo(() => presentAssignments(course, assignments), [course, assignments]);
  return Object.keys(groupedAssignments).map(groupName => (
    <GroupAssignments key={groupName} name={groupName} assignments={groupedAssignments[groupName]} />
  ));
}

function GroupAssignments({ name, assignments }) {
  assignments = sortBy(assignments, "assignedAt");

  return (
    <Table unstackable selectable singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={3}>{name}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {assignments.map((assignment, index) => (
          <Table.Row key={index}>
            {/* Status Icon */}
            <Table.Cell collapsing style={{ backgroundColor: "#f9fafb" }}>
              <Icon name={ASSIGNMENT_ICON[assignment.status].icon} color={ASSIGNMENT_ICON[assignment.status].color} />
            </Table.Cell>

            {/* Exercise Name */}
            <Table.Cell>
              <Link to={location => `${location.pathname}/${assignment.exerciseId}`}>
                <Icon name={EXERCISE_TYPE[assignment.type].icon} /> {assignment.exerciseTitle}
              </Link>
            </Table.Cell>

            {/* Status / Ribbon */}
            {isNew(assignment.assignedAt) && assignment.status === "ASSIGNED" ? (
              <Table.Cell collapsing>
                <Label color="green" ribbon="right">
                  New
                </Label>
              </Table.Cell>
            ) : (
              <Table.Cell collapsing textAlign="right">
                {ASSIGNMENT_ICON[assignment.status].title}
              </Table.Cell>
            )}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

export function CompactAssignmentList({ course, assignments, selectedId }) {
  const groupedAssignments = useMemo(() => presentAssignments(course, assignments), [course, assignments]);

  return Object.keys(groupedAssignments).map(groupName => (
    <CompactGroupAssignments
      key={groupName}
      name={groupName}
      assignments={groupedAssignments[groupName]}
      selectedId={selectedId}
    />
  ));
}

function CompactGroupAssignments({ name, assignments, selectedId }) {
  assignments = sortBy(assignments, "assignedAt");

  return (
    <Table basic="very" unstackable selectable size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={2}>{name}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {assignments.map((assignment, index) => (
          <Table.Row key={index} active={assignment._id === selectedId}>
            <Table.Cell style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              <Link to={`/user/courses/${assignment.courseId}/${assignment.exerciseId}`}>
                <Icon name={EXERCISE_TYPE[assignment.type].icon} /> {assignment.exerciseTitle}
              </Link>
            </Table.Cell>
            <Table.Cell collapsing>
              <Icon name={ASSIGNMENT_ICON[assignment.status].icon} color={ASSIGNMENT_ICON[assignment.status].color} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
