import React from "react";
import { useParams } from "react-router-dom";

import { useFirebase } from "../../../../Firebase";
import { useAuth } from "../../../../Session";

import { useFirestoreQuery } from "../../../../../utils/firebaseHooks";
import { Placeholder, Icon, Header, Message } from "semantic-ui-react";

import { UserMessagesList } from "../../../../Common/UserMessagesList";
import { CourseMenu } from "../CourseMenu";

export default function MessagesPage() {
  const firebase = useFirebase();
  const authUser = useAuth();
  const userId = authUser.uid;
  const { course_id } = useParams();

  const [messages, isLoading] = useFirestoreQuery(
    firebase.messagesOfStudentInCourse(course_id, userId).orderBy("timestamp", "desc")
  );

  let body = (
    <Placeholder>
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder>
  );

  if (!isLoading) {
    body = <UserMessagesList messages={messages} />;
  }

  return (
    <>
      <Header as="h2" color="purple">
        <Icon name="comments" />
        <Header.Content>
          Messages
          <Header.Subheader>See all messages recieved for this course</Header.Subheader>
        </Header.Content>
      </Header>

      {body}
    </>
  );
}

export function MessagesPageMenu() {
  return (
    <React.Fragment>
      <CourseMenu activeItem="messages" />
      <Message
        info
        icon="info"
        header="Asking new questions"
        content="You can ask new questions only through the assignment page"
      />
    </React.Fragment>
  );
}
