import React from "react";

import { useFirebase } from "../../../Firebase";
import { useFirestoreQuery } from "../../../../utils/firebaseHooks";

import { Segment } from "semantic-ui-react";
import AdminAssignmentList from "../../../UI/AdminAssignmentList";

export default function AssignmentsPage({ course, studentId }) {
  const firebase = useFirebase();

  const [exercises, isLoading] = useFirestoreQuery(firebase.exercises(course._id));
  const [assignments, isLoadingAssignments] = useFirestoreQuery(firebase.assignmentsOfCourse(studentId, course._id));

  if (isLoading || isLoadingAssignments) {
    return <div>Loading</div>;
  }

  return <AdminAssignmentList userId={studentId} course={course} exercises={exercises} assignments={assignments} />;
}
