import React from "react";

import MatrixPage from "../Matrix";
import StudentListPage from "../StudentListPage";
import CourseContentPage from "../CourseContentPage";

import UpcomingReviewPage from "../UpcomingReview";
import AnnouncementsPage from "../Announcements";
import MessagesPage from "../Messages";
import InviteStudentsPage from "../StudentInvitePage";
import EditCoursePage from "../CreateCoursePage/EditCoursePage";
import ExerciseIndex from "../ExerciseIndex";

import { Route, Switch } from "react-router-dom";

import * as ROUTES from "../../../../constants/routes";

export default function RouteSelector({ course, current, submittedAssignments }) {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.MANAGE_DASHBOARD}
        render={() => <CourseContentPage course={course} current={current} />}
      />
      <Route exact path={ROUTES.MANAGE_ANNOUNCEMENTS} render={() => <AnnouncementsPage course={course} />} />
      <Route
        exact
        path={ROUTES.MANAGE_UPCOMING}
        render={() => <UpcomingReviewPage course={course} submittedAssignments={submittedAssignments} />}
      />
      <Route exact path={ROUTES.MANAGE_INVITE} render={() => <InviteStudentsPage course={course} />} />
      <Route path={ROUTES.MANAGE_STUDENT_LIST} render={() => <StudentListPage course={course} current={current} />} />
      <Route exact path={ROUTES.MANAGE_MESSAGES} render={() => <MessagesPage course={course} />} />
      <Route exact path={ROUTES.MANAGE_MATRIX} render={() => <MatrixPage course={course} current={current} />} />
      <Route exact path={ROUTES.MANAGE_EDIT} render={() => <EditCoursePage course={course} />} />
      <Route path={ROUTES.MANAGE_EXERCISE} render={() => <ExerciseIndex course={course} current={current} />} />
    </Switch>
  );
}
