import React from "react";
import { useFirebase } from "../../../../Firebase";
import { useFirestoreQuery } from "../../../../../utils/firebaseHooks";

import { Icon, Header, Message } from "semantic-ui-react";
import { AnnouncementList } from "../../../../Common/Announcement";

export default function AnnouncementPage({ course }) {
  const firebase = useFirebase();
  const [announcements, isLoading] = useFirestoreQuery(firebase.announcements(course._id).orderBy("createdAt", "desc"));

  return (
    <>
      <Header as="h2" color="purple">
        <Icon name="announcement" />
        <Header.Content>
          Announcements
          <Header.Subheader>Latest course updates</Header.Subheader>
        </Header.Content>
      </Header>

      {isLoading && <Message>Loading</Message>}

      {!isLoading && <AnnouncementList announcements={announcements} />}

      {!isLoading && announcements.length === 0 && <Message info>No announcements yet</Message>}
    </>
  );
}
