import React from "react";

import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: JSON.parse(localStorage.getItem("authUser")),
        claims: {}
      };

      this.handleTokenRefresh = this.handleTokenRefresh.bind(this);
    }

    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        authUser => {
          localStorage.setItem("authUser", JSON.stringify(authUser));
          this.setState({ authUser });
          this.handleTokenRefresh(authUser.uid);

          window.pendo.initialize({
            visitor: {
                id:              authUser.uid,
                email:           authUser.email,
                full_name:    authUser.name
            },
        
            account: {
                id:           authUser.uid,
                // name:         // Optional
                // is_paying:    // Recommended if using Pendo Feedback
                // monthly_value:// Recommended if using Pendo Feedback
                // planLevel:    // Optional
                // planPrice:    // Optional
                // creationDate: // Optional
        
                // You can add any additional account level key-values here,
                // as long as it's not one of the above reserved names.
            }
        });
        
        },
        () => {
          localStorage.removeItem("authUser");
          this.setState({ authUser: null });
        }
      );
    }

    handleTokenRefresh(uid) {
      this.unsubscribe = this.props.firebase.user(uid).onSnapshot(doc => {
        /* Every time user data is updated - update custom claims */
        this.props.firebase.auth.currentUser.getIdTokenResult(true).then(idTokenResult => {
          let newAuthUser = null;

          if (null != this.state.authUser) {
            // newAuthUser = { ...doc.data() };
            newAuthUser = { ...this.state.authUser, ...doc.data() };
            newAuthUser.claims = idTokenResult.claims;
          }

          this.setState({ authUser: newAuthUser });
        });
      });
    }

    componentWillUnmount() {
      this.listener();
      this.unsubscribe();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
