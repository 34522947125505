import styled, { css } from "styled-components";
import React from "react";
import devices from "../../constants/devices";

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

Form.Button = styled.button`
  padding: 14px 2.5rem;
  margin: 1rem 0 4px 0rem;
  /* border: 2px #11304d solid; */
  /* color: #11304d; */
  border: 0;
  background: #11304d;
  color: white;
  text-transform: uppercase;
  font-size: 1rem;
  white-space: nowrap;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }

  &:focus {
    outline: 2px #ffc107 solid;
    /* outline: 2px #ffc107 solid; */
    box-shadow: 0 0 5px #ffc107;
  }
`;

const Input = styled.input`
  padding: 10px 10px;
  margin: 4px 0;
  border: 1px #11304d solid;
  color: #11304d;
  width: 100%;

  flex-grow: 1;

  &:focus {
    outline: none;
    /* outline: 2px #ffc107 solid; */
    border-color: #ffc107;
    box-shadow: 0 0 5px #ffc107;
  }
`;

const Label = styled.div`
  padding: 12px 0 0px 0;
  font-size: 0.9rem;
  /* font-weight: bold; */
  text-transform: uppercase;

  /* margin: 4px 0; */
  /* width: 76%; */

  flex-grow: 1;
`;
Form.Label = Label;

Form.Input = function({ label, ...props }) {
  if (label) {
    return (
      <div>
        <Label>{label}</Label>
        <Input {...props} />
      </div>
    );
  } else return <Input {...props} />;
};

const Checkbox = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0 4px 0;
  cursor: pointer;

  & > span {
    margin-right: 0.5rem;
  }
  & > div {
    padding: 0;
    text-transform: none;
  }

  &:focus {
    outline: none;
    & > span {
      /* box-shadow: 0 0 5px #ffc107; */
      color: #ffc107;
    }
  }
`;

Form.Checkbox = function({ label, onChange, checked = false, ...props }) {
  const icon = checked ? "far fa-check-square" : "far fa-square";
  function handleKeyDown(event) {
    event.preventDefault();
    if (event.key === " ") {
      onChange(!checked);
    }
  }

  return (
    <Checkbox tabIndex="0" onClick={() => onChange(!checked)} onKeyPress={handleKeyDown}>
      <span className={icon} />
      <Label>{label}</Label>
    </Checkbox>
  );
};

Form.Group = styled.div`
  display: flex;

  & > div {
    flex: 1;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  /* Mobile */
  @media ${devices.mobileL} {
    flex-direction: column;

    & > div {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
`;

Form.Paragraph = styled.p`
  text-align: left;
  padding: 1rem 0;
  margin: 0;
`;

Form.Error = styled.p`
  text-align: center;
  color: #941616;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export default Form;
