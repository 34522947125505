import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { useFirebase } from "../Firebase";
import { useFirestoreQuery } from "../../utils/firebaseHooks";

import TimeAgo from "react-timeago";

import { Icon, Feed, Placeholder, Message } from "semantic-ui-react";

export function RecentActivity({ user, asUser = true }) {
  const firebase = useFirebase();

  const [docs, isLoading] = useFirestoreQuery(
    firebase
      .assignments(user._id)
      .where("userId", "==", user._id)
      .orderBy("lastUpdate", "desc")
      .limit(8)
  );

  function courseName(courseId) {
    return courseId in user.enrolled ? user.enrolled[courseId] : "-";
  }

  const docsWithCourseName = useMemo(
    () => docs && docs.map(doc => ({ ...doc, courseName: courseName(doc.courseId) })),
    [docs]
  );

  return <ActivityFeed isLoading={isLoading} docs={docsWithCourseName} asUser={asUser} />;
}

export function RecentCourseActivity({ userId, courseId, asUser = true, limit = 8 }) {
  const firebase = useFirebase();

  const [docs, isLoading] = useFirestoreQuery(
    firebase
      .assignments(userId)
      .where("courseId", "==", courseId)
      .orderBy("lastUpdate", "desc")
      .limit(limit)
  );

  return <ActivityFeed isLoading={isLoading} docs={docs} asUser={asUser} />;
}

function ActivityFeed({ docs, isLoading, asUser }) {
  if (isLoading) {
    return (
      <Placeholder>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder>
    );
  }

  if (_.isEmpty(docs)) {
    return <Message>No activities yet</Message>;
  }

  return (
    <Feed>
      {docs.map(doc => (
        <Feed.Event
          key={doc._id}
          style={{
            marginBottom: "1em"
          }}>
          <Feed.Content>
            <Feed.Date>
              <TimeAgo date={doc.lastUpdate.toDate()} />
            </Feed.Date>
            <Feed.Summary>{describeActivity(doc, asUser)}</Feed.Summary>
            {doc.courseName && (
              <Feed.Meta>
                <Feed.Like as={Link} to={asUser ? `/user/courses/${doc.courseId}` : `/manage/${doc.courseId}`}>
                  <Icon name="book" />
                  {doc.courseName}
                </Feed.Like>
              </Feed.Meta>
            )}
          </Feed.Content>
        </Feed.Event>
      ))}
    </Feed>
  );
}

function describeActivity(activity, asUser) {
  const base = asUser
    ? `/user/courses/${activity.courseId}/${activity.exerciseId}`
    : `/manage/${activity.courseId}/${activity.exerciseId}/assignments/${activity.userId}`;

  const exercise = <Link to={base}>{activity.exerciseTitle}</Link>;

  if (activity.status === "ASSIGNED") {
    return asUser ? <span>Exercise {exercise} is now open</span> : <span>Exercise {exercise} was assigned</span>;
  }

  if (activity.status === "SUBMITTED") {
    return asUser ? <span>You have submitted {exercise}</span> : <span>{exercise} was submitted</span>;
  }

  if (activity.status === "IN_REVIEW") {
    return <span>Exercise {exercise} is being reviewed</span>;
  }
  if (activity.status === "REDO") {
    return <span>Redo required for {exercise}</span>;
  }
  if (activity.status === "DONE") {
    return <span>{exercise} is Completed</span>;
  }

  return activity.status;
}
