import React from "react";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";

import { Icon, Segment, Menu } from "semantic-ui-react";
import { isEmpty } from "lodash";

export default function UserMessage({ message, onResolveClick }) {
  const color = message.isResolved ? "green" : "orange";

  const body = (
    <Segment>
      <p style={{ whiteSpace: "pre" }}>{message.content}</p>
      <small>
        <b>
          <Icon name="user" /> {message.displayName}
        </b>{" "}
        | {message.timestamp && <TimeAgo date={message.timestamp.toDate()} minPeriod={5} />}
      </small>
    </Segment>
  );

  if (message.isResolved) {
    return (
      <Segment.Group>
        <Segment secondary color={color} style={{ paddingTop: "0.85em", paddingBottom: "0.85em" }}>
          <Icon name="envelope outline" />{" "}
          <Link to={`/user/courses/${message.courseId}/${message.exerciseId}`}>
            <b>{message.exerciseTitle}</b>
          </Link>
        </Segment>
        {body}
        {isEmpty(message.response) ? (
          message.responseAuthorId === message.userId ? (
            <></>
          ) : (
            <Segment>
              <small>
                <b>{message.responseAuthor}</b> |{" "}
                {message.responseTimestamp && <TimeAgo date={message.responseTimestamp.toDate()} minPeriod={5} />}
              </small>
            </Segment>
          )
        ) : (
          <Segment>
            <p style={{ whiteSpace: "pre" }}>{message.response}</p>
            <small>
              <b>{message.responseAuthor}</b> |{" "}
              {message.responseTimestamp && <TimeAgo date={message.responseTimestamp.toDate()} minPeriod={5} />}
            </small>
          </Segment>
        )}
      </Segment.Group>
    );
  } else {
    return (
      <>
        <Segment.Group>
          <Segment secondary color={color} style={{ paddingTop: "0.85em", paddingBottom: "0.85em" }}>
            <Icon name="envelope outline" />{" "}
            <Link to={`/user/courses/${message.courseId}/${message.exerciseId}`}>
              <b>{message.exerciseTitle}</b>
            </Link>
          </Segment>
          {body}
          <Menu inverted color={color} size="small" attached="bottom">
            <Menu.Item>
              <Icon name="circle notch" loading /> Waiting for answer
            </Menu.Item>
            <Menu.Item position="right" onClick={() => onResolveClick(message)}>
              <Icon name="check" /> Mark as resolved
            </Menu.Item>
          </Menu>
        </Segment.Group>
      </>
    );
  }
}
