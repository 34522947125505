import React from "react";

import { Link } from "react-router-dom";
import { Icon, Ref, Segment } from "semantic-ui-react";
import { Draggable } from "react-beautiful-dnd";
import { EXERCISE_TYPE } from "../../../../constants/icons";

import AsyncIconButton from "../../../UI/AsyncIconButton";
import * as StudentProgress from "../../../Common/StudentProgress";

export function ContentEntry({ entry, index, onDelete, stats }) {
  return (
    <Draggable draggableId={entry._id} index={index}>
      {(provided, snapshot) => (
        <Ref innerRef={provided.innerRef}>
          <Segment {...provided.draggableProps} className="no-padding">
            <div
              className={
                snapshot.isDragging
                  ? "course-content-list-item course-content-list-item_dragged"
                  : "course-content-list-item"
              }>
              <div {...provided.dragHandleProps}>
                <Icon className="course-content-list-item_move" name="move" fitted />
              </div>
              <div style={{ flex: 1 }}>
                <Link to={({ pathname }) => `${pathname}/${entry._id}`}>
                  <Icon name={EXERCISE_TYPE[entry.type].icon} /> {entry.title}
                </Link>
              </div>
              {stats && <StudentProgress.StudentProgress values={stats} />}
              <div>
                <Link to={({ pathname }) => `${pathname}/${entry._id}/preferences`} className="linkify">
                  <Icon name="edit" />
                </Link>
                <AsyncIconButton name="trash alternate" onClick={() => onDelete(entry)} />
              </div>
            </div>
          </Segment>
        </Ref>
      )}
    </Draggable>
  );
}
