import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import useIsMounted from "../../utils/isMounted";

export default function AsyncIconButton({ onClick, name, ...props }) {
  const [loading, setLoading] = useState(false);
  const isMounted = useIsMounted();

  if (onClick) {
    const newOnClick = async (event, data) => {
      setLoading(true);
      await onClick(event, data);
      if (isMounted()) {
        setLoading(false);
      }
    };

    if (loading) {
      return <Icon name="circle notch" loading {...props} />;
    }

    return <Icon className="linkify" name={name} onClick={newOnClick} {...props} />;
  }

  return <Icon name={name} {...props} />;
}
