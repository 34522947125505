import React from "react";
import { useParams, Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";

export function CourseMenu({ activeItem }) {
  const { course_id } = useParams();

  return (
    <Menu fluid vertical pointing>
      <Menu.Item
        active={activeItem === "announcements"}
        as={Link}
        to={`/user/courses/${course_id}/announcements`}
        icon="announcement"
        name="Announcements"
      />
      <Menu.Item
        active={activeItem === "assignments"}
        as={Link}
        to={`/user/courses/${course_id}`}
        icon="tasks"
        name="Assignments"
        color="teal"
      />
      <Menu.Item
        active={activeItem === "messages"}
        as={Link}
        to={`/user/courses/${course_id}/messages`}
        icon="comments"
        name="Messages"
        color="purple"
      />
    </Menu>
  );
}
