import React from "react";
import RichtextArea from "../../../../UI/RichtextArea";
import UploadZone from "../../../../UI/UploadZone";

import { Header } from "semantic-ui-react";

export default function EditPage({ course, current, exercise }) {
  const basePath = `courses/${course._id}/${exercise._id}`;

  async function saveInstructions(text) {
    await exercise._ref.update({ description: text });
  }

  async function handleFileDeleted(fileName) {
    let attachments = { ...exercise.attachments };
    delete attachments[fileName];
    await exercise._ref.update({ attachments });
  }

  async function handleFileUploaded(fileName, metadata) {
    let attachments = {
      ...exercise.attachments,
      [fileName]: metadata
    };
    await exercise._ref.update({ attachments });
  }

  return (
    <React.Fragment>
      <RichtextArea title="Instructions" onSave={saveInstructions} defaultValue={exercise.description} />

      <Header size="medium" dividing color="blue">
        Attached Files
      </Header>
      <UploadZone
        basePath={basePath}
        currentFiles={exercise.attachments}
        onFileDeleted={handleFileDeleted}
        onFileUploaded={handleFileUploaded}
        maxFileSize={10}
      />
    </React.Fragment>
  );
}
