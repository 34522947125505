import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import _ from "lodash";

import { useFirebase } from "../../../Firebase";
import { useFirestoreQuery } from "../../../../utils/firebaseHooks";

import * as ICONS from "../../../../constants/icons";

import { Table, Icon, Message } from "semantic-ui-react";

export function ReviewQueue({ course, submittedAssignments }) {
  const firebase = useFirebase();
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);

  const [enrolled, isLoadingStudents] = useFirestoreQuery(
    firebase.enrolledStudents(course._id).orderBy("userName", "asc")
  );

  const enrolledById = useMemo(() => _.keyBy(enrolled, "_id"), [enrolled]);

  const handleSort = clickedColumn => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setDirection("ascending");
      return;
    }

    setDirection(direction === "ascending" ? "descending" : "ascending");
  };

  const sorted = useMemo(
    () =>
      direction === "descending"
        ? _.sortBy(submittedAssignments, [column]).reverse()
        : _.sortBy(submittedAssignments, [column]),
    [submittedAssignments, direction, column]
  );

  if (isLoadingStudents) {
    return <Message icon={<Icon name="circle notched" loading />} content="Loading..." />;
  }

  if (sorted.length === 0) {
    return <Message positive content="Nothing to review" />;
  }

  return (
    <Table sortable celled fixed>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell sorted={column === "lastUpdate" ? direction : null} onClick={handleSort("lastUpdate")}>
            Submitted On
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "exerciseTitle" ? direction : null}
            onClick={handleSort("exerciseTitle")}>
            Exercise
          </Table.HeaderCell>
          <Table.HeaderCell sorted={column === "userId" ? direction : null} onClick={handleSort("userId")}>
            Student
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {sorted.map((doc, index) => (
          <Table.Row key={index}>
            <Table.Cell>
              <Link to={`/manage/${course._id}/${doc.exerciseId}/assignments/${doc.userId}`}>
                <Icon name={ICONS.ASSIGNMENT_ICON.SUBMITTED.icon} color={ICONS.ASSIGNMENT_ICON.SUBMITTED.color} />
                <b>Version {doc.versions - 1}</b> | <TimeAgo date={doc.lastUpdate.toDate()} minPeriod={5} />
              </Link>
            </Table.Cell>
            <Table.Cell>
              <Link to={`/manage/${course._id}/${doc.exerciseId}/assignments`}>{doc.exerciseTitle}</Link>
            </Table.Cell>
            <Table.Cell>
              <Link to={`/manage/${course._id}/students/${doc.userId}`}>
                {doc.userId in enrolledById && enrolledById[doc.userId].userName}
              </Link>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
