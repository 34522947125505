import React from "react";
import { Link, useParams } from "react-router-dom";
import { Menu } from "semantic-ui-react";

export function ExerciseMenu({ activeItem }) {
  const { course_id, exercise_id } = useParams();

  return (
    <Menu pointing vertical fluid>
      <Menu.Item
        icon="edit"
        name="Edit"
        active={activeItem === "edit"}
        as={Link}
        to={`/manage/${course_id}/${exercise_id}`}
      />

      <Menu.Item
        active={activeItem === "messages"}
        as={Link}
        to={`/manage/${course_id}/${exercise_id}/messages`}
        name="Messages"
        icon="comments"
        color="purple"
      />

      <Menu.Item
        icon="tasks"
        name="Student Assignments"
        active={activeItem === "assignments"}
        color="teal"
        as={Link}
        to={`/manage/${course_id}/${exercise_id}/assignments`}
      />

      <Menu.Item
        icon="cog"
        name="Preferences"
        active={activeItem === "preferences"}
        color="teal"
        as={Link}
        to={`/manage/${course_id}/${exercise_id}/preferences`}
      />
    </Menu>
  );
}
