import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import { withAuthorization, AuthUserContext } from "../../../Session";
import { withFirebase } from "../../../Firebase";

import * as ROLES from "../../../../constants/roles";
import * as Elements from "./elements";
import { ASSIGNMENTS } from "../../../../constants/icons";

import * as dataModel from "../../../../services/dataModel";
import { Confirm, Header, Divider, Breadcrumb, Message } from "semantic-ui-react";
import { ManagePageContainer } from "../../../UI/ManagePageContainer";

class MatrixPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enrolled: null,
      exercises: []
    };
  }

  componentDidMount() {
    const courseId = this.props.match.params.course_id;

    /* Load Enrolled Student List */
    this.unsubscribe1 = this.props.firebase
      .enrolledStudents(courseId)
      .orderBy("userName", "desc")
      .onSnapshot(snapshot => {
        let enrolled = [];
        snapshot.forEach(doc => {
          enrolled.push({
            ...doc.data(),
            userId: doc.id
          });
        });

        this.setState({
          enrolled
        });
      });

    /* Load All Exercise Names */
    this.unsubscribe2 = this.props.firebase.exercises(courseId).onSnapshot(querySnapshot => {
      let exercises = [];
      querySnapshot.forEach(doc => {
        exercises.push({
          ...doc.data(),
          id: doc.id,
          ref: doc.ref
        });
      });

      this.setState({
        exercises
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe1();
    this.unsubscribe2();
  }

  render() {
    const courseId = this.props.course._id;
    const { course } = this.props;
    const { current } = this.props;
    const { enrolled, exercises } = this.state;

    const breadcrumb = [
      // { key: "Home", content: <Icon name="home" /> },
      { key: "Course Content", content: course.name, as: Link, to: `/manage/${course._id}` },
      { key: "Progress Matrix", content: "Progress Matrix", active: true }
    ];

    const header = (
      <ManagePageContainer>
        <Header as="h1" icon="tasks" content="Progress Matrix" subheader="See global progress of all students" />
        <Breadcrumb icon="right angle" sections={breadcrumb} />
        <Divider />
      </ManagePageContainer>
    );

    // Check if loading
    if (exercises.length === 0 || current === null) {
      return (
        <ManagePageContainer>
          {header}

          <Message>Matrix will be available once a students are enrolled</Message>
        </ManagePageContainer>
      );
    }

    const exercisesDict = _.keyBy(exercises, "id");
    const groupedExercises = _.mapValues(course.exerciseOrder, exerciseList =>
      exerciseList.map(exerciseId => exercisesDict[exerciseId])
    );

    return (
      <div>
        {header}

        <Elements.MatrixContainer>
          <Matrix
            courseId={courseId}
            students={enrolled}
            groups={course.groups}
            groupedExercises={groupedExercises}
            current={current}
          />
        </Elements.MatrixContainer>
      </div>
    );
  }
}
MatrixPage.contextType = AuthUserContext;

class Matrix extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waiting: {}
    };

    this.assignExercise = this.assignExercise.bind(this);
    this.renderStudentRow = this.renderStudentRow.bind(this);
  }

  assignExercise(groupName, courseId, userId, exercise) {
    this.setWaiting(groupName, exercise.id, userId);
    exercise._id = exercise.id;
    const data = dataModel.newAssignment(courseId, userId, exercise, this.props.firebase.fieldValue.serverTimestamp());
    this.props.firebase.assignment(userId, exercise.id).set(data);
  }

  isWaiting(groupName, exerciseId, userId) {
    const { waiting } = this.state;
    if (groupName in waiting && exerciseId in waiting[groupName] && userId in waiting[groupName][exerciseId]) {
      return true;
    }

    return false;
  }

  setWaiting(groupName, exerciseId, userId) {
    var { waiting } = this.state;

    if (!(groupName in waiting)) {
      waiting[groupName] = {};
    }

    if (!(exerciseId in waiting[groupName])) {
      waiting[groupName][exerciseId] = {};
    }

    if (!(userId in waiting[groupName][exerciseId])) {
      waiting[groupName][exerciseId][userId] = true;
    }

    return this.setState({
      waiting
    });
  }

  doneWaiting(groupName, exerciseId, userId) {
    var { waiting } = this.state;

    if (groupName in waiting && exerciseId in waiting[groupName] && userId in waiting[groupName][exerciseId]) {
      delete waiting[groupName][exerciseId][userId];
    }

    return this.setState({
      waiting
    });
  }

  renderIcon(student, groupName, exercise, current) {
    /* If exercise is assigned */
    if (exercise.id in current && student.userId in current[exercise.id]) {
      //  /* If somehow still 'waiting' - remove waiting */
      //  if (this.isWaiting(groupName, exercise.id, student.userId)) {
      //   this.doneWaiting(groupName, exercise.id, student.userId);
      // }

      return (
        <Link to={`/manage/${this.props.courseId}/${exercise.id}/assignments/${student.userId}`}>
          <span
            className={ASSIGNMENTS[current[exercise.id][student.userId].s].icon}
            style={{ color: ASSIGNMENTS[current[exercise.id][student.userId].s].color }}
          />
        </Link>
      );
    } else {
      /* If exercise has no type */
      if (exercise.type === undefined) {
        return <></>;
      }

      /* If no status and waiting */
      if (this.isWaiting(groupName, exercise.id, student.userId)) {
        return <span className="fas fa-spinner fa-spin" />;
      }

      return (
        <span
          className="fas fa-plus"
          style={{ cursor: "pointer" }}
          onClick={() => this.assignExercise(groupName, this.props.courseId, student.userId, exercise)}
        />
      );
    }
  }

  renderStudentRow(student, groups, groupedExercises, current) {
    return (
      <tr key={student.userId}>
        <Elements.NameTd>{student.userName}</Elements.NameTd>
        {groups.map(
          (groupName, index) =>
            groupedExercises[groupName] &&
            groupedExercises[groupName].map(exercise => (
              <Elements.ExTd key={`${student.userId}_${exercise.id}`} style={{ backgroundColor: this.getColor(index) }}>
                {this.renderIcon(student, groupName, exercise, current)}
              </Elements.ExTd>
            ))
        )}
      </tr>
    );
  }

  getColor(index) {
    if (index % 2 === 0) {
      return "rgb(195, 232, 253)";
    } else {
      return "rgb(225, 244, 255)";
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // /* If somehow still 'waiting' - remove waiting */
    // if (this.isWaiting(groupName, exercise.id, student.userId)) {
    //   this.doneWaiting(groupName, exercise.id, student.userId);
    // }
  }

  render() {
    const { groupedExercises, groups, students, current } = this.props;

    if (groups && Object.keys(groupedExercises).length > 0) {
      const headerGroupNames = (
        <tr>
          <td />
          {groups.map(
            (groupName, index) =>
              groupedExercises[groupName] && (
                <Elements.ExTd
                  key={groupName}
                  colSpan={groupedExercises[groupName].length}
                  style={{ backgroundColor: this.getColor(index) }}>
                  {groupName}
                </Elements.ExTd>
              )
          )}
        </tr>
      );

      const headerOptions = (
        <tr>
          <td />
          {groups.map(
            (groupName, index) =>
              groupedExercises[groupName] &&
              groupedExercises[groupName].map(exercise => (
                // <Elements.ThRotated key={exercise.title} style={{ backgroundColor: this.getColor(index) }}>
                <Elements.ExTd key={exercise.id} style={{ backgroundColor: this.getColor(index) }}>
                  {exercise.type !== undefined && (
                    <AssignAllButton
                      firebase={this.props.firebase}
                      courseId={this.props.courseId}
                      students={students}
                      current={current}
                      exercise={exercise}
                    />
                  )}
                </Elements.ExTd>
              ))
          )}
        </tr>
      );

      const header3 = (
        <tr>
          <td />
          {groups.map(
            (groupName, index) =>
              groupedExercises[groupName] &&
              groupedExercises[groupName].map(exercise => (
                <Elements.ThRotated key={exercise.id}>
                  <div style={{ backgroundColor: this.getColor(index) }}>
                    <span>{exercise.title}</span>
                  </div>
                </Elements.ThRotated>
              ))
          )}
        </tr>
      );

      return (
        <Elements.MatrixTable>
          <thead>{header3}</thead>
          <thead>{headerGroupNames}</thead>
          <thead>{headerOptions}</thead>
          <tbody>{students.map(student => this.renderStudentRow(student, groups, groupedExercises, current))}</tbody>
        </Elements.MatrixTable>
      );
    }

    return <></>;
  }
}

class AssignAllButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clicked: false,
      showModal: false
    };

    this.assignAll = this.assignAll.bind(this);
    this.onShowModal = this.onShowModal.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  assignAll() {
    const { exercise, current } = this.props;
    exercise._id = exercise.id;

    var users = this.props.students.map(enrollment => enrollment.userId);
    if (exercise.id in current) {
      let newCurrent = current[exercise.id];
      users = users.filter(userId => !(userId in newCurrent));
    }

    this.setState({ clicked: true, showModal: false }, async () => {
      const batch = this.props.firebase.db.batch();
      users.forEach(userId => {
        const data = dataModel.newAssignment(
          this.props.courseId,
          userId,
          exercise,
          this.props.firebase.fieldValue.serverTimestamp()
        );
        batch.set(this.props.firebase.assignment(userId, exercise._id), data);
      });
      await batch.commit();

      this.setState({ clicked: false });
    });
  }

  onShowModal() {
    this.setState({ showModal: true });
  }

  handleCancel() {
    this.setState({ showModal: false });
  }

  render() {
    if (this.state.clicked) {
      return <span className="fas fa-spinner fa-spin" />;
    }
    return (
      <>
        <Confirm
          size="small"
          open={this.state.showModal}
          onConfirm={this.assignAll}
          onCancel={this.handleCancel}
          content={`Do you want to assign ${this.props.exercise.title} to all students?`}
        />
        <span className="fas fa-plus" style={{ cursor: "pointer" }} onClick={this.onShowModal} />
      </>
    );
  }
}

Matrix = withFirebase(Matrix);
export default withAuthorization(ROLES.AUTH)(withFirebase(MatrixPage));
