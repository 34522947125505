import React, { useState, useMemo } from "react";
import { isEmpty } from "lodash";

import { useFirebase } from "../Firebase";
import { useAuth } from "../Session";
import * as dataModel from "../../services/dataModel";

import { Message, Menu, Input, Icon } from "semantic-ui-react";
import AdminMessage from "./AdminMessage";

export function AdminMessagesList({ messages, showTitle = false, showSearch = true }) {
  const [filterBy, setFilterBy] = useState(null);
  const [search, setSearch] = useState("");

  const resolvedMessages = useMemo(() => messages ? messages.filter(message => message.isResolved) : null, [messages]);
  const unresolvedMessages = useMemo(() => messages ? messages.filter(message => !message.isResolved) : null, [messages]);

  if (isEmpty(messages)) {
    return (
      <React.Fragment>
        <Message info>No messages found</Message>
      </React.Fragment>
    );
  }

  // Count Messages
  const totalCount = messages.length;
  const resolvedCount = resolvedMessages.length;

  // Filter messages
  let filteredMessages = [...unresolvedMessages, ...resolvedMessages];
  if (filterBy === "unresolved") {
    filteredMessages = unresolvedMessages;
  }
  if (filterBy === "resolved") {
    filteredMessages = resolvedMessages;
  }

  // Search Messages
  if (search !== "") {
    filteredMessages = [...unresolvedMessages, ...resolvedMessages];
    filteredMessages = filteredMessages.filter(message => message.content.toLowerCase().includes(search.toLowerCase()));
  }

  return (
    <React.Fragment>
      <Menu secondary compact fluid>
        <Menu.Item header>{search ? `Results for: ${search}` : "Filter By"}</Menu.Item>

        {search === "" && (
          <React.Fragment>
            <Menu.Item
              name={`All Messages ${totalCount}`}
              active={filterBy === null}
              onClick={() => setFilterBy(null)}
            />
            <Menu.Item
              name={`Unresolved ${totalCount - resolvedCount}`}
              active={filterBy === "unresolved"}
              onClick={() => setFilterBy("unresolved")}
            />
            <Menu.Item
              name={`Resolved ${resolvedCount}`}
              active={filterBy === "resolved"}
              onClick={() => setFilterBy("resolved")}
            />
          </React.Fragment>
        )}

        {showSearch && (
          <Menu.Menu position="right">
            <Input
              placeholder="Search..."
              value={search}
              onChange={event => setSearch(event.target.value)}
              icon={search ? <Icon name="times" onClick={() => setSearch("")} link /> : "search"}
            />
          </Menu.Menu>
        )}
      </Menu>

      {isEmpty(filteredMessages) && <Message>No messages found with this filter</Message>}
      {filteredMessages.map(message => (
        <AdminMessageContainer key={message._id} showTitle={showTitle} message={message} />
      ))}
    </React.Fragment>
  );
}

function AdminMessageContainer(props) {
  const firebase = useFirebase();
  const authUser = useAuth();

  const [editMode, setEditMode] = useState(false);

  async function onReview(shouldReview) {
    await props.message._ref.update({
      isReviewed: shouldReview,
      reviewerName: authUser.name,
      reviewerId: authUser.uid
    });
  }

  async function onReply() {
    setEditMode(true);
    await onReview(true);
  }

  async function onCancel() {
    setEditMode(false);
    await onReview(false);
  }

  async function onResolve() {
    setEditMode(false);
    await props.message._ref.update(
      dataModel.resolveMessage(props.message, authUser.name, authUser.uid, null, firebase.fieldValue.serverTimestamp())
    );
  }

  async function onSubmit(response) {
    setEditMode(false);
    await props.message._ref.update(
      dataModel.resolveMessage(
        props.message,
        authUser.name,
        authUser.uid,
        response,
        firebase.fieldValue.serverTimestamp()
      )
    );
  }

  return (
    <AdminMessage
      {...props}
      editMode={editMode}
      authorName={authUser.name}
      onReply={onReply}
      onReview={onReview}
      onResolve={onResolve}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
}
