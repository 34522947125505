import React from "react";

import { useFirebase } from "../../../Firebase";
import { useFirestoreQuery } from "../../../../utils/firebaseHooks";

import { Message, Icon } from "semantic-ui-react";
import { AdminMessagesList } from "../../../Common/AdminMessagesList";
import { ManagePageContainer } from "../../../UI/ManagePageContainer";

export default function MessagesPage({ courseId, studentId }) {
  const firebase = useFirebase();

  const [messages, isLoading] = useFirestoreQuery(
    firebase.messagesOfStudentInCourse(courseId, studentId).orderBy("timestamp", "desc")
  );

  return (
    <ManagePageContainer>
      {isLoading ? (
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>Just one second</Message.Header>
            We are fetching that content for you.
          </Message.Content>
        </Message>
      ) : (
        <AdminMessagesList messages={messages} showTitle={true} />
      )}
    </ManagePageContainer>
  );
}
