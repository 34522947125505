import React from "react";

import { useFirebase } from "../../../Firebase";
import { useAuth } from "../../../Session";

import { useFirestoreQuery } from "../../../../utils/firebaseHooks";
import { Placeholder, Icon, Breadcrumb, Divider, Header } from "semantic-ui-react";

import { UserMessagesList } from "../../../Common/UserMessagesList";

export default function MessagesPage() {
  const firebase = useFirebase();
  const authUser = useAuth();
  const userId = authUser.uid;

  const [messages, isLoading] = useFirestoreQuery(firebase.messagesOfStudent(userId).orderBy("timestamp", "desc"));

  let body = (
    <Placeholder>
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder>
  );

  if (!isLoading) {
    body = <UserMessagesList messages={messages} />;
  }

  return (
    <>
      <Header as="h1" color="purple">
        <Icon name="comments" />
        <Header.Content>
          My Messages
          <Header.Subheader>See all messages from all courses</Header.Subheader>
        </Header.Content>
      </Header>
      <Breadcrumb>
        <Breadcrumb.Section>
          <Icon name="home" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section active>My Messages</Breadcrumb.Section>
      </Breadcrumb>

      <Divider
        style={{
          paddingBottom: "1rem"
        }}
      />

      {body}
    </>
  );
}
