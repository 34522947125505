import React from "react";
import { Logo } from "./Logo";
import styled from "styled-components";

export default function ErrorPage() {
  return (
    <div>
      <TopHalf>
        <div className="logo">
          <Logo />
        </div>
      </TopHalf>

      <TheRest>
        <p style={{ fontSize: "1.4rem", marginBottom: "6rem" }}>well... that's embarrassing</p>
        <p>It looks like you found a bug</p>
        <p
          style={{
            fontSize: "0.9rem",
            textTransform: "uppercase",
            fontWeight: "500",
            marginTop: "1rem"
          }}>
          Please try again
        </p>
      </TheRest>
    </div>
  );
}

const TopHalf = styled.div`
  background-color: #12304d;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.7rem;
  border-bottom: 9px solid #cddc38;
`;

const TheRest = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
`;
// #12304d blue
// #cddc38 green
