import React from "react";
import * as dataModel from "../../../../services/dataModel";
import * as ROUTES from "../../../../constants/routes";

import { useAuth } from "../../../Session";
import { useHistory, useParams } from "react-router-dom";
import { useFirebase } from "../../../Firebase";
import { useFirestoreDoc } from "../../../../utils/firebaseHooks";

import { Icon, Breadcrumb, Divider, Header } from "semantic-ui-react";

import { CourseForm } from "./CourseForm";
import { ManagePageContainer } from "../../../UI/ManagePageContainer";

export default function CreateCoursePage() {
  const params = useParams();
  const firebase = useFirebase();
  const [document, isLoading] = useFirestoreDoc(firebase.course(params.course_id));

  async function saveCourse(values, { setSubmitting, setStatus }) {
    try {
      await document._ref.update(values);
      setSubmitting(false);
    } catch (error) {
      setStatus({ msg: "There was an error while processing the form, Please try again" });
      setSubmitting(false);
    }
  }

  const breadcrumb = [
    { key: "Home", content: <Icon name="home" /> },
    { key: "settings", content: "Settings" }
  ];

  console.log(document);

  return (
    <ManagePageContainer>
      <Header as="h1" icon="cog" content="Settings" />
      <Breadcrumb icon="right angle" sections={breadcrumb} />
      <Divider />

      {!isLoading && (
        <CourseForm initialValues={{ name: document.name, description: document.description }} onSubmit={saveCourse} />
      )}
    </ManagePageContainer>
  );
}
