import React, { useState } from "react";
import { Header, Form } from "semantic-ui-react";

export default function PreferencesPage({ course, exercise }) {
  const [title, setTitle] = useState(exercise.title);

  async function handleSubmit() {
    exercise._ref.update({ title });
  }

  return (
    <React.Fragment>
      <Header size="medium" dividing color="blue">
        Preferences
      </Header>

      <Form onSubmit={handleSubmit}>
        <Form.Input label="Name" value={title} onChange={event => setTitle(event.target.value)} />
        <Form.Field>
          <label>Type</label>
          {exercise.type === "document" ? "Plain Text Document" : "Exercise"}
        </Form.Field>
        <Form.Button primary disabled={title === exercise.title}>
          Save
        </Form.Button>
      </Form>
    </React.Fragment>
  );
}
