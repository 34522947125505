import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../../../Firebase";
import * as ROUTES from "../../../../constants/routes";
import * as Styled from "./styles";
import Form from "../../../UI/Form";

import { Container, Segment } from "semantic-ui-react";

const SignUpPage = () => (
  <>
    <Styled.PageHeader>
      <Styled.PageHeader.Icon>
        <span className="fad fa-edit fa-fw" />
      </Styled.PageHeader.Icon>
      <Styled.PageHeader.Title>Sign Up</Styled.PageHeader.Title>
    </Styled.PageHeader>

    <Styled.Block green>
      <Container style={{ width: "600px" }}>
        <SignUpForm />
        <Segment style={{ background: "#ffffffba" }} padded>
          Already signed up? <Link to={ROUTES.SIGN_IN}>Login here</Link> instead.
        </Segment>
      </Container>
    </Styled.Block>
  </>
);

const INITIAL_STATE = {
  name: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  accept: false,
  error: null
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { name, email, passwordOne } = this.state;

    const roles = [];

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set(
          {
            name,
            email,
            roles,
            enrolled: {}
          },
          { merge: true }
        );
      })
      .then(authUser => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.COURSES);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onAcceptChange = value => {
    this.setState({ accept: value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const { name, email, passwordOne, passwordTwo, accept, error } = this.state;

    return (
      <Segment style={{ background: "#ffffffea" }} padded>
        <Form error={!!error} onSubmit={this.onSubmit}>
          {/* <Styled.Paragraph>Fill the following form to sign-up.</Styled.Paragraph> */}
          <Form.Input
            required
            label="Full name"
            name="name"
            value={name}
            onChange={this.onChange}
            type="text"
            placeholder="Full name"
          />
          <Form.Input
            required
            label="E-mail address"
            name="email"
            value={email}
            onChange={this.onChange}
            type="email"
            placeholder="E-mail address"
          />
          <Form.Group>
            <Form.Input
              required
              label="Password"
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              placeholder="Password"
              type="password"
            />
            <Form.Input
              required
              label="Confirm password"
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Confirm password"
            />
          </Form.Group>
          <Form.Checkbox
            checked={accept}
            name="accept"
            onChange={this.onAcceptChange}
            label={<span>By signing up you accept the <Link to="/terms">Terms and Conditions</Link></span>}
          />
          {error && <Form.Error>{error.message}</Form.Error>}
          <Form.Button type="submit" disabled={!accept}>
            Sign Up
          </Form.Button>
        </Form>
      </Segment>
    );
  }
}
const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase);

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

export default SignUpPage;

export { SignUpForm, SignUpLink };
