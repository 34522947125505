import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";

import { useFirebase } from "../../../Firebase";
import { useFirestoreQuery } from "../../../../utils/firebaseHooks";
import { Placeholder, Icon, Breadcrumb, Divider, Header } from "semantic-ui-react";
import { ManagePageContainer } from "../../../UI/ManagePageContainer";

import { ContentList } from "./ContentList";
import NewGroupForm from "./NewGroupForm";
import Tips from "./Tips";

import * as statistics from "../../../../utils/statistics";
import * as dataModel from "../../../../services/dataModel";

export default function CoureContentPage({ course, current }) {
  const firebase = useFirebase();
  let body = <CourseContentPlaceHolder />;

  // Load Exercises
  const [exercises, isLoading] = useFirestoreQuery(firebase.exercises(course._id));
  const [groupedExercises, setGroupedExercises] = useState({});

  useEffect(() => {
    const exercisesDict = _.keyBy(exercises, "_id");
    const ge = _.mapValues(course.exerciseOrder, exerciseList =>
      exerciseList.map(exerciseId => exercisesDict[exerciseId])
    );
    setGroupedExercises(ge);
  }, [course, exercises]);

  const exerciseStatistics = useMemo(() => {
    if (!exercises || !current) {
      return;
    }
    let stats = {};
    exercises.forEach(exercise => {
      const matrixData = (exercise._id in current && current[exercise._id]) || null;
      stats[exercise._id] = statistics.countExercise(matrixData, course.enrolledCounter);
    });
    return stats;
  }, [exercises, current]);

  async function createGroup(groupName) {
    if (course.groups.includes(groupName)) {
      // TODO: Show error
      return;
    }
    return firebase.course(course._id).update({ groups: [...course.groups, groupName] });
  }

  async function createContent(groupName, title) {
    const prevExerciseOrder = course.exerciseOrder || {};
    const prevGroupExercises = prevExerciseOrder[groupName] || [];

    const newExercise = dataModel.newExercise(title, "", firebase.fieldValue.serverTimestamp());
    const exerciseDoc = await firebase.exercises(course._id).add(newExercise);

    const exerciseOrder = { ...prevExerciseOrder, [groupName]: [...prevGroupExercises, exerciseDoc.id] };
    await firebase.course(course._id).update({ exerciseOrder });
  }

  async function updateOrder(exerciseOrder) {
    await firebase.course(course._id).update({ exerciseOrder });
  }

  async function updateGroups(newOrder) {
    await firebase.course(course._id).update({ groups: newOrder });
  }

  if (!isLoading) {
    body = (
      <React.Fragment>
        <ContentList
          groups={course.groups}
          groupedExercise={groupedExercises}
          exerciseStatistics={exerciseStatistics}
          exerciseOrder={course.exerciseOrder}
          onAddNewContent={createContent}
          onOrderChanged={updateOrder}
          onGroupsUpdated={updateGroups}
          current={current}
        />
        <NewGroupForm onSave={createGroup} />
        <Tips groups={course.groups} exercises={exercises} />
      </React.Fragment>
    );
  }

  return (
    <ManagePageContainer>
      <Header as="h1" icon="list" content="Course Content" subheader="Manage all documents and assignments" />

      {/* BreadCrumb */}
      <Breadcrumb>
        <Breadcrumb.Section>
          <Icon name="home" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section active>Course Content</Breadcrumb.Section>
      </Breadcrumb>

      <Divider />

      {/* Page Content */}
      {body}
    </ManagePageContainer>
  );
}

function CourseContentPlaceHolder() {
  return (
    <Placeholder>
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder>
  );
}
