export const ASSIGNMENTS = {
  ASSIGNED: { icon: "fas fa-arrow-alt-circle-right", color: "#007bff", title: "Assigned" },
  SUBMITTED: { icon: "far fa-arrow-alt-circle-up", color: "#007bff", title: "Submitted" },
  IN_REVIEW: { icon: "far fa-arrow-alt-circle-up", color: "#007bff", title: "In Review" },
  REDO: { icon: "far fa-times-circle", color: "#dc3545", title: "Please Resubmit" },
  DONE: { icon: "far fa-check-circle", color: "#28a745", title: "Done" }
};

export const ASSIGNMENTS_USER = {
  ASSIGNED: { icon: "fas fa-arrow-alt-circle-right", color: "#007bff", lightColor: "#007bff50", title: "" },
  SUBMITTED: { icon: "far fa-arrow-alt-circle-up", color: "#007bff", lightColor: "#007bff50", title: "Submitted" },
  IN_REVIEW: { icon: "far fa-arrow-alt-circle-up", color: "#007bff", lightColor: "#007bff50", title: "Submitted" },
  REDO: {
    icon: "far fa-times-circle",
    color: "#ffc107",
    lightColor: "#ffc10750",
    title: "Please Resubmit",
    gradeStyle: "border-warning bg-gentle-warning border-top"
  },
  DONE: {
    icon: "far fa-check-circle",
    color: "#28a745",
    lightColor: "#28a74550",
    title: "Done",
    gradeStyle: "border-success bg-gentle-success border-top"
  }
};

export const POLICIES = {
  ON_DEMAND: { icon: "fas fa-wrench", title: "Manual" },
  ON_SUBMIT: { icon: "fas fa-angle-double-down", title: "On Submit" },
  ON_CHECK: { icon: "fas fa-check", title: "On Check" }
};

export const EXERCISE_TYPE = {
  document: { icon: "file alternate outline" },
  exercise: { icon: "wrench" },
  undefined: { icon: "question" }
};

export const ASSIGNMENT_ICON = {
  ASSIGNED: { icon: "circle outline", color: "grey", title: "Assigned" },
  SUBMITTED: { icon: "arrow alternate circle right", color: "blue", title: "Submitted" },
  IN_REVIEW: { icon: "arrow alternate circle right", color: "orange", title: "In Review" },
  REDO: { icon: "times circle", color: "red", title: "Please Resubmit" },
  DONE: { icon: "check circle", color: "green", title: "Done" },
  undefined: { icon: "question", color: "black", title: "Undefined" }
};
// export const EXERCISE_TYPE = {
//   document: { icon: "far fa-file-alt fa-sm" },
//   exercise: { icon: "fas fa-wrench fa-sm" },
//   undefined: { icon: "fas fa-question fa-xs" }
// };
